import callBackend from '../shared/methods/callBackend/callBackend';

const getTrackingExcludes = async () => {
  const url = `${process.env.REACT_APP_API_URL}/trackingexcluded`;
  return callBackend({
    url,
    method: 'GET',
  });
};

export default getTrackingExcludes;
