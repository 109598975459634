import React from 'react';
import { observer } from 'mobx-react';
import { components } from 'react-select';
import { Icon, Button } from '@storaensods/seeds-react';
import Div from '../../shared/components/Div/Div';
import Flex from '../../shared/components/Flex/Flex';
import Select from '../../shared/components/Select/Select';
import useDialog from '../../shared/components/Dialog/useDialog';

import styles from './SavedFiltersSelect.module.scss';

import SavedFiltersFormModel from './SavingFilterFormModel';
import SavingFilterForm from './SavingFilterForm';

function SavedFiltersSelect({ model, filterModel }) {
  const { addDialog } = useDialog();
  const formModel = new SavedFiltersFormModel();

  const Option = ({
    children, innerProps, data, value, ...props
  }) => (
    <components.Option {...props} style={{ padding: 0 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          data-testid="saved-filter-option-check"
          className={styles.optionIcon}
          onClick={() => {
            model.toggleCheckOption(value);
          }}
        >
          {data.isCheck ? 'check_box' : 'check_box_outline_blank'}
        </Icon>
        <div
          {...innerProps}
          style={{ width: '100%' }}
          data-testid="saved-filter-option"
        >
          {children}
        </div>
      </div>
    </components.Option>
  );

  return (
    <Flex centeredVertically>
      <Div style={{ width: 200 }} data-testid="saved-filter-select">
        <Select
          options={model.options}
          value={model.value}
          isSearchable
          isClearable
          placeholder="My filters..."
          components={{ Option }}
          onChange={(option) => {
            if (option && option.value) {
              // model.setValue(option);
              filterModel.setSavedFilterValuesByName(option.value);
            } else {
              // model.setValue(null);
              filterModel.setSavedFilterValuesByName(null);
            }
          }}
        />
      </Div>
      <Div>
        <Button
          data-testid="saved-filter-delete-btn"
          className={styles.filterControlButton}
          type="secondary"
          icon="delete"
          onClick={() => {
            filterModel.removeSelectedFilters(model.allSelectedValues);
            model.updateOptions(filterModel.getSavedFiltersOption());
          }}
        />
      </Div>
      <Div>
        <Button
          data-testid="saved-filter-save-btn"
          className={styles.filterControlButton}
          type="secondary"
          icon="save"
          onClick={() => {
            addDialog(<SavingFilterForm model={formModel} />, {
              buttons: [
                {
                  label: 'Cancel',
                  onClick: () => true,
                },
                {
                  label: 'Save',
                  onClick: () => {
                    if (!formModel.error) {
                      filterModel.addNewSavedFilter(formModel);
                      model.updateOptions(filterModel.getSavedFiltersOption());
                      model.setValue({
                        label: formModel.filterName,
                        value: formModel.filterName,
                      });
                      return true;
                    }
                    return false;
                  },
                  icon: 'save',
                },
              ],
            });
          }}
        />
      </Div>
    </Flex>
  );
}

export default observer(SavedFiltersSelect);
