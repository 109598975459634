import React from 'react';
import Text from '../../shared/components/typography/Text';
import Select from '../../shared/components/Select/Select';
import Div from '../../shared/components/Div/Div';
import Flex from '../../shared/components/Flex/Flex';

function DivisionFilter({
  divisionFilterModel, filterOptionsLoading,
}) {
  const { divisionModel } = divisionFilterModel;

  return (
    <Flex
      row="true"
      centeredVertically
      margin={{ bottom: 's' }}
      fullWidth
    >
      <Text
        captionSmall
        bold
        padding={{ bottom: 's' }}
        color="neutral100"
        margin={{ top: 's' }}
      >
        Division:
      </Text>
      <Div
        margin={{ left: 'xs' }}
        style={{ minWidth: '260px' }}
      >
        <Select
          isMulti
          options={divisionModel.options}
          value={divisionModel.value}
          isSearchable
          isClearable
          isLoading={filterOptionsLoading}
          isDisabled={filterOptionsLoading}
          placeholder="Select division..."
          onChange={(option) => divisionModel.setValue(option)}
        />
      </Div>
    </Flex>
  );
}

export default DivisionFilter;
