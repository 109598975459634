import React from 'react';
import Flex from '../../shared/components/Flex/Flex';
import CommentBubble from '../../shared/components/CommentBubble/CommentBubble';

function CommentsCell({ data, row: { index: currentRow } }) {
  const { comments = [], stage } = data[currentRow];
  return (
    comments.length > 0 && (
      <Flex centered style={{ position: 'relative' }}>
        <CommentBubble numComments={comments.length} transportStage={stage} />
      </Flex>
    )
  );
}

export default CommentsCell;
