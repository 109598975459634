import callBackend from '../shared/methods/callBackend/callBackend';

export type TFilterRule = {
  originId: Array<string>,
  destinationId: Array<string>,
  carrierId: Array<string>,
  transportModeType: Array<string>,
  trackingPartyName: Array<string>,
};

const getTrackingRules = async (filters?: TFilterRule) => {
  let url = `${process.env.REACT_APP_API_URL}/trackingrules`;
  if (filters) { url += `&filters=${encodeURIComponent(JSON.stringify(filters))}`; }
  return callBackend({
    url,
    method: 'GET',
  });
};

export default getTrackingRules;
