import React from 'react';
import Div from '../Div/Div';
import Flex from '../Flex/Flex';
import PopupModel from './PopupModel';
import addDecorators from '../../methods/addDecorators/addDecorators';
import styles from './Popup.module.scss';
import withModel from '../../methods/withModel/withModel';

const Popup = ({
  id, children, popupIDs, removePopupID, closePopupManually, ...props
}) => {
  const popupIndex = popupIDs.indexOf(id);
  const isActive = popupIndex > -1;

  document.querySelector('body').style.overflowY = isActive
    ? 'hidden'
    : 'visible';

  return (
    <Div {...props}>
      {isActive && (
        <Div
          className={styles.popup_dimmer}
          onClick={() => {
            if (!closePopupManually) removePopupID(id);
            else closePopupManually();
          }}
        >
          <Flex
            centeredHorizontally
            className={styles.popup_content}
            styles={{ zIndex: 100 + popupIndex }}
          >
            <Div onClick={(event) => event.stopPropagation()}>{children}</Div>
          </Flex>
        </Div>
      )}
    </Div>
  );
};

export default addDecorators(
  withModel({
    Model: PopupModel,
    props: ['popupIDs', 'removePopupID'],
  }),
).to(Popup);
