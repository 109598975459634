import React from 'react';
import classNames from 'classnames';
import Div from '../Div/Div';
import styles from './Badge.module.scss';

export default function Badge({
  value, children, className, isOnRight, hide, ...props
}) {
  return (
    <Div className={classNames([styles.badgeContainer, className])} {...props}>
      <span
        className={classNames(styles.badge, {
          [styles.isOnRight]: isOnRight,
          [styles.hide]: hide,
        })}
      >
        {value}
      </span>
      {children}
    </Div>
  );
}
