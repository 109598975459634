import React, { useState, useEffect } from 'react';
import { Icon, Spinner } from '@storaensods/seeds-react';

import Flex from '../../../shared/components/Flex/Flex';
import Text from '../../../shared/components/typography/Text';

import styles from './TransportStatusIconAndLabel.module.scss';

function EditableInput({ value, loading, onChange }) {
  const [canType, setCanType] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const ref = React.createRef();

  useEffect(() => {
    if (canType) {
      ref.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canType]);

  return (
    canType ? (
      <input
        data-testid="current-editable-text-input"
        style={{ padding: 4 }}
        ref={ref}
        value={inputValue}
        onFocus={(ev) => {
          ev.target.select();
        }}
        onBlur={() => {
          setCanType(false);
          setInputValue(value);
        }}
        onChange={(ev) => {
          setInputValue(ev.target.value);
        }}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            setCanType(false);
            if (onChange) {
              onChange(inputValue);
            }
          }
        }}
      />
    ) : (
      <Text onClick={() => setCanType(!loading)}>
        {value}
        &nbsp;
        {loading ? (
          <Spinner style={{ display: 'inline-block', height: 10, width: 10 }} />
        ) : (
          <Icon>edit</Icon>
        )}
      </Text>
    )
  );
}

function LabelAndValue({
  label, children, margin = { bottom: 'm' }, onChange = undefined, loading = false,
  valuefontSize = '14px', labelfontSize = '12px', ellipseValue = false, valueWidth, ...props
}) {
  return (
    <Flex column margin={margin} {...props}>
      <Text
        bold
        captionSmall
        margin={{ bottom: 'xxxs' }}
        style={{ fontSize: labelfontSize }}
        color="neutral100"
      >
        {label}
      </Text>
      {onChange ? (
        <EditableInput loading={loading} value={children} onChange={onChange} />
      ) : (
        <Text
          className={ellipseValue && styles.ellipse}
          style={{ fontSize: valuefontSize, width: valueWidth }}
        >
          {children}
        </Text>
      )}
    </Flex>
  );
}

export default LabelAndValue;
