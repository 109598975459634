import callBackend from '../shared/methods/callBackend/callBackend';

const stubbedData = {
  contRef: null,
  transports: [
    {
      match: 'transportId',
      results: [
        {
          transportId: 'REF_GN5_00',
          origin: 'SEHYLM',
          destination: 'LENGHELI1',
          stage: 'arrived',
        },
        {
          transportId: 'REF_GN50_0',
          origin: 'SEHYLM',
          destination: 'LENGHELI',
          stage: 'arrived',
        },
      ],
    },
    {
      match: 'waybillId',
      results: [
        {
          transportId: 'REF_GN5_00',
          waybillId: 'GN5_00',
          origin: 'SEHYLM',
          destination: 'LENGHELI1',
          stage: 'arrived',
        },
        {
          transportId: 'REF_GN50_0',
          waybillId: 'GN50_0',
          origin: 'SEHYLM',
          destination: 'LENGHELI',
          stage: 'arrived',
        },
      ],
    },
    {
      match: 'orderId',
      results: [],
    },
  ],
};

const getSearchResults = async (query) => {
  const stubbed = false;

  if (stubbed) {
    return new Promise(((resolve) => {
      setTimeout(resolve, 250, {
        callWasSuccessful: true,
        response: stubbedData,
      });
    }));
  }

  let url = `${process.env.REACT_APP_API_URL}/transportsearch`;

  if (query) url += `?query=${query}`;

  const result = await callBackend({ url, method: 'GET' });

  return result;
};

export default getSearchResults;
