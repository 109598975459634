import React from 'react';
import Flex from '../../shared/components/Flex/Flex';
import Text from '../../shared/components/typography/Text';

import getLatenessStatusDifferenceLabel from './getLatenessStatusDifferenceLabel';

export default function LatenessStatusDifferenceCell({ value: latenessStatusDifference }) {
  return (
    <Flex
      style={{
        minWidth: 24, textTransform: 'uppercase',
      }}
    >
      <Text captionSmall>
        {getLatenessStatusDifferenceLabel(latenessStatusDifference)}
      </Text>
    </Flex>
  );
}
