import React, { useContext } from 'react';
import { observable, computed } from 'mobx';
import { AuthenticationState } from 'react-aad-msal';

const MESSAGE_UPLOAD_ROLE_NAME = 'message_upload';

export class UserModel {
  userAgentApplication;

  @observable name;

  @observable authenticationState;

  @observable roles;

  @observable error;

  msLogout = () => {};

  logout = () => {
    window.localStorage.clear();
    if (this.msLogout) {
      this.msLogout();
    }
    // window.location.reload();
  };

  constructor(userAgentApplication = {}) {
    this.setUserAgentApplication(userAgentApplication);
  }

  setUserAgentApplication(userAgentApplication) {
    this.userAgentApplication = userAgentApplication;

    const {
      error,
      authenticationState,
      logout,
    } = this.userAgentApplication;

    this.authenticationState = authenticationState;
    this.error = error;
    this.msLogout = logout || (() => {});

    if (this.authenticationState === AuthenticationState.Authenticated) {
      const {
        accountInfo: { account: { name, idToken: { roles } } },
      } = this.userAgentApplication;
      this.roles = roles || [];
      this.name = name;
    } else {
      this.roles = [];
      this.name = null;
    }
  }

  @computed get canUploadTestMessages() {
    return this.roles.includes(MESSAGE_UPLOAD_ROLE_NAME);
  }
}

const UserContext = React.createContext(new UserModel());

export const UserProvider = UserContext.Provider;

export function useUserModel() {
  return useContext(UserContext);
}
