import { computed } from 'mobx';
import SelectModel from '../../shared/components/Select/SelectModel';

export default class CarrierCustomerFiltersModel {
  carrierModel = new SelectModel();

  customerModel = new SelectModel();

  @computed
  get filters() {
    return {
      ...(this.carrierModel.value && {
        carrier: this.carrierModel.getValue(),
      }),
      ...(this.customerModel.value && {
        customer: this.customerModel.getValue(),
      }),
    };
  }

  @computed
  get tags() {
    return {
      carrier: {
        list: this.carrierModel.getLabelAndValue(),
        remove: (value) => { this.carrierModel.remove(value); },
      },
      customer: {
        list: this.customerModel.getLabelAndValue(),
        remove: (value) => { this.customerModel.remove(value); },
      },
    };
  }

  setFilterOptions(options) {
    const filterOptions = options || {};
    this.carrierModel.setOptions(this.getOptions(filterOptions.carriers));
    this.customerModel.setOptions(this.getOptions(filterOptions.customers));
  }

  getFilterOptions() {
    return {
      carriers: this.carrierModel.options,
      customers: this.customerModel.options,
    };
  }

  copyFilterOptions(options) {
    this.carrierModel.setOptions(this.getOptions(options.carriers));
    this.customerModel.setOptions(this.getOptions(options.customers));
  }

  getOptions = (values) => (values
    ? values.map((value) => ({
      value: value.code,
      label: value.name !== null ? `${value.code} - ${value.name}` : `${value.code}`,
    }))
    : []);

  setFilterValues = (filterValues) => {
    if (filterValues.carrier) {
      this.carrierModel.setValue(filterValues.carrier);
    } else {
      this.carrierModel.setValue(null);
    }

    if (filterValues.customer) {
      this.customerModel.setValue(filterValues.customer);
    } else {
      this.customerModel.setValue(null);
    }
  };
}
