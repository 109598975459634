import React from 'react';
import { Notification } from '@storaensods/seeds-react';
import { AuthenticationState } from 'react-aad-msal';
import Page from './pages/Page/Page';
import { useUserModel } from './User';

export default function AuthenticationChecker({ children }) {
  const userModel = useUserModel();
  switch (userModel.authenticationState) {
    case AuthenticationState.Authenticated:
      return children(userModel);
    case AuthenticationState.InProgress:
      return (
        <Page hideBackButton>
          <Notification type="attention">
            Authenticating...
          </Notification>
        </Page>
      );
    case AuthenticationState.Unauthenticated:
      // console.log({ e: userModel.error});
      return (
        <Page hideBackButton>
          <Notification type="negative">
            An error occured during authentication, please try again!
          </Notification>
        </Page>
      );
    default:
      return (
        <Page hideBackButton>
          Login fail, please try again
        </Page>
      );
  }
}
