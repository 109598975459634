import { React, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { throttle } from 'lodash';
import * as L from 'leaflet';
import { Marker, Polyline, Popup } from 'react-leaflet';

import styles from './TransportMap.module.scss';

import Text from '../../../shared/components/typography/Text';
import TransportScheduleStatus from '../TransportScheduleStatus';
import TransportCaption from '../../../TransportCaption/TransportCaption';

import eventFriendlyNames from '../../../shared/methods/externalEventFriendlyNames/externalEventFriendlyNames';

import colors from '../../../shared/styles/Colors.module.scss';
import homeImg from '../../../assets/home-24px.svg';
import flagImg from '../../../assets/flag-24px.svg';

function TransportPathMarkers({ mapModel }) {
  const [hover, setHover] = useState('');

  const throttledHoverHandler = useMemo(() => throttle(setHover, 25), [setHover]);

  const getMarkerZIndex = (index, last) => {
    if (index === 0 || index === last) {
      return 100;
    }
    return index === hover ? 99 : 0;
  };

  const getIconWrapperClass = (key) => (key && (key.startsWith('pickup') || key.startsWith('dropoff'))
    ? 'icon_wrapper_first-last'
    : 'icon_wrapper');

  const {
    path,
    canEdit,
    currentSequence,
    isCurrentActive,
    dropoffOrPickup,
  } = mapModel;

  let index = 0; // this is needed, cuz sometimes origin or destination are not added in the path.

  return (
    <>
      <Polyline
        color={colors.blue100}
        positions={mapModel.coordinates}
        smoothFactor={0}
      />
      {path.reduce((acc, point, i) => {
        const {
          eventCode,
          arrivalRequestedVsEstimated,
          scheduleStatus,
          latitude,
          longitude,
          street,
          city,
          postalCode,
          country,
          address,
          key,
        } = point;

        if (!latitude || !longitude) {
          // eslint-disable-next-line no-console
          console.warn(`Missing latitude or longitude for ${key || `eta-${index}`} `);
          return acc;
        }

        let iconContent;
        let iconStyle;

        if (key && key.startsWith('pickup')) {
          iconContent = `<img src="${homeImg}" />`;
          iconStyle = `${styles.map_marker_icon} ${styles.map_marker_icon_pickup} 
                      ${canEdit && dropoffOrPickup === 'pickup' && styles.map_marker_icon_pickup_edit}`;
        } else if (key && key.startsWith('dropoff')) {
          iconContent = `<img src="${flagImg}" />`;
          iconStyle = `${styles.map_marker_icon} ${styles.map_marker_icon_dropoff}
                      ${canEdit && dropoffOrPickup === 'dropoff' && styles.map_marker_icon_dropoff_edit}`;
        } else {
          index += 1;
          iconContent = index;
          const activeMarker = (isCurrentActive || path.length - 2 === index) ? styles.map_marker_icon_yellow : '';
          const lateMarker = scheduleStatus === TransportScheduleStatus.LATE
            ? styles.map_marker_icon_red : activeMarker;
          iconStyle = `${styles.map_marker_icon} ${lateMarker}`;
        }

        const shadowStyle = styles.map_marker_shadow;
        const labelStyle = styles.map_marker_label;
        const icon = L.divIcon({
          className: styles.map_marker,
          iconSize: null,
          html: `<div class="${getIconWrapperClass(key)}"><div class="${iconStyle}"></div><div class="${shadowStyle}"></div><div class="${labelStyle}">${currentSequence || iconContent}</div></div>`,
        });
        const eventFriendlyName = eventFriendlyNames[eventCode] || eventCode;
        acc.push(
          <Marker
            zIndexOffset={getMarkerZIndex(i, acc.length - 1)}
            eventHandlers={{
              mouseover: () => {
                throttledHoverHandler(i);
              },
              mouseout: () => {
                throttledHoverHandler('');
              },
            }}
            draggable={mapModel.canDrag(point)}
            onDragend={(event) => {
              const { target } = event;
              const newCoordinates = target.getLatLng();
              mapModel.updatePoint({
                ...point,
                latitude: newCoordinates.lat,
                longitude: newCoordinates.lng,
              }, i);
            }}
            icon={icon}
            key={key || `eta-${index}`}
            position={[latitude, longitude]}
          >
            <Popup closeButton={false}>
              <Text
                tagName="span"
                block
                captionSmall
                bold
                margin={{ bottom: 'xs' }}
                color="neutral100"
              >
                {
                  eventCode === 'ETA'
                    ? `${eventFriendlyName} ${index}`
                    : eventFriendlyName
                }
              </Text>

              {street && (
                <Text tagName="span" block>
                  {street}
                </Text>
              )}
              {city && (
                <Text tagName="span" block>
                  {city}
                </Text>
              )}
              {postalCode && (
                <Text tagName="span" block>
                  {postalCode}
                </Text>
              )}
              {country && (
                <Text tagName="span" block padding={{ bottom: 'xs' }}>
                  {country}
                </Text>
              )}

              {address && (
                <Text tagName="span" block padding={{ bottom: 'xs' }}>
                  {address}
                </Text>
              )}

              {(latitude || longitude) && (
                <Text
                  tagName="span"
                  block
                  small
                  color="neutral40"
                  margin={{ bottom: 'xs' }}
                >
                  {`(${latitude}, ${longitude})`}
                </Text>
              )}

              <TransportCaption
                bold
                diff={arrivalRequestedVsEstimated}
                prefix="ETA "
              />
            </Popup>
          </Marker>,
        );
        return acc;
      }, [])}
    </>
  );
}

export default observer(TransportPathMarkers);
