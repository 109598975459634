import callBackend from '../../../shared/methods/callBackend/callBackend';

interface xmlMessageUrlResponse {
  callWasSuccessful: boolean,
  response: string
}

const getXmlMessageUrl = async (blobId) => {
  const url = `${process.env.REACT_APP_API_URL}/xmlMessageUrl/${blobId}`;

  const responseMessage: xmlMessageUrlResponse = await callBackend({ url, method: 'GET' }) as xmlMessageUrlResponse;

  return responseMessage.response;
};

export default getXmlMessageUrl;
