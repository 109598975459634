import { useUserModel } from './User';

const menuItemNoAccessRequire = [
  {
    label: 'Home',
    icon: 'home',
    path: '/',
  },
  {
    label: 'Tracking Rules',
    icon: 'art_track',
    path: '/tracking-rules',
  },
  {
    label: 'Locations',
    icon: 'edit_location',
    path: '/locations',
  },
  {
    label: 'Opening Hours',
    icon: 'access_time_filled',
    path: '/openinghours',
  },
  {
    label: 'Filters and Alerts',
    icon: 'notifications',
    path: '/filter',
  },
];

const menuItemUploadAccessRequire = [
  {
    label: 'Upload message',
    icon: 'file_upload',
    path: '/upload-message',
  },
];

export default function useAppLinks() {
  const userModel = useUserModel();
  const lastItems = [
    {
      label: 'Logout',
      icon: 'account_circle',
      onClick: () => { userModel.logout(); },
    },
  ];
  return [
    ...menuItemNoAccessRequire,
    ...(userModel.canUploadTestMessages ? menuItemUploadAccessRequire : []),
    ...lastItems,
  ];
}
