import TransportStage from './TransportStage';
import callBackend from '../../shared/methods/callBackend/callBackend';

const stubbedData = {
  transportId: '4218074122',
  mill: {
    id: 'HY',
    nameAddress: [
      'Stora Enso Paper AB',
      'Hylte Mill',
      'SE - 314 81 HYLTEBRUK',
      'Sweden',
    ],
    orders: [
      {
        number: 'HYDE-839131',
        lineItemNumber: '001',
        numberOfPackages: 45,
      },
      {
        number: 'HYDE-839131',
        lineItemNumber: '002',
        numberOfPackages: 54,
      },
    ],
  },
  waybills: [{
    id: 'HYHY1111666',
    weight: 23690.0,
  }],
  loadPlanStatus: 'P',
  incoterms: 'DAP',
  incotermsLocation: 'DE24782',
  transportModeType: 'Road',
  transportModeCode: 'TK',
  vehicle: {
    code: 'TR136TAUTLINER',
    licensePlateNumber: 'FIXE0500303',
  },
  carrier: {
    id: 'LKW',
    nameAddress: [
      'LKW WALTER Internationale Transportorganisation AG',
      'Industriezentrum NÖ-Süd, Strasse 14',
      'Wiener Neudorf',
      '2355',
      'AT',
    ],
  },
  transportPlanner: {
    name: 'Josefine Apell (LSC3)',
    email: 'josefine.apell@storaenso.com',
  },
  origin: {
    id: 'SEHYLM',
    nameAddress: [
      'Hylte Mill',
      'Gamla Nissastigen 16',
      'Hyltebruk',
      '314 81',
      'SE',
    ],
  },
  destination: {
    id: 'LENGHELI',
    nameAddress: [
      'IMPRIMERIE LENGLET',
      '1224 AVENUE DES 2 VALLEES',
      "ZA DE L'A2",
      'RAILLENCOURT STE OLLE',
      '59554',
      'FR',
    ],
  },
  loadingPlannedTo: '2018-06-07T00:00:00.000Z',
  departureActual: '2018-06-07T00:16:00.000Z',
  arrivalRequestedTo: '2018-06-09T00:00:00.000Z',
  // arrivalActual: Math.random() < 0.5 ? '2018-06-09T00:17:00.000Z' : null,
  arrivalActual: null,
  stage: TransportStage.IN_TRANSIT,
  statusMessages: [
    {
      eventCode: 'Planned',
      creationTime: '2018-06-06T15:45:00.000Z',
    },
    {
      eventCode: 'Loading',
      creationTime: '2018-06-06T21:15:00.000Z',
      contact: {
        name: 'Calle Contact Person',
        phone: '+123456789098',
        email: 'calle.contact@lkwwalter.com',
      },
    },
    {
      eventCode: 'Loaded',
      creationTime: '2018-06-07T00:10:00.000Z',
    },
    {
      eventCode: 'Departed',
      creationTime: '2018-06-07T00:15:00.000Z',
      contact: {
        name: 'Calle Contact Person',
        phone: '+123456789098',
        email: 'calle.contact@lkwwalter.com',
      },
    },
    {
      eventCode: 'ETA',
      creationTime: '2018-06-07T00:30:00.000Z',
      milestone: {
        eta: '2018-06-09T00:14:00.000Z',
        street: 'Nissastigen',
        city: 'Torup',
        postalCode: '314 96',
        country: 'SE',
        latitude: '56.972022',
        longitude: '13.169137',
      },
      contact: {
        name: 'Calle Contact Person',
        phone: '+123456789098',
        email: 'calle.contact@lkwwalter.com',
      },
    },
    {
      eventCode: 'ETA',
      creationTime: '2018-06-07T00:45:00.000Z',
      milestone: {
        eta: '2018-06-09T00:16:00.000Z',
        street: 'Nissastigen',
        city: 'Torup',
        postalCode: '314 96',
        country: 'SE',
        latitude: '56.945050',
        longitude: '13.076611',
      },
      contact: {
        name: 'Calle Contact Person',
        phone: '+123456789098',
        email: 'calle.contact@lkwwalter.com',
      },
    },
    {
      eventCode: 'ETA',
      creationTime: '2018-06-07T01:00:00.000Z',
      milestone: {
        eta: '2018-06-09T00:18:00.000Z',
        street: 'Nya Nissastigen',
        city: 'Oskarström',
        postalCode: '313 32',
        country: 'SE',
        latitude: '56.802488',
        longitude: '12.960122',
      },
      contact: {
        name: 'Calle Contact Person',
        phone: '+123456789098',
        email: 'calle.contact@lkwwalter.com',
      },
    },
    {
      eventCode: 'ETA',
      creationTime: '2018-06-07T01:15:00.000Z',
      milestone: {
        eta: '2018-06-09T00:19:00.000Z',
        street: 'Nyatorp-Gustavsfält',
        city: 'Halmstad',
        postalCode: '123 45',
        country: 'SE',
        latitude: '56.658771',
        longitude: '12.906476',
      },
      contact: {
        name: 'Calle Contact Person',
        phone: '+123456789098',
        email: 'calle.contact@lkwwalter.com',
      },
    },
    // {
    //   eventCode: 'Arrived',
    //   creationTime: '2018-06-09T00:17:00.000Z',
    //   contact: {
    //     name: 'Calle Contact Person',
    //     phone: '+123456789098',
    //     email: 'calle.contact@lkwwalter.com'
    //   }
    // },
    // {
    //   eventCode: 'Unloaded',
    //   creationTime: '2018-06-09T02:00:00.000Z',
    //   contact: {
    //     name: 'Calle Contact Person',
    //     phone: '+123456789098',
    //     email: 'calle.contact@lkwwalter.com'
    //   }
    // },
    // {
    //   eventCode: 'Delivered',
    //   creationTime: '2018-06-09T02:05:00.000Z',
    //   contact: {
    //     name: 'Calle Contact Person',
    //     phone: '+123456789098',
    //     email: 'calle.contact@lkwwalter.com'
    //   }
    // }
  ],
  comments: [
    {
      creationTime: '2018-06-08T01:34:21.000Z',
      text:
        'Nunc eu tellus sit amet lacus accumsan hendrerit vitae non massa. Aliquam porttitor, dolor et fringilla tincidunt, nunc quam tempus tellus, nec blandit ipsum nisi et sem. Vivamus convallis eros eget pharetra auctor. Donec congue venenatis mattis. Morbi luctus tortor a lobortis pellentesque.',
      author: {
        name: 'Petter Jönsson',
        email: 'petter.jonsson@storaenso.com',
      },
    },
    {
      creationTime: '2018-06-08T02:51:34.000Z',
      text:
        'Vestibulum egestas felis volutpat enim molestie, vitae cursus enim commodo. Curabitur pellentesque nulla quis leo pharetra convallis.',
      author: {
        name: 'Maria Ahlstedt',
        email: 'maria.ahlstedt@storaenso.com',
      },
    },
  ],
};

const getTransport = async (transportID) => {
  const stubbed = false;

  if (stubbed) {
    return new Promise(((resolve) => {
      setTimeout(resolve, 250, {
        callWasSuccessful: true,
        response: stubbedData,
      });
    }));
  }

  let url = `${process.env.REACT_APP_API_URL}/transport/`;
  url += transportID;

  return callBackend({
    url,
    method: 'GET',
  });
};

export default getTransport;
