import React from 'react';
import { observer } from 'mobx-react';
import Select from '../../shared/components/Select/Select';
import Div from '../../shared/components/Div/Div';
import Text from '../../shared/components/typography/Text';

function EventFilters({ model }) {
  const {
    eventModel,
  } = model;

  return (
    <Div style={{ width: '50%' }}>
      <Div margin={{ bottom: 's' }}>
        <Text
          captionSmall
          bold
          color="neutral100"
        >
          Transport Status
        </Text>
      </Div>
      <Div margin={{ bottom: 'l' }}>
        <Select
          isMulti
          options={eventModel.options}
          value={eventModel.value}
          isSearchable
          isClearable
          placeholder="Select Transport Status..."
          onChange={(option) => eventModel.setValue(option)}
        />
      </Div>
    </Div>
  );
}

export default observer(EventFilters);
