import { decorate, observable } from 'mobx';

class TransportsDateStatusCaptionModel {
  dateStatusSwitch = true;

  constructor() {
    setInterval(() => {
      this.dateStatusSwitch = !this.dateStatusSwitch;
    }, 5000);
  }
}

export default decorate(TransportsDateStatusCaptionModel, {
  dateStatusSwitch: observable,
});
