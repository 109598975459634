import React from 'react';
import { observer } from 'mobx-react';
import { Input } from '@storaensods/seeds-react';
// import Text from '../../shared/components/typography/Text';
import Div from '../../shared/components/Div/Div';

function SavingFilterForm({ model }) {
  const {
    filterName,
  } = model;

  return (
    <Div data-testid="saving-filter-form">
      <Input
        label="Filter name"
        size="sm"
        value={filterName}
        onChange={(ev) => { model.onFilterNameChange(ev); }}
        helpText={model.error && model.error.filterName}
        invalid={model.error && !!model.error.filterName}
      />
    </Div>
  );
}

export default observer(SavingFilterForm);
