import callBackend from '../../shared/methods/callBackend/callBackend';

export enum ETransportPatchOP {
  REPLACE = 'replace',
}

export type TTransportBody = Array<{
  op: ETransportPatchOP,
  path: string,
  value: any,
}>;

const patchTransport = async (transportID, body: TTransportBody) => callBackend({
  url: `${process.env.REACT_APP_API_URL}/transports/${transportID}`,
  method: 'PATCH',
  body,
});

export default patchTransport;
