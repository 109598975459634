import React from 'react';
import { findLastIndex, isEmpty } from 'lodash';

import Flex from '../../../shared/components/Flex/Flex';
import TransportStatusIconAndLabel from '../TransportDetails/TransportStatusIconAndLabel';

import Div from '../../../shared/components/Div/Div';
import ProgressLine from '../../../shared/components/ProgressLine/ProgressLine';

import {
  getPlan,
  getInTransitToPickUp,
  getPickUp,
  getInTransitToDropOff,
  getDropOff,
  getTrackingFailed,
} from './SummaryEventsFunctions';
import InternalEventCodeLabel from '../InternalEventCodeLabel';

export default function SummaryEvents({ transportModel, noTopBackround, ...props }) {
  const {
    pickupLocations,
    dropoffLocations,
    latestEventCode,
    latenessStatusDifference,
    transportId,
  } = transportModel;

  const steps = [];

  steps.push({
    title: 'Transport Planned',
    icon: 'insert_drive_file',
    ...getPlan(pickupLocations, dropoffLocations),
  });

  if (transportModel.latestEventCode === 'TRACKING_FAILED') {
    steps.push({
      title: InternalEventCodeLabel.TRACKING_FAILED,
      icon: 'round_location_off',
      ...getTrackingFailed(),
    });
  } else {
    const pickupLocationsIsEmpty = isEmpty(pickupLocations);
    const dropoffLocationsIsEmpty = isEmpty(dropoffLocations);

    if (!pickupLocationsIsEmpty) {
      const latestPickupLocationIndex = findLastIndex(pickupLocations,
        (location) => location.arrivalActual || location.departureActual);

      pickupLocations.forEach((location, index) => {
        const isLatestLocation = index === latestPickupLocationIndex;
        const isNextLocation = index === latestPickupLocationIndex + 1;

        steps.push({
          title: 'In transit to pick up',
          icon: 'local_shipping',
          ...getInTransitToPickUp(
            location,
            latestEventCode,
            latenessStatusDifference,
            isLatestLocation, isNextLocation,
          ),
        });
        steps.push({
          title: 'Pick Up',
          icon: 'home',
          ...getPickUp(
            location,
            latestEventCode,
            latenessStatusDifference,
            isLatestLocation,
          ),
        });
      });
    } else {
      steps.push({
        title: 'In transit to pick up',
        icon: 'local_shipping',
      });
      steps.push({
        title: 'Pick Up',
        icon: 'home',
      });
    }
    if (!dropoffLocationsIsEmpty) {
      const latestDropoffLocationIndex = findLastIndex(dropoffLocations,
        (location) => location.arrivalActual || location.departureActual);

      dropoffLocations.forEach((location, index) => {
        const isLatestLocation = index === latestDropoffLocationIndex;
        const isNextLocation = index === latestDropoffLocationIndex + 1;

        steps.push({
          title: 'In transit to drop off',
          icon: 'local_shipping',
          ...getInTransitToDropOff(
            location,
            latestEventCode,
            latenessStatusDifference,
            isLatestLocation,
            isNextLocation,
          ),
        });
        steps.push({
          title: 'Drop Off',
          icon: 'flag',
          ...getDropOff(
            location,
            latestEventCode,
            latenessStatusDifference,
            isLatestLocation,
          ),
        });
      });
    } else {
      steps.push({
        title: 'In transit to drop off',
        icon: 'local_shipping',
      });
      steps.push({
        title: 'Drop Off',
        icon: 'flag',
      });
    }
  }

  return (
    <Div {...props}>
      {!noTopBackround && (
        <Flex fullWidth>
          <TransportStatusIconAndLabel
            latestEventCode={latestEventCode}
            transportId={transportId}
            lastEvent={
              transportModel.statusMessages && transportModel.statusMessages.length > 0
                ? transportModel.statusMessages[transportModel.statusMessages.length - 1]
                : null
}
          />
        </Flex>
      )}
      <Div padding={!noTopBackround ? { horizontal: 'l', vertical: 's' } : {}} style={{ minHeight: 400 }}>
        <ProgressLine steps={steps} />
      </Div>
    </Div>
  );
}
