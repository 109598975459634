/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import { Icon } from '@storaensods/seeds-react';

import CloseButton from '../CloseButton/CloseButton';
import styles from './DatePicker.module.scss';

function _DatePicker({
  selected, isClearable, onClear, ...props
}) {
  return (
    <DatePicker
      showTimeSelect
      selected={selected}
      customInput={
        <Input isClearable={isClearable && selected} onClear={onClear} />
      }
      className={styles.datePicker}
      {...props}
    />
  );
}

// eslint-disable-next-line react/prefer-stateless-function
class Input extends React.Component {
  render() {
    const {
      isClearable, onClear, onClick, disabled, ...props
    } = this.props;
    return (
      <div className={styles.datePickerCutomInputContainer}>
        <input disabled={disabled} {...props} />
        {isClearable && !disabled && (
          <CloseButton
            absolute
            small
            style={{ top: 0, bottom: 0, right: 38 }}
            onClick={onClear}
          />
        )}
        <span onClick={onClick} className={styles.datePickerCutomInputIcon}>
          <Icon>date_range</Icon>
        </span>
      </div>
    );
  }
}

export default observer(_DatePicker);
