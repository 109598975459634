import React from 'react';
import { observer } from 'mobx-react';
import { Icon, Button } from '@storaensods/seeds-react';
import Flex from '../../shared/components/Flex/Flex';
import colors from '../../shared/styles/Colors.module.scss';
import DivisionFilter from '../DivisionFilter/DivisionFilter';
import Div from '../../shared/components/Div/Div';

function MainFilters({ trackingFilterModel, filterOptionsLoading, divisionFilterModel }) {
  const { trackingFilterSelectModel } = trackingFilterModel;

  const buttonStyle = {
    marginBottom: 20,
    border: '1px solid',
  };

  return (
    <Div>
      <DivisionFilter
        divisionFilterModel={divisionFilterModel}
        filterOptionsLoading={filterOptionsLoading}
        margin={{ bottom: 'l' }}
      />
      <Flex
        spaceBetween
        fullWidth
        centeredVertically
      >
        <Button
          onClick={() => {
            trackingFilterSelectModel.setValue(['TRACKING_COMPLETED']);
          }}
          style={buttonStyle}
          type="secondary"
        >
          <Icon
            style={{ marginRight: 7 }}
          >
            flag
          </Icon>
          Tracking Completed
        </Button>
        <Button
          onClick={() => {
            trackingFilterSelectModel.setValue(['TRACKING_ONGOING']);
          }}
          style={buttonStyle}
          type="secondary"
        >
          <Icon
            style={{ marginRight: 7, color: colors.green100 }}
          >
            rss_feed
          </Icon>
          Tracking Ongoing
        </Button>
        <Button
          onClick={() => {
            trackingFilterSelectModel.setValue(['TRACKING_REQUESTED']);
          }}
          style={buttonStyle}
          type="secondary"
        >
          <Icon
            style={{ marginRight: 7, color: colors.blue100 }}
          >
            rss_feed
          </Icon>
          Tracking Requested
        </Button>
        <Button
          onClick={() => {
            trackingFilterSelectModel.setValue(['TRACKING_ERROR']);
          }}
          style={buttonStyle}
          type="secondary"
        >
          <Icon
            style={{ marginRight: 7, color: colors.orange }}
          >
            rss_feed
          </Icon>
          Tracking Request Error
        </Button>
        <Button
          onClick={() => {
            trackingFilterSelectModel.setValue(['TRACKING_FAILED', 'TRACKING_CANCELLED']);
          }}
          style={buttonStyle}
          type="secondary"
        >
          <Icon
            style={{ marginRight: 7, color: colors.red100 }}
          >
            rss_feed
          </Icon>
          Tracking Failed
        </Button>
        <Button
          onClick={() => {
            trackingFilterSelectModel.setValue(['NO_TRACKING']);
          }}
          style={buttonStyle}
          type="secondary"
        >
          <Icon
            style={{ marginRight: 7 }}
          >
            rss_feed
          </Icon>
          No Tracking
        </Button>
      </Flex>
    </Div>
  );
}

export default observer(MainFilters);
