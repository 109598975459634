import AsyncSelect from 'react-select/lib/Async';
import React from 'react';
import Select from 'react-select';
import colors from '../../styles/Colors.module.scss';

function _Select({
  maxWidth = 340, async, dropdownIsHidden, isMulti, ...props
}) {
  const styles = {
    ...(dropdownIsHidden && {
      indicatorSeparator: (base) => ({
        ...base,
        width: 0,
        visibility: 'hidden',
      }),
    }),
    ...(dropdownIsHidden && {
      dropdownIndicator: (base) => ({
        ...base,
        width: 0,
        visibility: 'hidden',
      }),
    }),
    valueContainer: (currentStyles) => ({
      ...currentStyles,
      maxWidth,
    }),
    placeholder: (currentStyles) => ({
      ...currentStyles,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth,
    }),
    multiValueLabel: (currentStyles) => ({
      ...currentStyles,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 250,
    }),
  };

  const theme = (currentTheme) => ({
    ...currentTheme,
    borderRadius: 0,
    colors: {
      primary: colors.blue100, // focus rectangle, option background:selected
      primary75: colors.blue75, // ???
      primary50: colors.blue50, // option background:active
      primary25: colors.blue25, // option background:hover
      danger: colors.red100, // ???
      dangerLight: colors.red25, // ???
      neutral0: colors.neutral0, // background
      neutral5: colors.neutral10, // background:disabled
      neutral10: colors.neutral20, // border:disabled, separator:disabled
      neutral20: colors.neutral40, // border, separator, icon (dropdown, loading, clear)
      neutral30: colors.blue25, // border:hover
      neutral40: colors.neutral30, // icon:hover
      neutral50: colors.neutral40, // placeholder
      neutral60: colors.neutral60, // icon:active
      neutral70: colors.neutral60, // ???
      neutral80: colors.neutral80, // input, icon:active:hover, cursor
      neutral90: colors.neutral80, // ???
    },
  });

  return async ? (
    <AsyncSelect styles={styles} theme={theme} {...props} />
  ) : (
    <Select data-testid="select-test" isMulti={isMulti} styles={styles} theme={theme} {...props} />
  );
}

export default _Select;
