import { action, decorate, observable } from 'mobx';

class TabMenuModel {
  items;

  selectedID;

  setSelectedID = (value) => { this.selectedID = value; return this.selectedID; };

  setItems = (value) => { this.items = value; return this.items; };
}

export default decorate(TabMenuModel, {
  items: observable,
  selectedID: observable,
  setSelectedID: action,
  setItems: action,
});
