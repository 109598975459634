import React, { useState, useContext } from 'react';
import { push as BurgerMenu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { Icon } from '@storaensods/seeds-react';
import colors from '../../styles/Colors.module.scss';
import Div from '../Div/Div';
import Element from '../Element/Element';
import styles from './Menu.module.scss';

const mainMenuStyles = {
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: colors.neutral10,
    // paddingTop: 89,
    borderRightWidth: 1,
    borderRightColor: colors.neutral20,
    borderRightStyle: 'solid',

  },
  bmOverlay: {
    background: 'transparent',
  },
};

const MenuContext = React.createContext({});

export function useMenu() {
  const [isMenuOpen, setMenuOpen] = useContext(MenuContext);

  return {
    isMenuOpen,
    setMenuOpen,
    toggleMenu: () => setMenuOpen(!isMenuOpen),
    open: () => setMenuOpen(true),
    close: () => setMenuOpen(false),
  };
}

function MenuItem({
  path, close, label, icon, onClick,
}) {
  const item = (
    <Div key={path} padding={{ left: 'xl' }} className={styles.menuItem} onClick={onClick}>
      {icon && (<Icon className={styles.menuItemIcon}>{icon}</Icon>)}
      <Element className={styles.menuItemLabel}>{label}</Element>
    </Div>
  );

  return (
    path ? (
      <Link to={path} onClick={close}>
        {item}
      </Link>
    ) : item
  );
}

export function Menu({ children, menuItem, ...props }) {
  const { setMenuOpen, isMenuOpen, close } = useMenu();

  return (
    <Div id="main-container" className={`${isMenuOpen ? styles.sidebarOpen : ''}`}>
      <BurgerMenu
        isOpen={isMenuOpen}
        onStateChange={({ isOpen }) => setMenuOpen(isOpen)}
        styles={mainMenuStyles}
        width={280}
        pageWrapId="page-wrap"
        outerContainerId="main-container"
        noOverlay
        customBurgerIcon={false}
        customCrossIcon={false}
        disableAutoFocus
      >
        <Div padding={{ top: 'l' }} className={styles.menuContainer} {...props}>
          {menuItem.map(({
            label,
            icon,
            path,
            onClick,
          }) => (
            <MenuItem
              key={label}
              close={close}
              label={label}
              icon={icon}
              path={path}
              onClick={onClick}
            />
          ))}
        </Div>
      </BurgerMenu>
      <Div id="page-wrap" className={styles.pageWrap}>
        {children}
      </Div>
    </Div>
  );
}

export function MenuProvider({ children }) {
  return (
    <MenuContext.Provider value={useState(false)}>
      {children}
    </MenuContext.Provider>
  );
}

export default function SideMenu({ children, menuItem }) {
  return (
    <MenuProvider>
      <Menu menuItem={menuItem}>
        {children}
      </Menu>
    </MenuProvider>
  );
}
