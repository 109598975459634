import React from 'react';
import { Input, Button } from '@storaensods/seeds-react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Form from '../shared/components/Form/Form';
import Flex from '../shared/components/Flex/Flex';

const validationSchema = yup.object({
  locationId: yup
    .string()
    .required(),
  latitude: yup
    .string()
    .required(),
  longitude: yup
    .string()
    .required(),
  geofenceRadius: yup
    .string()
    .required(),
  updatedBy: yup
    .string(),
});

const initialValues = {
  locationId: '',
  latitude: '',
  longitude: '',
  geofenceRadius: '0',
  updatedBy: '',
};

export default function LocationPage({ data, onSubmit }) {
  const {
    values, handleChange, handleSubmit, errors, submitForm, isSubmitting,
  } = useFormik({
    initialValues: data || initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Form onSubmit={handleSubmit}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label style={{ fontSize: 15, fontWeight: 'bold', marginBottom: 8 }}>
        {data ? 'Updating:' : 'Add location coordinates'}
      </label>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {data ? <label style={{ marginBottom: 8 }}>{values.locationId}</label>
        : (
          <>
            <Input
              style={{ marginBottom: 8 }}
              label="LocationId"
              id="locationId"
              size="sm"
              value={values.locationId}
              onChange={handleChange}
              helpText={errors && errors.locationId}
              invalid={errors && !!errors.locationId}
            />
          </>
        )}
      <Input
        style={{ marginBottom: 8 }}
        label="Latitude"
        id="latitude"
        size="sm"
        value={values.latitude}
        onChange={handleChange}
        helpText={errors && errors.latitude}
        invalid={errors && !!errors.latitude}
      />
      <Input
        style={{ marginBottom: 8 }}
        label="Longitude"
        id="longitude"
        size="sm"
        value={values.longitude}
        onChange={handleChange}
        helpText={errors && errors.longitude}
        invalid={errors && !!errors.longitude}
      />
      <Flex style={{ flexDirection: 'row-reverse' }}>
        <Button onClick={submitForm} disabled={isSubmitting}>
          { data ? 'Update' : 'Save'}
        </Button>
      </Flex>
    </Form>
  );
}
