import React, { useEffect, useState, useContext } from 'react';
import { autorun } from 'mobx';
import { toast } from 'react-toastify';
import AppContext from '../../AppContext';
import ErrorStatsList from './QuickAccessContent/ErrorStatsList';
import AlertsQuickAccess from './QuickAccessContent/AlertsQuickAccess';
import FiltersQuickAccess from './QuickAccessContent/FiltersQuickAccess';
import useUrlLocationQuery from '../../shared/methods/useUrlLocationQuery/useUrlLocationQuery';
import useCountersUpdate from '../../Filters/useCountersUpdate';

const MAX_ALERTS_TO_SHOW = 3;

export default function useQuickAccessBarItems() {
  const [items, setItems] = useState([]);
  const { quickAccessBarModel, filtersModel } = useContext(AppContext);
  const { location } = useUrlLocationQuery();
  const { updateAllTransportCounts } = useCountersUpdate();

  useEffect(() => autorun(() => {
    // This line is temporary so autorun can trigger with useEffect when savedFilters are updated.
    const { listOfFilterNamesAndValues } = filtersModel;
    // important when updating alerts but this won't update counters
    quickAccessBarModel.updateAlerts(listOfFilterNamesAndValues);

    const allAlerts = quickAccessBarModel.getAlerts();

    const errorStats = quickAccessBarModel.getErrorStats();
    const totalerrorsStats = Object.values(errorStats).reduce((acc, count) => acc + count, 0);

    const pageActions = [];
    if (['/filter'].includes(location.pathname)) {
      pageActions.push({
        isDivider: true,
        id: 'filters',
        icon: 'assignment',
        showBadge: false,
        autoOpen: true,
        onClick: () => {},
        Content: <FiltersQuickAccess />,
      });
    }

    const alertCounts = allAlerts.reduce((acc, item) => (acc + item.badge), 0);

    let alertsToShow = [];
    if (allAlerts.length <= MAX_ALERTS_TO_SHOW) {
      alertsToShow = [
        ...allAlerts,
      ];
    } else {
      alertsToShow = [
        ...allAlerts.slice(MAX_ALERTS_TO_SHOW),
        {
          id: 'alerts',
          tooltip: 'All alerts',
          icon: 'more_horiz',
          showBadge: !!alertCounts,
          badge: alertCounts,
          onClick: () => {},
          Content: <AlertsQuickAccess alerts={allAlerts} />,
        },
      ];
    }

    setItems([
      {
        id: 'error',
        tooltip: 'All Errors',
        icon: 'error_outline',
        showBadge: !!totalerrorsStats,
        badge: null,
        onClick: () => {},
        Content: <ErrorStatsList errorStats={errorStats} />,
      },
      ...alertsToShow,
      ...pageActions,
      {
        isDivider: true,
        id: 'refreshBar',
        icon: 'refresh',
        tooltip: 'Refresh bar',
        onClick: () => {
          updateAllTransportCounts();
          toast('Refresh requested');
        },
      },
    ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [location]);

  return items;
}
