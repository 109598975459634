import React, { useContext } from 'react';
import context from './DialogContext';
import Dialog from './Dialog';

export default function useDialog() {
  const [dialogs, setDialog] = useContext(context);

  return {
    removeLastDialog: () => {
      dialogs.pop();
      setDialog([
        ...dialogs,
      ]);
    },
    addDialog: (child, dialogProps) => {
      setDialog([
        ...dialogs,
        <Dialog {...dialogProps}>{child}</Dialog>,
      ]);
    },
  };
}
