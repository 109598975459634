import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { Icon, Tag } from '@storaensods/seeds-react';
import Div from '../../shared/components/Div/Div';
import Flex from '../../shared/components/Flex/Flex';
import Text from '../../shared/components/typography/Text';
import TabMenu from '../../shared/components/TabMenu/TabMenu';
import TransportComments from './TransportComments/TransportComments';
import TransportDetails from './TransportDetails/TransportDetails';
import TransportEvents from './TransportEvents/TransportEvents';
import TransportMap from './TransportMap/TransportMap';
import TransportMapModel from './TransportMap/TransportMapModel';
import TransportOriginAndDestination from './TransportOriginAndDestination/TransportOriginAndDestination';
// import colors from '../../shared/styles/Colors.module.scss';
import styles from './Transport.module.scss';
import CloseButton from '../../shared/components/CloseButton/CloseButton';
import PinIcon from '../../shared/components/PinIcon/PinIcon';
import TrackingIcon from './TrackingIcon';
import DropMenu from '../../shared/components/DropMenu/DropMenu';

import EventCode from './InternalEventCodeEnum';
import EventCodeLabel from './InternalEventCodeLabel';

import SummaryEvents from './SummaryEvents/SummaryEvents';

function Transport({ transportPopupModel, onCloseButtonClick, ...props }) {
  const {
    transportModel,
    tabMenuModel,
    expandedEvents,
    transportCommentsModel,
    setTransportPinStatus,
    loadTransport,
  } = transportPopupModel;

  const {
    transportId,
    milestones,
    pickupLocations,
    dropoffLocations,
    trackingStatus,
    trackingStatusRequestModel,
    transportErrors,
    latestEventCode,
  } = transportModel;

  const mapModel = new TransportMapModel({
    transportId,
    pickupLocations,
    dropoffLocations,
    milestones,
    // TODO: Not called from TransportMapModel any more
    onUpdateDestination: (lat, lng) => {
      transportModel.updateDestinationCoordinates(lat, lng);
    },
  });

  const { transportId: trasportIdFromParams } = useParams();

  useEffect(() => {
    if (!transportModel.transportId && trasportIdFromParams) {
      loadTransport(trasportIdFromParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackingStatusActionItems = trackingStatusRequestModel.getTrackingStatusActionItems();

  return (
    <Div className={styles.transport} {...props}>
      <Flex>
        <Div className={styles.transportSideDetailContainer}>
          <Div
            shownResponsive={{ widerThan: 'm' }}
            backgroundColor="neutral10"
            style={{ msGridRow: 2, msGridColumn: 1 }}
          >
            <SummaryEvents transportModel={transportModel} />
          </Div>
        </Div>
        <Div className={styles.transportMapContainer}>
          <Div
            // alignBottom
            backgroundColor="neutral0"
            padding={{ horizontal: 'l', top: 'm', bottom: 'm' }}
            style={{ msGridRow: 1, msGridColumn: 2 }}
          >
            <Div
              relative
              style={{ height: 32, marginBottom: 4 }}
            >
              <Flex>
                <PinIcon
                  style={{ marginTop: -10 }}
                  isPinned={transportModel.isPinned}
                  makeLarger
                  // padding={{ size: 'xl' }}
                  onClick={() => setTransportPinStatus(transportModel.transportId)}
                />

                <Flex
                  margin={{ left: 'm' }}
                  style={{ cursor: 'pointer', height: 24 }}
                  onClick={() => {
                    const {
                      protocol, hostname, port,
                    } = window.location;
                    copy(`${protocol}//${hostname}${port ? `:${port}` : ''}/transport/${transportId}`);
                    toast('Link copied in your clipboard');
                  }}
                >
                  <Icon size="lg">share</Icon>
                </Flex>

                <DropMenu
                  disabled={trackingStatusRequestModel.loading}
                  margin={{ left: 'm' }}
                  items={trackingStatusActionItems}
                >
                  <TrackingIcon
                    trackingStatus={trackingStatus}
                  />
                </DropMenu>

                {latestEventCode === EventCode.CANCELLED && (
                  <Text tagName="span" color="red100" margin={{ left: 's' }}>{EventCodeLabel.CANCELLED}</Text>
                )}
              </Flex>

              {!trasportIdFromParams && (
                <CloseButton
                  absolute
                  style={{ top: 0, right: 0 }}
                  // padding={{ size: 'xl' }}
                  onClick={onCloseButtonClick}
                />
              )}
            </Div>
            <TransportOriginAndDestination
              transportModel={transportModel}
              withFullAddress={false}
            />
          </Div>

          <TransportMap
            model={mapModel}
            shownResponsive={{ widerThan: 'm' }}
          />
        </Div>
      </Flex>
      <Div className={styles.transportTapContainer}>
        <Div
          shown={transportErrors.length}
          backgroundColor="neutral0"
          padding={{ horizontal: 'm', top: 'm' }}
        >
          {transportErrors.map((err) => (
            <Tag style={{ marginLeft: 8 }} type="negative" key={`error-tag-${transportId}-${err}`}>{err}</Tag>
          ))}
        </Div>

        <Div
          backgroundColor="neutral0"
          padding={{ horizontal: 'l', vertical: 'l' }}
          style={{ msGridRow: 2, msGridColumn: 2 }}
        >
          <TabMenu
            model={tabMenuModel}
            margin={{ bottom: 'l' }}
            onChange={(selectedID) => tabMenuModel.setSelectedID(selectedID)}
          />

          <SummaryEvents
            noTopBackround
            transportModel={transportModel}
            shown={tabMenuModel.selectedID === 'summary'}
          />

          <TransportDetails
            shown={tabMenuModel.selectedID === 'details'}
            transportModel={transportModel}
          />

          <TransportEvents
            shown={tabMenuModel.selectedID === 'events'}
            transportModel={transportModel}
            expandedEvents={expandedEvents}
            onEventSourceSelected={
              (eventSource) => transportModel.filterEventsBySource(eventSource)
            }
            onEventExpandToggle={
              (eventIndex) => transportPopupModel.toggleEventExpanded(eventIndex)
            }
          />

          {tabMenuModel.selectedID === 'map' && (
            <TransportMap model={mapModel} shown={tabMenuModel.selectedID === 'map'} />
          )}

          <TransportComments
            comments={transportModel.comments}
            model={transportCommentsModel}
            onComment={transportPopupModel.postTransportComment}
            shown={tabMenuModel.selectedID === 'comments'}
          />

        </Div>
      </Div>
    </Div>
  );
}

export default observer(Transport);
