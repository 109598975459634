import React from 'react';

function Form({ children, onSubmit, ...props }) {
  const onNativeSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={onNativeSubmit} {...props}>
      {children}
    </form>
  );
}

export default Form;
