import React from 'react';

export default (props) => {
  const { onChange, message } = props;
  return (
    <div className="buttons fadein">
      <div className="button">
        <h3>{message}</h3>
        <label htmlFor="single">
          Choose a file to upload:
        </label>
        <input type="file" id="single" onChange={onChange} />
      </div>
    </div>
  );
};
