import React from 'react';
import Div from '../../../shared/components/Div/Div';

import mapStyles from './TransportMap.module.scss';

// eslint-disable-next-line object-curly-newline
export default function SimpleMarker({ label, active, late, eventGroup }) {
  let color = active ? mapStyles.map_marker_icon_yellow : '';
  if (late) {
    color = mapStyles.map_marker_icon_red;
  }
  if (eventGroup) {
    color = mapStyles.map_marker_icon_neutral;
  }
  return (
    <Div className={mapStyles.simple}>
      {late}
      <Div className={`${mapStyles.map_marker_icon} ${color}`} />
      <Div className={mapStyles.map_marker_label}>{label}</Div>
    </Div>
  );
}
