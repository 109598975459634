import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@storaensods/seeds-react';
import DestinationForm from '../Location/LocationForm';
import LocationTable from '../Location/LocationTable';
import Div from '../shared/components/Div/Div';
import Page from './Page/Page';
import useDialog from '../shared/components/Dialog/useDialog';

function Location({ model }) {
  const {
    list,
    loading,
  } = model;

  const { addDialog, removeLastDialog } = useDialog();

  useEffect(() => {
    model.loadLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate = (values) => {
    model.updateLocationModel(values);
    removeLastDialog();
  };

  const onAdd = () => {
    addDialog(
      <DestinationForm
        data={null}
        onSubmit={onUpdate}
      />,
    );
  };

  return (
    <Page title="Location Coordinates">
      <Div margin={{ bottom: 'l' }}>
        <Button onClick={onAdd}>
          Add
        </Button>
        <LocationTable
          loading={loading}
          data={list}
          onRemove={(id) => {
            addDialog('Are you sure you want to delete?', {
              buttons: [
                {
                  type: 'negative',
                  label: 'Yes',
                  onClick: async () => {
                    await model.remove(id);
                    return true;
                  },
                },
                {
                  label: 'No',
                  onClick: () => true,
                },
              ],
            });
          }}
          onEdit={(item) => {
            addDialog(
              <DestinationForm
                data={item}
                onSubmit={onUpdate}
              />,
            );
          }}
        />
      </Div>
    </Page>
  );
}

export default observer(Location);
