import React from 'react';
import Grid from '../../shared/components/Grid/Grid';
import Text from '../../shared/components/typography/Text';
import Select from '../../shared/components/Select/Select';
import Div from '../../shared/components/Div/Div';

function CarrierCustomerFilters({ model, filterOptionsLoading, ...props }) {
  const { carrierModel, customerModel } = model;

  return (
    <Grid columns="1fr 1fr" fullWidth {...props}>
      <Text
        captionSmall
        bold
        padding={{ bottom: 's' }}
        color="neutral100"
        style={{ msGridRow: 1, msGridColumn: 1 }}
      >
        Carrier
      </Text>

      <Text
        captionSmall
        bold
        padding={{ bottom: 's' }}
        color="neutral100"
        margin={{ left: 'xs' }}
        style={{ msGridRow: 1, msGridColumn: 2 }}
      >
        Buyer
      </Text>

      <Div style={{ msGridRow: 2, msGridColumn: 1 }}>
        <Select
          isMulti
          options={carrierModel.options}
          value={carrierModel.value}
          isSearchable
          isClearable
          isLoading={filterOptionsLoading}
          isDisabled={filterOptionsLoading}
          placeholder="Select Carrier..."
          onChange={(option) => carrierModel.setValue(option)}
        />
      </Div>

      <Div margin={{ left: 'xs' }} style={{ msGridRow: 2, msGridColumn: 2 }}>
        <Select
          isMulti
          options={customerModel.options}
          value={customerModel.value}
          isSearchable
          isClearable
          isLoading={filterOptionsLoading}
          isDisabled={filterOptionsLoading}
          placeholder="Select Buyer..."
          onChange={(option) => customerModel.setValue(option)}
        />
      </Div>
    </Grid>
  );
}

export default CarrierCustomerFilters;
