import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

import EventCodeEnum, { EventCodeEnumOrdered } from '../InternalEventCodeEnum';

import Text from '../../../shared/components/typography/Text';
import ProgressStepsEnum from '../../../shared/components/ProgressLine/ProgressStepsEnum';

import { DEFAULT_FULL_FORMAT } from '../../../Filters/TimeRangeFilters/TimeRangeFormat';

import getLatenessStatusDifferenceLabel from '../../../TransportsTable/LatenessStatusDifferenceCell/getLatenessStatusDifferenceLabel';

// --------- Helpers

type getStepArgs = {
  latestEventCode: string,
  activeStep: string,
  doneStep?: string,
  hasReachedLocation?: boolean,
  isLatestLocation?: boolean,
  isNextLocation?: boolean
}

type LocationStub = {
  arrivalActual: string,
  arrivalEstimated: string,
  arrivalRequestedFrom: string,
  arrivalRequestedTo: string,
  departureActual: string,
  loadingPlannedFrom: string,
  loadingPlannedTo: string,
}

function inTransitStep(latestEvent) {
  return latestEvent === EventCodeEnum.IN_TRANSIT_PICK_UP
  || latestEvent === EventCodeEnum.IN_TRANSIT_DROP_OFF;
}

function arrivalStep(latestEvent) {
  return latestEvent === EventCodeEnum.ARRIVED_PICK_UP
  || latestEvent === EventCodeEnum.ARRIVED_DROP_OFF;
}

function departureStep(latestEvent) {
  return latestEvent === EventCodeEnum.IN_TRANSIT_DROP_OFF
  || latestEvent === EventCodeEnum.DEPARTED_DROP_OFF;
}

function getStep({
  latestEventCode,
  activeStep,
  doneStep,
  hasReachedLocation,
  isLatestLocation,
  isNextLocation,
}: getStepArgs) {
  if (latestEventCode === EventCodeEnum.CANCELLED) {
    return { type: ProgressStepsEnum.ERROR };
  }

  if (latestEventCode === doneStep
    && hasReachedLocation
    && (isLatestLocation && inTransitStep(latestEventCode))) {
    return ProgressStepsEnum.DONE;
  }

  if (latestEventCode === activeStep) {
    const activeArrival = isLatestLocation && arrivalStep(latestEventCode);
    const activeInTransit = isNextLocation && inTransitStep(latestEventCode);

    if (activeArrival || activeInTransit) {
      return ProgressStepsEnum.ACTIVE;
    }
    if (!hasReachedLocation) {
      return ProgressStepsEnum.EMPTY;
    }
    return ProgressStepsEnum.DONE;
  }

  const locationReachedAndNotOnDepartureStep = hasReachedLocation
  && !departureStep(latestEventCode);

  // eslint-disable-next-line max-len
  const latestEventCodeAfterCurrentActiveStepWithLocationAlreadyReached = EventCodeEnumOrdered[latestEventCode] > EventCodeEnumOrdered[activeStep]
  && hasReachedLocation;

  if (locationReachedAndNotOnDepartureStep
    || latestEventCodeAfterCurrentActiveStepWithLocationAlreadyReached) {
    return ProgressStepsEnum.DONE;
  }
  return ProgressStepsEnum.EMPTY;
}

type SubTextsType = Array<{
  text: string,
  enabled: boolean,
}>

type BodyProps = {
  texts: SubTextsType,
}

function Body({ texts = [] }: BodyProps) {
  return (
    <>
      {texts.map(({ text, enabled }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text tagName="p" key={`${text}-${index}`} style={{ color: enabled ? undefined : 'silver' }}>
          {text}
        </Text>
      ))}
    </>
  );
}

const formatDate = (date, format) => (date ? moment(date).format(format) : 'N/A');

// --------- Helpers end

// --------- Step functions

type StepFunctionValue = {
  type: ProgressStepsEnum,
  body: React.ReactElement,
}

export function getPlan(
  pickupLocations: Array<LocationStub>,
  dropoffLocations: Array<LocationStub>,
): StepFunctionValue | {} {
  if (isEmpty(pickupLocations) || isEmpty(dropoffLocations)) {
    return {};
  }

  const body: SubTextsType = [];

  pickupLocations.forEach((location) => {
    body.push({
      text: `Pick up RTA, from: ${formatDate(location.loadingPlannedFrom, DEFAULT_FULL_FORMAT)}`,
      enabled: !!location.loadingPlannedFrom,
    });
    body.push({
      text: `Pick up RTA, to: ${formatDate(location.loadingPlannedTo, DEFAULT_FULL_FORMAT)}`,
      enabled: !!location.loadingPlannedTo,
    });
  });

  dropoffLocations.forEach((location) => {
    body.push({
      text: `Drop off RTA, from: ${formatDate(location.arrivalRequestedFrom, DEFAULT_FULL_FORMAT)}`,
      enabled: !!location.arrivalRequestedFrom,
    });
    body.push({
      text: `Drop off RTA, to: ${formatDate(location.arrivalRequestedTo, DEFAULT_FULL_FORMAT)}`,
      enabled: !!location.arrivalRequestedTo,
    });
  });

  return {
    type: ProgressStepsEnum.DONE,
    body: <Body texts={body} />,
  };
}

export function getInTransitToPickUp(
  location: LocationStub,
  latestEventCode: string,
  latenessStatusDifference: number,
  isLatestLocation: boolean,
  isNextLocation: boolean,
) {
  if (isEmpty(location)) {
    return {};
  }

  const body: SubTextsType = [];

  if (latestEventCode === EventCodeEnum.IN_TRANSIT_PICK_UP) {
    body.push({
      text: `Estimated Arrival: ${formatDate(location.arrivalEstimated, DEFAULT_FULL_FORMAT)}`,
      enabled: !!location.arrivalEstimated,
    });

    const latenessStatus = getLatenessStatusDifferenceLabel(latenessStatusDifference);
    if (latenessStatus) {
      body.push({ text: `Lateness: ${latenessStatus}`, enabled: true });
    }
  }

  const hasReachedLocation = Boolean(location.arrivalActual || location.departureActual);

  const type = getStep({
    latestEventCode,
    activeStep: EventCodeEnum.IN_TRANSIT_PICK_UP,
    hasReachedLocation,
    isLatestLocation,
    isNextLocation,
  });

  return {
    type,
    body: <Body texts={body} />,
  };
}

export function getPickUp(
  location: LocationStub,
  latestEventCode: string,
  latenessStatusDifference: number,
  isLatestLocation: boolean,
) {
  if (isEmpty(location)) {
    return {};
  }

  let loadingTime;
  if (latestEventCode === EventCodeEnum.ARRIVED_PICK_UP) {
    loadingTime = getLatenessStatusDifferenceLabel(latenessStatusDifference);
  }

  const body: SubTextsType = [];

  body.push({
    text: `Actual Arrival: ${formatDate(location.arrivalActual, DEFAULT_FULL_FORMAT)}`,
    enabled: !!location.arrivalActual,
  });

  if (loadingTime) {
    body.push({ text: `Loading time: ${loadingTime}`, enabled: true });
  }

  body.push({
    text: `Actual Departure: ${formatDate(location.departureActual, DEFAULT_FULL_FORMAT)}`,
    enabled: !!location.departureActual,
  });

  const hasReachedLocation = Boolean(location.arrivalActual || location.departureActual);

  const type = getStep({
    latestEventCode,
    activeStep: EventCodeEnum.ARRIVED_PICK_UP,
    doneStep: EventCodeEnum.IN_TRANSIT_DROP_OFF,
    hasReachedLocation,
    isLatestLocation,
  });

  return {
    type,
    body: <Body texts={body} />,
  };
}

export function getInTransitToDropOff(
  location: LocationStub,
  latestEventCode: string,
  latenessStatusDifference: number,
  isLatestLocation: boolean,
  isNextLocation: boolean,
) {
  if (isEmpty(location)) {
    return {};
  }

  const body: SubTextsType = [];

  if (latestEventCode === EventCodeEnum.IN_TRANSIT_DROP_OFF) {
    body.push({
      text: `Estimated Arrival: ${formatDate(location.arrivalEstimated, DEFAULT_FULL_FORMAT)}`,
      enabled: !!location.arrivalEstimated,
    });

    const latenessStatus = getLatenessStatusDifferenceLabel(latenessStatusDifference);
    if (latenessStatus) {
      body.push({ text: `Lateness: ${latenessStatus}`, enabled: true });
    }
  }

  const hasReachedLocation = Boolean(location.arrivalActual || location.departureActual);

  const type = getStep({
    latestEventCode,
    activeStep: EventCodeEnum.IN_TRANSIT_DROP_OFF,
    hasReachedLocation,
    isLatestLocation,
    isNextLocation,
  });

  return {
    type,
    body: <Body texts={body} />,
  };
}

export function getDropOff(
  location: LocationStub,
  latestEventCode: string,
  latenessStatusDifference: number,
  isLatestLocation: boolean,
) {
  if (isEmpty(location)) {
    return {};
  }

  const body: SubTextsType = [];
  body.push({
    text: `Actual Arrival: ${formatDate(location.arrivalActual, DEFAULT_FULL_FORMAT)}`,
    enabled: !!location.arrivalActual,
  });

  if (latestEventCode === EventCodeEnum.ARRIVED_DROP_OFF) {
    const latenessStatus = getLatenessStatusDifferenceLabel(latenessStatusDifference);
    if (latenessStatus) {
      body.push({ text: `Lateness: ${latenessStatus}`, enabled: true });
    }
  }

  body.push({
    text: `Actual Departure: ${formatDate(location.departureActual, DEFAULT_FULL_FORMAT)}`,
    enabled: !!location.departureActual,
  });

  const hasReachedLocation = !!(location.arrivalActual || location.departureActual);

  const type = getStep({
    latestEventCode,
    activeStep: EventCodeEnum.ARRIVED_DROP_OFF,
    doneStep: EventCodeEnum.DEPARTED_DROP_OFF,
    hasReachedLocation,
    isLatestLocation,
  });

  return {
    type,
    body: <Body texts={body} />,
  };
}

export function getTrackingFailed() {
  const body: SubTextsType = [];
  body.push({ text: 'No tracking available', enabled: true });

  const type = ProgressStepsEnum.ERROR;
  return {
    type,
    body: <Body texts={body} />,
  };
}

// --------- Step functions end
