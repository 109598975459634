import React from 'react';
import { Icon, Logo, Button } from '@storaensods/seeds-react';
import Text from '../typography/Text';
import Flex from '../Flex/Flex';
import Div from '../Div/Div';
import Search from '../../../Search/Search';
import { useMenu } from '../Menu/Menu';
import { useUserModel } from '../../../User';

import styles from './Header.module.scss';

export default function Header({
  searchModel,
  onTransportSearch,
}) {
  const {
    isMenuOpen,
    toggleMenu,
  } = useMenu();

  const {
    logout,
  } = useUserModel();

  return (
    <Div className={styles.Header}>
      {/* <PageMargin> */}
      <Flex fullWidth centeredVertically className={styles.HeaderContainer}>
        <Flex centeredVertically style={{ width: '25%' }}>
          <Flex centered className={styles.menuIconContainer} onClick={() => toggleMenu()}>
            {isMenuOpen ? <Icon size="lg">close</Icon> : <Icon size="lg">menu</Icon>}
          </Flex>
          <Div className={styles.logoContainer}>
            <Logo size="sm" className={styles.logo} />
          </Div>
          <Text tagName="span" bold captionSmall padding={{ horizontal: 's' }} className={styles.HeaderTitle}>
            Predictive Visibility Platform
          </Text>
        </Flex>
        <Flex centered style={{ width: '50%' }}>
          <Search
            style={{ width: 500, margin: 0 }}
            model={searchModel}
            margin={{ bottom: 'xl' }}
            onTransportSearch={onTransportSearch}
          />
        </Flex>
        <Flex style={{ width: '25%', flexDirection: 'row-reverse' }}>
          <Button flat type="secondary" icon="account_circle" onClick={logout} />
        </Flex>
      </Flex>
      {/* </PageMargin> */}
    </Div>
  );
}
