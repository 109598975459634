import FiltersModel from './Filters/FiltersModel';
import TransportPopupModel from './TransportPopup/TransportPopupModel';
import TransportsModel from './TransportsTable/TransportsModel';
import SearchModel from './Search/SearchModel';
import TrackingRulesModel from './TrackingRules/TrackingRulesModel';
import QuickAccessBarModel from './pages/QuickAccess/QuickAccessBarModel';
import LocationModel from './Location/LocationModel';
import OpeningHoursModel from './OpeningHours/OpeningHoursModel';
import SortingModel from './TransportsTable/Sorting/SortingModel';

class AppModel {
  filtersModel = new FiltersModel();

  searchModel = new SearchModel();

  transportsModel = new TransportsModel();

  transportPopupModel = new TransportPopupModel();

  TrackingRulesModel = new TrackingRulesModel();

  quickAccessBarModel = new QuickAccessBarModel();

  locationModel = new LocationModel();

  openingHoursModel = new OpeningHoursModel();

  sortingModel = new SortingModel();
}

export default AppModel;
