import {
  action, computed, observable, autorun,
} from 'mobx';
import moment from 'moment';
import OriginDestinationFiltersModel from './OriginDestinationFilters/OriginDestinationFiltersModel';
import TimeRangeFiltersModel from './TimeRangeFilters/TimeRangeFiltersModel';
import EventFiltersModel from './EventFilters/EventFiltersModel';
import EarlyAndLateFiltersModel from './EarlyAndLateFilters/EarlyAndLateFiltersModel';
import CarrierCustomerFiltersModel from './CarrierCustomerFilters/CarrierCustomerFiltersModel';
import getFilterOptions from './getFilterOptions';
import saveFilterValues from './saveFilterValues';
import getFilterValues from './getFilterValues';
import SavedFiltersSelectModel from './SavedFiltersSelect/SavedFiltersSelectModel';
import TrackingFilterModel from './TrackingFilter/TrackingFilterModel';
import OrderNumberFilterModel from './OrderNumberFilter/OrderNumberFilterModel';
import { DEFAULT_FULL_FORMAT } from './TimeRangeFilters/TimeRangeFormat';
import SavedFilterListModel from './SavedFilterListModel';
import DivisionFilterModel from './DivisionFilter/DivisionFilterModel';
import SortingModel from '../TransportsTable/Sorting/SortingModel';

type TTag = { label: string, key: string, remove?: () => void }

// both are used to wait for compute filters to be ready and fectch from API
let interval;
let digestedFilters;

// used to wait untill the next counter fetch
let transportCountInterval;

/**
 * FiltersModel consist of:
 * ……………………………
 *  • Saved-filter-model: used for forming an object so that it be saved to API
 *  • Other Models for filter options
 */
export default class FiltersModel {
  @observable filterOptionsLoading = false;

  filterOptionsLoadError;

  filterValuesLoadError;

  eventFiltersModel = new EventFiltersModel();

  earlyAndLateFiltersModel = new EarlyAndLateFiltersModel();

  timeRangeFiltersModel = new TimeRangeFiltersModel();

  originDestinationFiltersModel = new OriginDestinationFiltersModel();

  carrierCustomerFiltersModel = new CarrierCustomerFiltersModel();

  savedFiltersSelectModel = new SavedFiltersSelectModel();

  trackingFilterModel = new TrackingFilterModel();

  orderNumberFilterModel = new OrderNumberFilterModel();

  savedFilterListModel = new SavedFilterListModel();

  divisionFilterModel = new DivisionFilterModel();

  selectedFilterName: string | null = null;

  sortingModel = new SortingModel();

  @observable optionsLoaded = false;

  shouldKeepSelectedFilterName = false;

  allTags: Array<TTag> = [];

  /**
   * Used to update the url with filter name or/and values
   */
  // eslint-disable-next-line no-unused-vars
  updateSavedFilterInUrlSearch = (queryParams = null as any) => {};

  setOnUpdateSavedFilterInUrlSearch = (callback) => {
    this.updateSavedFilterInUrlSearch = callback;
  };

  /*
  * 1- Load Filter options from API
  * 2- Load Filter values (Saved filters) from API
  * 3- Load counters for each saved filter name
  * 4- Use the saved fiters name to update the counters each 5 minutes
  */
  init = async (loadTransports, updateTransportCounts, updateAllTransportCounts) => {
    await this.loadFilterOptions();
    await this.loadFilterValues();

    const savedFilterNames = Array.from(this.savedFilterListModel.savedFiltersNames);

    clearInterval(transportCountInterval);

    let counter = 0;

    const getCountersFromAPI = async () => {
      const filterNameToUse = savedFilterNames[counter];

      counter += 1;

      if (counter === savedFilterNames.length) {
        // Start again with the first saved filter
        counter = 0;
      }

      await updateTransportCounts(filterNameToUse);
    };

    updateAllTransportCounts();
    // Check one filter every 5 minutes
    transportCountInterval = setInterval(getCountersFromAPI, 60000 * 30);

    return new Promise((res) => {
      // Autorun makes whatever is inside the autorun arrow function run every time one of
      // its dependencies change, in this the filters model.
      autorun(() => {
        clearTimeout(interval);
        digestedFilters = this.filters;
        interval = setTimeout(async () => {
          this.unsetSavedFilterSelect();
          await loadTransports({
            overwriteExistingData: true,
            filters: digestedFilters,
            sorting: this.sortingModel,
          });
          this.prepareAndSaveFilterValues();
          res();
        }, 100); // a delay in needed to digest all filters compute
      });
    });
  };

  /**
  * Return a saved-filter values and props
  */
  getSavedFilterValuesAndPropsByName(filterName) {
    return this.savedFilterListModel.getSavedFilterValuesAndPropsByName(filterName);
  }

  /*
  * Used to check if any of filter values has changed
  * and to copy the values and list of values of all filters to the filter-form in alerts view
  */
  @computed
  get savedFilters() {
    return this.savedFilterListModel.savedFilters;
  }

  /**
   * List of saved filter names
   */
  @computed
  get savedFiltersNames() {
    return this.savedFilterListModel.savedFiltersNames;
  }

  /**
   * Used to return a list of saved filters values and it's props
   */
  @computed
  get listOfFilterNamesAndValues() {
    return this.savedFilterListModel.listOfFilterNamesAndValues;
  }

  /*
  * These are the values that are used for querying to API
  *
  * The function will return an object that group filter-values by
  * filter-type (Customer Number, Event, Date, ... etc)
  * And WILL OVERRIDE any value like origin and destination values.
  *     ………………………………
  */
  @computed
  get filters() {
    return {
      ...this.eventFiltersModel.filters,
      ...this.earlyAndLateFiltersModel.filters,
      ...this.timeRangeFiltersModel.filters,
      ...this.originDestinationFiltersModel.filters,
      ...this.carrierCustomerFiltersModel.filters,
      ...this.trackingFilterModel.filters,
      ...this.orderNumberFilterModel.filters,
      ...this.divisionFilterModel.filters,
    };
  }

  /*
  * These are the acctual values that are used for fill the Filter componets.
  *
  * The function will return an object that group filter-values by
  * filter-type (Customer Number, Event, Date, ... etc)
  * And will NOT override any value like origin and destination values.
  *          ………
  */
  exactFilters() {
    return {
      ...this.eventFiltersModel.filters,
      ...this.earlyAndLateFiltersModel.filters,
      ...this.timeRangeFiltersModel.filters,
      ...this.originDestinationFiltersModel.exactFilters,
      ...this.carrierCustomerFiltersModel.filters,
      ...this.trackingFilterModel.filters,
      ...this.orderNumberFilterModel.filters,
      ...this.divisionFilterModel.filters,
    };
  }

  addFilterTags(listName, { list, remove }, prefex?: string) {
    (list || []).forEach((tag, i) => {
      const { label, value } = tag || {};
      this.allTags.push({
        key: `${listName}_${i}`,
        label: prefex ? prefex + label : label,
        remove: () => {
          this.updateSavedFilterInUrlSearch();
          remove(value);
        },
      });
    });
  }

  addDateTag(dateName, { value, remove }, prefex) {
    if (value) {
      this.allTags.push({
        key: dateName,
        label: `${prefex}: ${moment(value).format(DEFAULT_FULL_FORMAT)}`,
        remove: () => {
          this.updateSavedFilterInUrlSearch();
          remove();
        },
      });
    }
  }

  addSimpleTag(checkName, { value, remove }, prefex: string) {
    if (value) {
      this.allTags.push({
        key: checkName,
        label: prefex,
        remove: () => {
          this.updateSavedFilterInUrlSearch();
          remove(value);
        },
      });
    }
  }

  getSavedFiltersOption() {
    return this.savedFilterListModel.savedFiltersNamesWithLabels;
  }

  @computed
  get allFilterTags() {
    const {
      // earlierThan,
      // laterThan,
      latestEventCode,
    } = this.eventFiltersModel.tags;
    const {
      earlierThan,
      laterThan,
    } = this.earlyAndLateFiltersModel.tags;
    const {
      departureFrom,
      departureTo,
      arrivalFrom,
      arrivalTo,
      useCurrentDateForDeparture,
      useCurrentDateForArrival,
    } = this.timeRangeFiltersModel.tags;
    const {
      originCountry,
      originLocation,
      destinationCountry,
      destinationLocation,
    } = this.originDestinationFiltersModel.tags;
    const { carrier, customer } = this.carrierCustomerFiltersModel.tags;
    const { trackingStatus } = this.trackingFilterModel.tags;
    const { orderNumber } = this.orderNumberFilterModel.tags;
    const { division } = this.divisionFilterModel.tags;

    this.allTags = [];

    this.addDateTag('departureFrom', departureFrom, 'Pick up RTA from');
    this.addDateTag('departureTo', departureTo, 'Pick up RTA to');
    this.addDateTag('arrivalFrom', arrivalFrom, 'Drop off RTA from');
    this.addDateTag('arrivalTo', arrivalTo, 'Drop off RTA to');
    this.addSimpleTag('useCurrentDateForDeparture', useCurrentDateForDeparture, 'Use current date for Pickup RTA');
    this.addSimpleTag('useCurrentDateForArrival', useCurrentDateForArrival, 'Use current date for Drop off RTA');

    this.addFilterTags('earlierThan', earlierThan);
    this.addFilterTags('laterThan', laterThan);
    this.addFilterTags('latestEventCode', latestEventCode);

    if (originLocation.list) {
      this.addFilterTags('origin.location', originLocation, 'Pick up location: ');
    } else if (originCountry.list) {
      this.addFilterTags('origin.country', originCountry, 'Pick up location: ');
    }
    if (destinationLocation.list) {
      this.addFilterTags('destination.location', destinationLocation, 'Drop off location: ');
    } else if (destinationCountry.list) {
      this.addFilterTags('destination.country', destinationCountry, 'Drop off location: ');
    }

    this.addFilterTags('carrier', carrier);
    this.addFilterTags('customer', customer);
    this.addFilterTags('trackingStatus', trackingStatus);
    this.addFilterTags('orderNumber', orderNumber);
    this.addFilterTags('division', division);

    return this.allTags;
  }

  @action
    setFilterOptions = (filterOptions) => {
      this.eventFiltersModel.setFilterOptions();
      this.earlyAndLateFiltersModel.setFilterOptions();
      this.trackingFilterModel.setFilterOptions();
      this.divisionFilterModel.setFilterOptions();
      this.originDestinationFiltersModel.setFilterOptions({ ...filterOptions });
      this.carrierCustomerFiltersModel.setFilterOptions({ ...filterOptions });
    };

  getFilterOptions = () => (
    {
      ...this.carrierCustomerFiltersModel.getFilterOptions(),
      ...this.originDestinationFiltersModel.getFilterOptions(),
      ...this.divisionFilterModel.getFilterOptions(),
    }
  );

  copyFilterOptions = (options) => {
    this.eventFiltersModel.setFilterOptions();
    this.earlyAndLateFiltersModel.setFilterOptions();
    this.trackingFilterModel.setFilterOptions();
    this.originDestinationFiltersModel.copyFilterOptions(options);
    this.carrierCustomerFiltersModel.copyFilterOptions(options);
    this.divisionFilterModel.copyFilterOptions(options);
    this.optionsLoaded = true;
  };

  overrideTimeRangeFilterValues(filterValues) {
    if (filterValues) {
      this.timeRangeFiltersModel.setCurrentDateIfTrue({
        useCurrentDateForDeparture: filterValues.useCurrentDateForDeparture,
        useCurrentDateForArrival: filterValues.useCurrentDateForArrival,
      });
    }
  }

  setFilterValuesAndProps = (filterValuesAndProps) => {
    if (filterValuesAndProps) {
      const {
        values: filterValues = {},
        props: filterProps = {},
      } = filterValuesAndProps;

      this.timeRangeFiltersModel.setFilterValues(filterValues, filterProps);
      this.overrideTimeRangeFilterValues(filterValues);
      this.eventFiltersModel.setFilterValues(filterValues);
      this.earlyAndLateFiltersModel.setFilterValues(filterValues);
      this.carrierCustomerFiltersModel.setFilterValues(filterValues);
      this.originDestinationFiltersModel.setFilterValues(filterValues);
      this.trackingFilterModel.setFilterValues(filterValues);
      this.orderNumberFilterModel.setFilterValues(filterValues);
      this.divisionFilterModel.setFilterValues(filterValues);
    }
  };

  unsetFilterValuesAndProps() {
    this.setFilterValuesAndProps({});
  }

  setFiltersFromUrl = (filtersFromUrl) => {
    const filtersString = decodeURIComponent(filtersFromUrl);
    const filtersFromUrlJson = JSON.parse(filtersString);
    this.setFilterValuesAndProps({ values: filtersFromUrlJson });
  };

  getFiltersForUrl = () => {
    const filtersString = JSON.stringify(this.exactFilters());
    return decodeURIComponent(filtersString);
  };

  loadFilterOptions = async () => {
    this.filterOptionsLoadError = undefined;
    this.filterOptionsLoading = true;

    const { callWasSuccessful, response, error } = await getFilterOptions();

    if (callWasSuccessful) {
      this.setFilterOptions(response);
    } else {
      this.filterOptionsLoadError = JSON.stringify(String(error));
      this.setFilterOptions({});
    }

    this.filterOptionsLoading = false;
    this.optionsLoaded = true;
  };

  getSelectedSavedFilterValuesAndProps() {
    if (this.selectedFilterName) {
      return this.savedFilterListModel.getSavedFilterValuesAndPropsByName(
        this.selectedFilterName,
      ) || { props: {}, values: {} };
    }
    return { props: {}, values: {} };
  }

  setSavedFiltersObj = (obj) => {
    this.savedFilterListModel.set(obj);

    // if filters were set from url
    const hasFilters = !!Object.keys(this.filters).length;

    if (!hasFilters) {
      // Set current selected filter name
      this.selectedFilterName = this.savedFilterListModel.getCurrentFilterName() || null;
      this.shouldKeepSelectedFilterName = !!this.selectedFilterName;

      this.setFilterValuesAndProps(
        this.savedFilterListModel.getCurrentFilterValuesAndProps(),
      );
    }

    this.savedFiltersSelectModel.updateOptions(
      this.savedFilterListModel.savedFiltersNamesWithLabels,
    );

    if (this.selectedFilterName) {
      this.savedFiltersSelectModel.setValue({
        label: this.selectedFilterName,
        value: this.selectedFilterName,
      });
    }
  };

  stripArchivedStatus = (object) => {
    const { archivedStatus, ...rest } = object;
    return rest;
  };

  getTransformedResponse = (responseObject) => {
    const latestFilter = this.stripArchivedStatus(responseObject.latestFilter);
    const savedFilters = responseObject.listOfFilterNamesAndValues;
    Object.keys(savedFilters).forEach((filterName) => {
      savedFilters[filterName].values = this.stripArchivedStatus(savedFilters[filterName].values);
    });

    return {
      ...responseObject,
      latestFilter,
      listOfFilterNamesAndValues: savedFilters,
    };
  };

  loadFilterValues = async () => {
    const { callWasSuccessful, response, error } = await getFilterValues();

    if (callWasSuccessful) {
      if (response) {
        const transformedResponse = this.getTransformedResponse(JSON.parse(response));
        this.setSavedFiltersObj(transformedResponse);
      }
    } else {
      this.filterValuesLoadError = JSON.stringify(String(error));
    }
  };

  resetFilterValues = async () => {
    this.selectedFilterName = null;
    this.unsetSavedFilterSelect();
    this.setFilterValuesAndProps({ props: {}, values: {} });

    this.updateSavedFilterInUrlSearch();

    this.savedFilterListModel.clearCurrentFilterValues();

    await saveFilterValues(this.savedFilterListModel.savedFilters);
  };

  setSavedFilterValuesByName(name) {
    this.shouldKeepSelectedFilterName = true;
    this.selectedFilterName = name;
    this.savedFiltersSelectModel.setValueByName(name);
    const valuesAndProps = this.savedFilterListModel.getSavedFilterValuesAndPropsByName(name);
    this.setFilterValuesAndProps(valuesAndProps || { props: {}, values: {} });

    if (name) {
      this.updateSavedFilterInUrlSearch({ filtername: name });
    } else {
      this.updateSavedFilterInUrlSearch();
    }
  }

  unsetSavedFilterSelect = () => {
    if (!this.shouldKeepSelectedFilterName) {
      this.savedFiltersSelectModel.setValue(null);
      this.selectedFilterName = null;
    }
    this.shouldKeepSelectedFilterName = false;
  };

  prepareAndSaveFilterValues = async () => {
    // Save current filters to API
    this.savedFilterListModel.updateLatestFilterValue(
      this.selectedFilterName,
      this.selectedFilterName ? {} : this.exactFilters(),
    );

    await saveFilterValues(this.savedFilterListModel.savedFilters);
  };

  updateSavedFilterObj = (formModel) => {
    this.savedFilterListModel.updateSavedFilterValues(
      formModel.filterName,
      this.exactFilters(),
      formModel,
    );
  };

  @action
    addNewSavedFilter = async (formModel) => {
      this.updateSavedFilterObj(formModel);

      this.setSavedFilterValuesByName(formModel.filterName);

      await saveFilterValues(this.savedFilterListModel.savedFilters);
    };

  @action
    saveFilter = async (formModel) => {
      this.updateSavedFilterObj(formModel);

      await saveFilterValues(this.savedFilterListModel.savedFilters);
    };

  @action
    removeSelectedFilters = async (filterNames) => {
      this.savedFilterListModel.removeSavedFiltersNameAndValues(filterNames);

      this.selectedFilterName = null;

      this.updateSavedFilterInUrlSearch();

      this.setFilterValuesAndProps({ props: {}, values: {} });

      await saveFilterValues(this.savedFilterListModel.savedFilters);
    };
}
