import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
// import { Slider } from '@storaensods/seeds-react';
import Text from '../../shared/components/typography/Text';
import Grid from '../../shared/components/Grid/Grid';
import Div from '../../shared/components/Div/Div';
import Flex from '../../shared/components/Flex/Flex';
// import InverseSlider from '../../shared/components/InverseSlider/InverseSlider';
import Checkbox from '../../shared/components/Checkbox/Checkbox';
import Select from '../../shared/components/Select/Select';

function EarlyAndLateFilters({ model, ...props }) {
  const {
    lateAndEarlyModel,
    dayModelEarly,
    hourModelEarly,
    minuteModelEarly,
    dayModelLate,
    hourModelLate,
    minuteModelLate,
  } = model;

  const [earlyDays, setEarlyDays] = useState(null);
  const [earlyHours, setEarlyHours] = useState(null);
  const [earlyMinutes, setEarlyMinutes] = useState(null);
  const [lateDays, setLateDays] = useState(null);
  const [lateHours, setLateHours] = useState(null);
  const [lateMinutes, setLateMinutes] = useState(null);

  // Summarize day, hour, minute values to set earlierThan in minutes
  useEffect(() => {
    const minInMinutes = earlyDays + earlyHours + earlyMinutes;
    lateAndEarlyModel.setValue({ min: minInMinutes, max: lateAndEarlyModel.laterThan });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earlyDays, earlyHours, earlyMinutes]);

  // Summarize day, hour, minute values to set laterThan in minutes
  useEffect(() => {
    const maxInMinutes = lateDays + lateHours + lateMinutes;
    lateAndEarlyModel.setValue({ min: lateAndEarlyModel.earlierThan, max: maxInMinutes });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lateDays, lateHours, lateMinutes]);

  // Update dropdown selection tag to same as earlierThan value
  useEffect(() => {
    const early = lateAndEarlyModel.earlierThan;
    const { days, hours, minutes } = lateAndEarlyModel.daysHoursMinutes(early);
    dayModelEarly.setValue(days);
    hourModelEarly.setValue(hours);
    minuteModelEarly.setValue(minutes);
    if (early !== 0 && early !== undefined) {
      lateAndEarlyModel.setEarlyCheckbox(true);
    } else {
      lateAndEarlyModel.setEarlyCheckbox(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lateAndEarlyModel.earlierThan]);

  // Update dropdown selection tag to same as laterThan value
  useEffect(() => {
    const late = lateAndEarlyModel.laterThan;
    const { days, hours, minutes } = lateAndEarlyModel.daysHoursMinutes(late);
    dayModelLate.setValue(days);
    hourModelLate.setValue(hours);
    minuteModelLate.setValue(minutes);
    if (late !== 0 && late !== undefined) {
      lateAndEarlyModel.setLateCheckbox(true);
    } else {
      lateAndEarlyModel.setLateCheckbox(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lateAndEarlyModel.laterThan]);

  const selectStyle = {
    minWidth: '100px',
  };

  return (
    <Grid columns="1fr 1fr" fullWidth {...props}>
      <Flex
        column="true"
        centeredVertically
      >
        <Flex
          row="true"
          centeredVertically
        >
          <Text
            captionSmall
            bold
            margin={{ bottom: 's', right: 's' }}
            color="neutral100"
            style={{ msGridRow: 1, msGridColumn: 1 }}
          >
            Early
          </Text>

          <Div margin={{ bottom: 'xs' }} style={{ msGridRow: 1, msGridColumn: 1 }}>
            <Checkbox
              checked={lateAndEarlyModel.useEarlyModel}
              id="early"
              onChange={() => {
                lateAndEarlyModel.toggleEarlyCheckbox();
                if (!lateAndEarlyModel.useEarlyModel) {
                  setEarlyDays(0);
                  setEarlyHours(0);
                  setEarlyMinutes(0);
                }
              }}
            />
          </Div>
        </Flex>
        <Text
          margin={{ bottom: 's', right: 's' }}
          color="neutral100"
          style={{ msGridRow: 1, msGridColumn: 1 }}
        >
          More than:
        </Text>
        <Flex
          row="true"
          centeredVertically
          fullWidth
        >
          <Div
            style={selectStyle}
          >
            <Select
              isDisabled={!lateAndEarlyModel.useEarlyModel}
              options={dayModelEarly.options}
              value={dayModelEarly.value}
              isSearchable
              isClearable
              placeholder="0"
              onChange={(value) => {
                dayModelEarly.setValue(value);
                if (value === null) {
                  setEarlyDays(0);
                } else {
                  const daysToMinutes = value.value * 1440;
                  setEarlyDays(daysToMinutes === 0 ? daysToMinutes : -daysToMinutes);
                }
              }}
            />
          </Div>
          <Text
            margin={{
              bottom: 's', right: 's', left: 'xs', top: 'xs',
            }}
            color="neutral100"
            bold
          >
            Days
          </Text>
          <Div
            style={selectStyle}
          >
            <Select
              isDisabled={!lateAndEarlyModel.useEarlyModel}
              options={hourModelEarly.options}
              value={hourModelEarly.value}
              isSearchable
              isClearable
              placeholder="0"
              onChange={(value) => {
                hourModelEarly.setValue(value);
                if (value === null) {
                  setEarlyHours(0);
                } else {
                  const hoursToMinutes = value.value * 60;
                  setEarlyHours(hoursToMinutes === 0 ? hoursToMinutes : -hoursToMinutes);
                }
              }}
            />
          </Div>
          <Text
            margin={{
              bottom: 's', right: 's', left: 'xs', top: 'xs',
            }}
            color="neutral100"
            bold
          >
            Hours
          </Text>
          <Div
            style={selectStyle}
          >
            <Select
              isDisabled={!lateAndEarlyModel.useEarlyModel}
              options={minuteModelEarly.options}
              value={minuteModelEarly.value}
              isSearchable
              isClearable
              placeholder="0"
              onChange={(value) => {
                minuteModelEarly.setValue(value);
                if (value === null) {
                  setEarlyMinutes(0);
                } else {
                  const minutes = value.value;
                  setEarlyMinutes(minutes === 0 ? minutes : -minutes);
                }
              }}
            />
          </Div>
          <Text
            margin={{
              bottom: 's', right: 's', left: 'xs', top: 'xs',
            }}
            color="neutral100"
            bold
          >
            Mins
          </Text>
        </Flex>
      </Flex>
      <Flex
        column="true"
        centeredVertically
      >
        <Flex
          row="true"
          centeredVertically
        >
          <Text
            captionSmall
            bold
            margin={{ bottom: 's', right: 's' }}
            color="neutral100"
            style={{ msGridRow: 1, msGridColumn: 1 }}
          >
            Late
          </Text>

          <Div margin={{ bottom: 'xs' }} style={{ msGridRow: 1, msGridColumn: 1 }}>
            <Checkbox
              checked={lateAndEarlyModel.useLateModel}
              id="late"
              onChange={() => {
                lateAndEarlyModel.toggleLateCheckbox();
                if (!lateAndEarlyModel.useLateModel) {
                  setLateDays(0);
                  setLateHours(0);
                  setLateMinutes(0);
                }
              }}
            />
          </Div>
        </Flex>
        <Text
          margin={{ bottom: 's', right: 's' }}
          color="neutral100"
          style={{ msGridRow: 1, msGridColumn: 1 }}
        >
          More than:
        </Text>
        <Flex
          row="true"
          centeredVertically
        >
          <Div
            style={selectStyle}
          >
            <Select
              isDisabled={!lateAndEarlyModel.useLateModel}
              options={dayModelLate.options}
              value={dayModelLate.value}
              isSearchable
              isClearable
              placeholder="0"
              onChange={(value) => {
                dayModelLate.setValue(value);
                if (value === null) {
                  setLateDays(0);
                } else {
                  const daysToMinutes = value.value * 1440;
                  setLateDays(daysToMinutes);
                }
              }}
            />
          </Div>
          <Text
            margin={{
              bottom: 's', right: 's', left: 'xs', top: 'xs',
            }}
            color="neutral100"
            bold
          >
            Days
          </Text>
          <Div
            style={selectStyle}
          >
            <Select
              isDisabled={!lateAndEarlyModel.useLateModel}
              options={hourModelLate.options}
              value={hourModelLate.value}
              isSearchable
              isClearable
              placeholder="0"
              onChange={(value) => {
                hourModelLate.setValue(value);
                if (value === null) {
                  setLateHours(0);
                } else {
                  const hoursToMinutes = value.value * 60;
                  setLateHours(hoursToMinutes);
                }
              }}
            />
          </Div>
          <Text
            margin={{
              bottom: 's', right: 's', left: 'xs', top: 'xs',
            }}
            color="neutral100"
            bold
          >
            Hours
          </Text>
          <Div
            style={selectStyle}
          >
            <Select
              isDisabled={!lateAndEarlyModel.useLateModel}
              options={minuteModelLate.options}
              value={minuteModelLate.value}
              isSearchable
              isClearable
              placeholder="0"
              onChange={(value) => {
                minuteModelLate.setValue(value);
                if (value === null) {
                  setLateMinutes(0);
                } else {
                  const minutes = value.value;
                  setLateMinutes(minutes);
                }
              }}
            />
          </Div>
          <Text
            margin={{
              bottom: 's', right: 's', left: 'xs', top: 'xs',
            }}
            color="neutral100"
            bold
          >
            Mins
          </Text>
        </Flex>
      </Flex>
    </Grid>
  );
}

export default observer(EarlyAndLateFilters);
