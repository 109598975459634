import React, { useCallback, useState } from 'react';
import { Icon, Button, Tag } from '@storaensods/seeds-react';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import Flex from '../shared/components/Flex/Flex';
import Div from '../shared/components/Div/Div';
import Element from '../shared/components/Element/Element';
import Heading from '../shared/components/typography/Heading';
import styles from './Filters.module.scss';
import TimeRangeFilters from './TimeRangeFilters/TimeRangeFilters';
import OriginDestinationFilters from './OriginDestinationFilters/OriginDestinationFilters';
import CarrierCustomerFilters from './CarrierCustomerFilters/CarrierCustomerFilters';
import TrackingFilter from './TrackingFilter/TrackingFilter';
import OrderNumberFilter from './OrderNumberFilter/OrderNumberFilter';
import EventFilters from './EventFilters/EventFilters';
import EarlyAndLateFilters from './EarlyAndLateFilters/EarlyAndLateFilters';
import SavedFiltersSelect from './SavedFiltersSelect/SavedFiltersSelect';
import MainFilters from './MainFilters/MainFilters';

function Filters({
  model, noSavingControls, ...props
}) {
  const {
    filterOptionsLoading,
    eventFiltersModel,
    earlyAndLateFiltersModel,
    timeRangeFiltersModel,
    originDestinationFiltersModel,
    carrierCustomerFiltersModel,
    trackingFilterModel,
    orderNumberFilterModel,
    divisionFilterModel,
    savedFiltersSelectModel,
    allFilterTags,
  } = model;

  const [filterVisible, setFilterVisible] = useState(false);

  const toggleFilterView = useCallback(() => {
    setFilterVisible(!filterVisible);
  }, [setFilterVisible, filterVisible]);

  return (
    <Flex
      column="true"
      inline
      alignLeft
      padding={{ size: noSavingControls ? null : 'l' }}
      backgroundColor={noSavingControls ? null : 'neutral20'}
      // eslint-disable-next-line max-len
      className={classnames([styles.accordianContainer, styles.filters], { [styles.open]: filterVisible })}
      data-testid="filter-box"
      {...props}
    >
      <MainFilters
        trackingFilterModel={trackingFilterModel}
        divisionFilterModel={divisionFilterModel}
        filterOptionsLoading={filterOptionsLoading}
        margin={{ bottom: 'l' }}
      />
      <Flex
        spaceBetween
        fullWidth
        centeredVertically
        className={styles.accordianHeader}
      >
        <Heading
          tagName="h6"
          caption
          bold
          color="neutral100"
          className={styles.accordianText}
        >
          <Icon
            onClick={toggleFilterView}
            className={styles.accordianButton}
            style={{ marginRight: 7 }}
          >
            keyboard_arrow_down
          </Icon>
          <Element
            onClick={toggleFilterView}
          >
            Advanced transport filters
          </Element>
          {!noSavingControls && (
            <Icon
              onClick={() => {
                const {
                  protocol, hostname, port,
                } = window.location;
                copy(`${protocol}//${hostname}${port ? `:${port}` : ''}?filters=${model.getFiltersForUrl()}`);
                toast('Link copied in your clipboard');
              }}
              className={styles.shareButton}
              style={{ marginLeft: 7 }}
            >
              share
            </Icon>
          )}
        </Heading>

        {!noSavingControls && (
          <SavedFiltersSelect
            model={savedFiltersSelectModel}
            filterModel={model}
          />
        )}
      </Flex>

      <Div data-testid="applied-filter-tags-container">
        {
          // !!allFilterTags.length &&
          !filterVisible
          && (
            <Div
              padding={{ top: 's', bottom: 's' }}
              style={{ maxWidth: 830 }}
            >
              {allFilterTags.map(({ label, key, remove }) => (
                <Tag
                  data-testid="applied-filter-tag"
                  key={key}
                  style={{ margin: 3 }}
                  onClose={() => { remove(); }}
                >
                  {label}
                </Tag>
              ))}
            </Div>
          )
        }
      </Div>

      <Div
        className={styles.accordianContent}
      >
        <Div padding={{ top: 'l', bottom: 'l' }}>

          <TimeRangeFilters
            model={timeRangeFiltersModel}
            margin={{ bottom: 'l' }}
          />

          <EarlyAndLateFilters model={earlyAndLateFiltersModel} margin={{ bottom: 'l' }} />

          <Flex
            row="true"
            spaceBetween
            fullWidth
          >
            <EventFilters model={eventFiltersModel} margin={{ bottom: 'l' }} />

            <TrackingFilter
              trackingFilterModel={trackingFilterModel}
              filterOptionsLoading={filterOptionsLoading}
              margin={{ bottom: 'l' }}
            />
          </Flex>

          <OriginDestinationFilters
            model={originDestinationFiltersModel}
            filterOptionsLoading={filterOptionsLoading}
            margin={{ bottom: 'l' }}
          />

          <CarrierCustomerFilters
            model={carrierCustomerFiltersModel}
            filterOptionsLoading={filterOptionsLoading}
            margin={{ bottom: 'l' }}
          />

          <OrderNumberFilter
            orderNumberFilterModel={orderNumberFilterModel}
            filterOptionsLoading={filterOptionsLoading}
            margin={{ bottom: 'l' }}
          />

        </Div>
      </Div>

      {!noSavingControls && (
        <Flex className={styles.accordianFooter} margin={{ top: 's' }}>
          <Button onClick={model.resetFilterValues} flat icon="sync" style={{ padding: 0 }}>
            Reset filters
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

export default observer(Filters);
