import callBackend from '../shared/methods/callBackend/callBackend';

export type ExcludedTracking = {
  originId: string,
  destinationId: string,
  carrierId: string,
};

const postExcludedLocation = async (body: ExcludedTracking) => callBackend({
  url: `${process.env.REACT_APP_API_URL}/trackingexcluded`,
  method: 'POST',
  body,
});

export default postExcludedLocation;
