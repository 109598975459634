/* eslint-disable max-len */
import { action, observable } from 'mobx';
import moment from 'moment/moment';
import TransportStage from './TransportStage';
import getScheduleStatus from './getScheduleStatus';
import getDateDiff from '../../shared/methods/getDateDiff/getDateDiff';
import patchTransport, { ETransportPatchOP } from './patchTransport';
import TrackingStatusRequestModel from './TrackingStatusRequestModel';

export default class TransportModel {
  @observable arrivalActual;

  @observable arrivalEstimated;

  @observable arrivalRequestedTo;

  @observable arrivalRequestedToVsActual;

  @observable arrivalRequestedToVsEstimated;

  @observable carrier;

  @observable comments;

  @observable departureActual;

  @observable dropoffLocations;

  @observable loadingPlannedTo;

  @observable loadingPlannedToVsActual;

  @observable destination;

  @observable hasArrived;

  @observable hasDeparted;

  @observable incoterms;

  @observable incotermsLocation;

  @observable loadPlanStatus;

  @observable milestones;

  @observable mill;

  @observable numberOfPackages;

  @observable origin;

  @observable finalDestination;

  @observable pickupLocations;

  @observable scheduleStatus;

  @observable stage;

  @observable statusMessages;

  @observable transportId;

  @observable transportModeCode;

  @observable transportModeType;

  @observable transportPlanner;

  @observable vehicle;

  @observable waybills;

  @observable isPinned;

  @observable bookingConfirmation;

  @observable loading = false;

  @observable division;

  filterEventsBySource;

  uniqueTrackerNames;

  transportErrors = [] as Array<string>;

  trackingStatusRequestModel = new TrackingStatusRequestModel();

  constructor(values) {
    if (values) this.setValues(values);
    this.trackingStatusRequestModel.setTransport(this);
  }

  @action
  async setVehicleLicensePlateNumber(value) {
    this.vehicle = {
      ...this.vehicle,
      licensePlateNumber: value,
    };

    this.loading = true;

    await patchTransport(this.transportId, [
      {
        op: ETransportPatchOP.REPLACE,
        path: '/vehicle',
        value: this.vehicle,
      },
    ]);

    this.loading = false;
  }

  @action
  setStatusMessagesAndMilestones(trackerNameAndId) {
    this.statusMessages.forEach((statusMessage) => {
      // eslint-disable-next-line no-param-reassign
      statusMessage.showInUI = trackerNameAndId === null || (statusMessage.eventCode !== 'ETA' && statusMessage.eventCode !== 'Arrived')
        || (statusMessage.trackerName === trackerNameAndId.trackerName && statusMessage.trackerId === trackerNameAndId.trackerId);
    });

    this.statusMessages = this.statusMessages.slice().sort((eventA, eventB) => (moment(eventA.creationTime).isBefore(eventB.creationTime)
      ? -1
      : 1));

    // Filter milestones for easy access. Take into account all messages that have milestone set
    // (Can be ETA or Arrived or Loaded too)
    this.milestones = this.statusMessages
      .filter((message) => message.showInUI && message.milestone && message.milestone.latitude && message.milestone.longitude)
      .map((milestoneMessage) => ({
        ...milestoneMessage.milestone,
        eventCode: milestoneMessage.eventCode,
      }));
  }

  setValues(values) {
    Object.assign(this, values);
    this.arrivalRequestedTo = this.finalDestination?.arrivalRequestedTo || this.arrivalRequestedTo;
    this.arrivalActual = this.finalDestination?.arrivalActual || this.arrivalActual;
    this.arrivalEstimated = this.finalDestination?.arrivalEstimated || this.arrivalEstimated;

    if (this.statusMessages) {
      // Set requested vs estimated arrival and arrival status for each milestone
      this.statusMessages = this.statusMessages.map((statusMessage) => {
        if (statusMessage.milestone) {
          const arrivalRequestedVsEstimated = getDateDiff(
            this.arrivalRequestedTo,
            statusMessage.milestone.eta,
          );

          Object.assign(statusMessage.milestone, {
            arrivalRequestedVsEstimated,
            scheduleStatus: getScheduleStatus(arrivalRequestedVsEstimated),
          });
        }
        return statusMessage;
      });

      // Sort events, no tracker name at this point (= show all)
      this.setStatusMessagesAndMilestones(null);

      const trackerNameAndIdConcatenated: Array<any> = this.statusMessages.filter((message) => message.trackerName).map((message) => `${message.trackerName} - ${message.trackerId}`);

      const uniqueTrackerNameAndIdCombos = new Set(trackerNameAndIdConcatenated);

      this.uniqueTrackerNames = Array.from(uniqueTrackerNameAndIdCombos).map((trackerNameAndId) => ({
        trackerName: trackerNameAndId.split(' - ')[0],
        trackerId: trackerNameAndId.split(' - ')[1],
      }));
    }

    this.filterEventsBySource = (trackerNameAndId) => {
      this.setStatusMessagesAndMilestones(trackerNameAndId);
    };

    // Set arrivalEstimated from last milestone if not defined otherwise
    this.arrivalEstimated = !this.arrivalEstimated && this.milestones && this.milestones.length
      ? this.milestones[this.milestones.length - 1].eta
      : this.arrivalEstimated;

    // Set planned vs actual departure time
    this.loadingPlannedToVsActual = this.departureActual
      ? getDateDiff(this.loadingPlannedTo, this.departureActual)
      : null;

    // Set requested vs estimated arrival time and arrival status
    this.arrivalRequestedToVsEstimated = this.arrivalEstimated
      ? getDateDiff(this.arrivalRequestedTo, this.arrivalEstimated)
      : null;
    this.scheduleStatus = this.arrivalEstimated
      ? getScheduleStatus(this.arrivalRequestedToVsEstimated)
      : null;

    // Set requested vs actual arrival time and arrival status
    this.arrivalRequestedToVsActual = this.arrivalActual
      ? getDateDiff(this.arrivalRequestedTo, this.arrivalActual)
      : null;
    this.scheduleStatus = this.arrivalActual
      ? getScheduleStatus(this.arrivalRequestedToVsActual)
      : this.scheduleStatus;

    this.hasDeparted = this.stage === TransportStage.IN_TRANSIT
      || this.stage === TransportStage.ARRIVED;
    this.hasArrived = this.stage === TransportStage.ARRIVED;
  }

  @action
  updateDestinationCoordinates(latitude, longitude) {
    this.destination = {
      ...this.destination,
      coordinates: {
        latitude,
        longitude,
      },
    };
  }
}
