import { observer } from 'mobx-react';
import React from 'react';
import assignAll from 'lodash/fp/assignAll';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isString from 'lodash/fp/isString';
import map from 'lodash/fp/map';
import getModel from './getModel';

const toPropsWithValue = ({ model, props }) => flow(
  map(
    (prop) => (isString(prop)
      ? { [prop]: get(prop, model) }
      : { [prop.name]: get(prop.modelPath, model) }),
  ),
  assignAll,
)(props);

const toModelInstanceAndProps = ({ Model, props }) => ({
  model: getModel(Model),
  props,
});

const withModel = (...modelClassesAndProps) => {
  const modelInstancesAndProps = map(
    toModelInstanceAndProps,
    modelClassesAndProps,
  );

  const decorator = (ToBeDecorated) => observer(({ ...props }) => {
    const modelProps = flow(
      map(toPropsWithValue),
      assignAll,
    )(modelInstancesAndProps);

    return <ToBeDecorated {...modelProps} {...props} />;
  });

  decorator.__decoratorParameters = { modelClassesAndProps };

  return decorator;
};

export default withModel;
