import callBackend from '../shared/methods/callBackend/callBackend';

export type location = {
  id: Array<string>,
  latitude: Array<string>,
  longitude: Array<string>,
  geofenceRadius: Array<string>,
  updatedBy: Array<string>,
};

const getLocations = async (filters?: location) => {
  let url = `${process.env.REACT_APP_API_URL}/transport/locations`;
  if (filters) { url += `&filters=${encodeURIComponent(JSON.stringify(filters))}`; }
  return callBackend({
    url,
    method: 'GET',
  });
};

export default getLocations;
