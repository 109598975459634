import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Icon, Spinner } from '@storaensods/seeds-react';
import Heading from '../../shared/components/typography/Heading';
import Flex from '../../shared/components/Flex/Flex';
import Div from '../../shared/components/Div/Div';
import { useDrawer } from '../../shared/components/QuickAccessBar/QuickAccessBar';

import styles from './Page.module.scss';

function Page({
  title = null, children, hideBackButton = false, icon = null, loading = false,
}) {
  const { close } = useDrawer();

  useEffect(() => {
    close();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div className={styles.pageContainer}>
      <Div padding={{ right: 'xl' }} style={{ paddingLeft: 24 }}>
        <Flex centeredVertically>
          {!hideBackButton && (
            <Link to="/">
              <Flex centered margin={{ right: 's' }} className={styles.pageIcon}>
                <Icon size="lg">
                  {icon || 'arrow_back'}
                </Icon>
              </Flex>
            </Link>
          )}
          {/* <Icon size="lg">keyboard_arrow_right</Icon> */}
          {title && (
            <Heading margin={{ vertical: 'l' }}>
              {title}
            </Heading>
          )}
          {loading && (
            <Flex centered margin={{ right: 's' }} className={styles.pageIcon}>
              <Spinner />
            </Flex>
          )}
        </Flex>
        {children}
      </Div>
    </Div>
  );
}

export default observer(Page);
