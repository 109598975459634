enum TrackingStatusEnum {
  NO_TRACKING = 'NO_TRACKING',
  TRACKING_REQUESTED = 'TRACKING_REQUESTED',
  TRACKING_ONGOING = 'TRACKING_ONGOING',
  TRACKING_ERROR = 'TRACKING_ERROR',
  TRACKING_COMPLETED = 'TRACKING_COMPLETED',
  TRACKING_CANCELLED = 'TRACKING_CANCELLED',
  TRACKING_FAILED = 'TRACKING_FAILED'
}

export default TrackingStatusEnum;
