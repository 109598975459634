import React from 'react';
import Div from '../../../shared/components/Div/Div';
import Flex from '../../../shared/components/Flex/Flex';
import Text from '../../../shared/components/typography/Text';

function LabelAndValue({ label, children, margin = { bottom: 'm' } }) {
  return (
    <Flex column margin={margin}>
      <Text
        bold
        captionSmall
        margin={{ bottom: 'xxxs' }}
        style={{ fontSize: '12px' }}
        color="neutral100"
      >
        {label}
      </Text>
      <Text>{children}</Text>
    </Flex>
  );
}

function ContainerDetails({ transportModel, containerModel, ...props }) {
  const {
    transportId,
    mill,
    bookingConfirmation,
  } = transportModel;

  const {
    containerId,
    loadedWeight,
    orders,
    carrierId,
  } = containerModel;

  return (
    <Div {...props}>
      <LabelAndValue label="Booking reference">{transportId}</LabelAndValue>

      <LabelAndValue label="Carrier reference">{bookingConfirmation.carrierBookingReference}</LabelAndValue>

      <LabelAndValue label="Container Id">{containerId}</LabelAndValue>

      {carrierId && (
        <LabelAndValue label="Carrier">
          <span>
            {carrierId}
            <br />
          </span>
        </LabelAndValue>
      )}

      {mill && mill.nameAddress instanceof Array && (
        <LabelAndValue label="Mill">
          <span>
            {mill.id}
            <br />
          </span>
        </LabelAndValue>
      )}

      {orders instanceof Array && (
        <LabelAndValue label="Mill Order / Line / Packages">
          {orders.map((order) => (
            <span key={`order-${order.number}-line-${order.lineItemNumber}`}>
              {`${order.number} / ${order.lineItemNumber} / ${
                order.numberOfPackages
              }`}
              <br />
            </span>
          ))}
        </LabelAndValue>
      )}

      {loadedWeight && (
        <LabelAndValue label="Loaded Weight">
          {`${loadedWeight.gross} Kg (Gross)`}
        </LabelAndValue>
      )}
    </Div>
  );
}

export default ContainerDetails;
