import callBackend from '../../shared/methods/callBackend/callBackend';

const setTransportPinStatus = async (transportID, shouldPin) => {
  let url = `${process.env.REACT_APP_API_URL}/transport/`;
  url += transportID;

  return callBackend({
    url,
    method: 'PATCH',
    body: {
      shouldPin,
    },
  });
};

export default setTransportPinStatus;
