import React from 'react';
import moment from 'moment/moment';
import Text from '../shared/components/typography/Text';
import TransportScheduleStatus from '../TransportPopup/Transport/TransportScheduleStatus';
import getScheduleStatus from '../TransportPopup/Transport/getScheduleStatus';
import styles from './TransportCaption.module.scss';
import { DEFAULT_DATE_ONLY_FORMAT } from '../Filters/TimeRangeFilters/TimeRangeFormat';

const formatDate = (date, format) => (date ? moment(date).format(format) : 'N/A');

const formatDiff = (diff) => {
  const isOnTime = getScheduleStatus(diff) === TransportScheduleStatus.ON_TIME;

  if (isOnTime) return 'On Time';

  const hours = Math.abs(diff) / 1000 / 60 / 60;
  const minutes = Math.abs(diff) / 1000 / 60;
  let phrase = diff < 0 ? 'Early ' : 'Late ';

  if (hours > 1) {
    phrase
      += `${Math.floor(hours)
      } h ${
        Math.floor(minutes - 60 * Math.floor(hours))
      } min`;
  } else {
    phrase += `${Math.floor(minutes)} min`;
  }

  return phrase;
};

function TransportCaption({
  bold,
  color,
  strong,
  date,
  time,
  diff,
  text,
  prefix,
  ...props
}) {
  const isOnTime = getScheduleStatus(diff) === TransportScheduleStatus.ON_TIME;

  const currentColor = color || (isOnTime ? 'green100' : 'red75');

  let marginLeft = 8;
  let label = text;
  if (date) {
    label = formatDate(date, DEFAULT_DATE_ONLY_FORMAT);
    marginLeft = 8;
  } else if (time) {
    const isZero = moment.utc(time).format('HH:mm') === '00:00';
    if (!isZero) {
      label = formatDate(time, 'HH:mm');
      marginLeft = 8;
    }
  } else if (diff) {
    label = formatDiff(diff);
    marginLeft = 8;
  }

  return (
    <Text
      className={styles.transportCaption}
      bold={bold}
      captionSmall
      backgroundColor={strong ? currentColor : undefined}
      color={strong ? 'neutral0' : currentColor}
      padding={{ horizontal: strong ? 'xxs' : 'zero', vertical: 'xxxs' }}
      style={{ borderRadius: strong ? '4px' : undefined, marginLeft }}
      {...props}
    >
      {label && prefix ? prefix + label : label}
    </Text>
  );
}

export default TransportCaption;
