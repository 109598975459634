import callBackend from '../shared/methods/callBackend/callBackend';

export type location = {
  id: string,
  latitude: string,
  longitude: string,
  geofenceRadius: string,
  updatedBy: string,
};

const putLocation = async (locationId, body: location) => callBackend({
  url: `${process.env.REACT_APP_API_URL}/transport/location/${locationId}`,
  method: 'PUT',
  body,
});

export default putLocation;
