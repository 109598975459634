import React from 'react';
import { observer } from 'mobx-react';
import FilterForm from '../FiltersAndAlerts/FilterForm';
import Page from './Page/Page';
import FiltersModel from '../Filters/FiltersModel';
import FilterFormModel from '../FiltersAndAlerts/FilterFormModel';

function FiltersAndAlertsPage() {
  const model = new FilterFormModel();
  const filtersModel = new FiltersModel();

  return (
    <Page title="Filter">
      <FilterForm
        model={model}
        filtersModel={filtersModel}
      />
    </Page>
  );
}

export default observer(FiltersAndAlertsPage);
