const EventCodeEnum = {
  PLANNED: 'PLANNED',
  IN_TRANSIT_PICK_UP: 'IN_TRANSIT_PICK_UP',
  ARRIVED_PICK_UP: 'ARRIVED_PICK_UP',
  IN_TRANSIT_DROP_OFF: 'IN_TRANSIT_DROP_OFF',
  ARRIVED_DROP_OFF: 'ARRIVED_DROP_OFF',
  DEPARTED_DROP_OFF: 'DEPARTED_DROP_OFF',
  CANCELLED: 'CANCELLED',
  TRACKING_FAILED: 'TRACKING_FAILED',
};

export default EventCodeEnum;

export enum EventCodeEnumOrdered {
  PLANNED = 0,
  IN_TRANSIT_PICK_UP = 1,
  ARRIVED_PICK_UP = 2,
  IN_TRANSIT_DROP_OFF = 3,
  ARRIVED_DROP_OFF = 4,
  DEPARTED_DROP_OFF = 5,
}
