import React from 'react';
import Element from '../Element/Element';

function A({
  color = 'blue100', style, children, ...props
}) {
  return (
    <Element
      color={color}
      style={{ textDecoration: 'none', ...style }}
      tagName="a"
      {...props}
    >
      {children}
    </Element>
  );
}

export default A;
