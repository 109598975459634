import React from 'react';
import Text from '../../shared/components/typography/Text';
import Grid from '../../shared/components/Grid/Grid';
import Select from '../../shared/components/Select/Select';
import Div from '../../shared/components/Div/Div';

function OriginDestinationFilters({ model, filterOptionsLoading, ...props }) {
  const {
    originCountryModel,
    originLocationModel,
    destinationCountryModel,
    destinationLocationModel,
  } = model;

  return (
    <Grid columns="1fr 1fr" fullWidth {...props}>
      <Text
        captionSmall
        bold
        margin={{ bottom: 's' }}
        color="neutral100"
        style={{ msGridRow: 1, msGridColumn: 1 }}
      >
        Pick up location ID
      </Text>

      <Text
        captionSmall
        bold
        color="neutral100"
        margin={{ left: 'xs', bottom: 's' }}
        style={{ msGridRow: 1, msGridColumn: 2 }}
      >
        Drop off location ID
      </Text>

      <Div margin={{ bottom: 'xs' }} style={{ msGridRow: 2, msGridColumn: 1 }}>
        <Select
          isMulti
          options={originCountryModel.options}
          value={originCountryModel.value}
          isSearchable
          isClearable
          isLoading={filterOptionsLoading}
          isDisabled={filterOptionsLoading}
          placeholder="Select Country..."
          onChange={(option) => originCountryModel.setValue(option)}
        />
      </Div>

      <Div
        margin={{ left: 'xs', bottom: 'xs' }}
        style={{ msGridRow: 2, msGridColumn: 2 }}
      >
        <Select
          isMulti
          options={destinationCountryModel.options}
          value={destinationCountryModel.value}
          isSearchable
          isClearable
          isLoading={filterOptionsLoading}
          isDisabled={filterOptionsLoading}
          placeholder="Select Country..."
          onChange={(option) => destinationCountryModel.setValue(option)}
        />
      </Div>

      <Div style={{ msGridRow: 3, msGridColumn: 1 }}>
        <Select
          isMulti
          options={originLocationModel.options}
          value={originLocationModel.value}
          isSearchable
          isClearable
          isLoading={filterOptionsLoading}
          isDisabled={filterOptionsLoading}
          placeholder="Select Location..."
          onChange={(option) => originLocationModel.setValue(option)}
        />
      </Div>

      <Div margin={{ left: 'xs' }} style={{ msGridRow: 3, msGridColumn: 2 }}>
        <Select
          isMulti
          options={destinationLocationModel.options}
          value={destinationLocationModel.value}
          isSearchable
          isClearable
          isLoading={filterOptionsLoading}
          isDisabled={filterOptionsLoading}
          placeholder="Select Location..."
          onChange={(option) => destinationLocationModel.setValue(option)}
        />
      </Div>
    </Grid>
  );
}

export default OriginDestinationFilters;
