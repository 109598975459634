import { observable, action, computed } from 'mobx';

export default class LateAndEarlyModel {
  @observable earlierThan = 0; // in minutes

  @observable laterThan = 0; // in minutes

  @observable useEarlyModel = false;

  @observable useLateModel = false;

  @action
  toggleEarlyCheckbox() {
    this.useEarlyModel = !this.useEarlyModel;
    if ((this.earlierThan === 0 || this.earlierThan === undefined) && this.useEarlyModel === true) {
      this.setValue({ min: -720, max: this.laterThan });
    } else if (this.earlierThan !== 0 && this.useEarlyModel === false) {
      this.setValue({ min: 0, max: this.laterThan });
    }
  }

  @action
  toggleLateCheckbox() {
    this.useLateModel = !this.useLateModel;
    if ((this.laterThan === 0 || this.laterThan === undefined) && this.useLateModel === true) {
      this.setValue({ min: this.earlierThan, max: 1 });
    } else if (this.laterThan !== 0 && this.useLateModel === false) {
      this.setValue({ min: this.earlierThan, max: 0 });
    }
  }

  @action
  setEarlyCheckbox(trueOrFalse) {
    this.useEarlyModel = trueOrFalse;
  }

  @action
  setLateCheckbox(trueOrFalse) {
    this.useLateModel = trueOrFalse;
  }

  @action
  setValue({ min, max }) {
    this.earlierThan = min <= 0 ? min : 0;
    this.laterThan = max >= 0 ? max : 0;
  }

  @action
  initValue({ earlierThan, laterThan }) {
    this.earlierThan = earlierThan;
    this.laterThan = laterThan;
  }

  getValue() {
    const returnedValue = {} as any;
    if (this.earlierThan !== 0) {
      returnedValue.earlierThan = this.earlierThan;
    }
    if (this.laterThan !== 0) {
      returnedValue.laterThan = this.laterThan;
    }
    return returnedValue;
  }

  @computed
  get value() {
    return {
      earlierThan: this.earlierThan,
      laterThan: this.laterThan,
    };
  }

  @computed
  get minMaxValue() {
    return {
      min: this.earlierThan,
      max: this.laterThan,
    };
  }

  @action
  daysHoursMinutes(earlyOrLate) {
    let inMinutes = Math.abs(earlyOrLate);
    let days = 0;
    let hours = 0;
    let minutes = 0;
    const fullEarlyDays = 1440;
    const fullEarlyHours = 60;
    if (inMinutes >= fullEarlyDays) {
      days = Math.floor(inMinutes / fullEarlyDays);
      inMinutes -= (days * fullEarlyDays);
    }
    if (inMinutes >= fullEarlyHours) {
      hours = Math.floor(inMinutes / fullEarlyHours);
      inMinutes -= (hours * fullEarlyHours);
    }
    if (inMinutes >= minutes) {
      minutes = inMinutes;
    }
    return {
      days,
      hours,
      minutes,
    };
  }

  getEarlierThanLabelandValue() {
    const { days, hours, minutes } = this.daysHoursMinutes(this.earlierThan);
    return this.earlierThan ? [{
      label: `Earlier than: ${days}D 
      ${hours}H ${minutes}M`,
    }] : [];
  }

  getLaterThanLabelandValue() {
    const { days, hours, minutes } = this.daysHoursMinutes(this.laterThan);
    return this.laterThan ? [{
      label: `Later than: ${days}D
      ${hours}H ${minutes}M`,
    }] : [];
  }
}
