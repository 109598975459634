import PropTypes from 'prop-types';
import React from 'react';
import Element from '../Element/Element';
import styles from './Text.module.scss';

function Text({
  small = false,
  caption = false,
  captionSmall = false,
  centered = false,
  className = undefined,
  children,
  // singleLine,
  tagName = 'p',
  ...props
}) {
  const classNames = [
    styles.text,
    {
      [styles.text__small]: small,
      [styles.text__centered]: centered,
      [styles.text__caption]: caption,
      [styles.text__captionSmall]: captionSmall,
      // [styles.text__singleLine]: !!singleLine
    },
    className,
  ];

  return (
    <Element tagName={tagName} className={classNames} {...props}>
      {children}
    </Element>
  );
}

const supportedTagNames = ['p', 'span', 'div'];

Text.propTypes = {
  tagName: PropTypes.oneOf(supportedTagNames),
};

Text.defaultProps = {
  tagName: 'span',
};

export default Text;
