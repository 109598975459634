import React from 'react';
import {
  Icon, Select,
} from '@storaensods/seeds-react';
import { components } from 'react-select';

import styles from './FilterForm.module.scss';

const iconOptions = [
  {
    label: 'Bank',
    value: 'account_balance',
  },
  {
    label: 'Mountain',
    value: 'filter_hdr',
  },
  {
    label: 'Favorite',
    value: 'favorite',
  },
  {
    label: 'Vintage',
    value: 'Flter_vintage',
  },
  {
    label: 'Star',
    value: 'star',
  },
  {
    label: 'Healing',
    value: 'healing',
  },
  {
    label: 'Breakfast',
    value: 'free_breakfast',
  },
  {
    label: 'Adb',
    value: 'adb',
  },
  {
    label: 'Business',
    value: 'business_center',
  },
  {
    label: 'Beach',
    value: 'beach_access',
  },
  {
    label: 'AC Unit',
    value: 'ac_unit',
  },
  {
    label: 'Toy',
    value: 'toys',
  },
  {
    label: 'School',
    value: 'school',
  },
  {
    label: 'Cake',
    value: 'cake',
  },
];

const IconOptionItem = ({ children, value, innerProps }) => (
  <div {...innerProps} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Icon
      className={styles.optionIcon}
    >
      {value}
    </Icon>
    {children}
  </div>
);

const IconSingleValue = ({
  children, data, innerProps, ...props
}) => (
  <components.SingleValue {...props}>
    <IconOptionItem innerProps={innerProps} value={data.value}>
      {children}
    </IconOptionItem>
  </components.SingleValue>
);

const IconOption = ({
  children, innerProps, data, ...props
}) => (
  <components.Option {...props} style={{ padding: 0 }}>
    <IconOptionItem innerProps={innerProps} value={data.value}>
      {children}
    </IconOptionItem>
  </components.Option>
);

export default function IconSelect(props) {
  return (
    <Select
      placeholder="Select icon"
      options={iconOptions}
      components={{ Option: IconOption, SingleValue: IconSingleValue }}
      style={{ maxWidth: 350, marginBottom: '1em' }}
      isClearable
      {...props}
    />
  );
}
