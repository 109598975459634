import React from 'react';
import Transport from '../TransportPopup/Transport/Transport';
import Page from './Page/Page';

export default function TransportPage({ transportPopupModel }) {
  return (
    <Page title="Transport details">
      <Transport
        transportPopupModel={transportPopupModel}
        onCloseButtonClick={() => {}}
      />
    </Page>
  );
}
