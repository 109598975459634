import callBackend from '../shared/methods/callBackend/callBackend';

const getTransports = async ({
  contRef, pageSize = 30, filters, sorting,
}) => {
  let url = `${process.env.REACT_APP_API_URL}/transport?pageSize=${pageSize}`;

  if (contRef) url += `&contRef=${contRef}`;
  if (filters) url += `&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  if (sorting) url += `&sorting=${encodeURIComponent(JSON.stringify(sorting))}`;

  const result = await callBackend({ url, method: 'GET' });
  return result;
};

export default getTransports;
