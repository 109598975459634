/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Tag } from '@storaensods/seeds-react';
import Div from '../shared/components/Div/Div';
import { ReactComponent as GripLines } from '../assets/grip-lines-vertical-solid.svg';

import styles from './TransportsTable.module.scss';

import useTransportsTable from './useTransportsTable';
import TransportsTableContext from './TransportsTableContext';

import { MANDATORY_COLUMNS } from './OrderAndShowColumnsDialog';

function TransportsTable({
  onTransportTrigger,
  ...props
}) {
  const renderRowSubComponent = useCallback(
    ({ row }) => {
      const { transportErrors, transportId } = row.original;

      const errorsList = transportErrors.map((err) => (
        <Tag style={{ marginLeft: 8 }} type="negative" key={`error-tag-${transportId}-${err}`}>{err}</Tag>
      ));

      return (<Div style={{ paddingLeft: 56 }} key={`errors-list-${transportId}`} padding={{ vertical: 's' }}>{errorsList}</Div>);
    },
    [],
  );

  const table = useTransportsTable();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    data,
    prepareRow,
    visibleColumns,
  } = table;

  return (
    <Div style={{ overflowX: 'auto' }} {...props}>
      <TransportsTableContext.Provider value={table}>
        <table {...getTableProps()} className={`se-table ${styles.transportsTable}`}>
          <thead className="se-table-head">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th width={column.totalWidth} {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {!MANDATORY_COLUMNS.some((id) => id === column.id) && (
                    <Div
                      {...column.getResizerProps()}
                      className={`${styles.resizer} ${
                        column.isResizing ? `${styles.isResizing}` : ''
                      }`}
                    >
                      <GripLines className={styles.gripLines} />
                    </Div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const { hasDeparted, hasArrived } = data[i];
              const classNames = [
                'se-table-item se-table-item--clickable',
                styles.transportsTable_row,
              ];

              if (!hasDeparted) classNames.push(styles.transportsTable_row_scheduled);
              if (hasDeparted && !hasArrived) classNames.push(styles.transportsTable_row_inTransit);
              if (hasArrived) classNames.push(styles.transportsTable_row_arrived);

              const trStyle = row.isExpanded ? { borderBottom: 0 } : {};
              return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${i}-row`}>
                  <tr
                    className={classNames.join(' ')}
                    style={trStyle}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => (cell.column.id !== 'expander' ? (
                      <td
                        {...cell.getCellProps()}
                        onClick={() => {
                          onTransportTrigger(row.original.transportId);
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ) : (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )))}
                  </tr>
                  {row.isExpanded ? (
                    <>
                      <tr />
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </td>
                      </tr>
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </TransportsTableContext.Provider>
    </Div>
  );
}

// .ReactTable .rt-noData {
//   transform: translate(-50%, 50%);
// }

export default observer(TransportsTable);
