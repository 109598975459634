import callBackend from '../shared/methods/callBackend/callBackend';

export type OpeningHours = {
  id: Array<string>,
  locationId: Array<string>,
  close: Array<string>,
  dayOfWeek: Array<number>,
  date: Array<Date>,
  timeZone: Array<string>,
};

const getOpeningHours = async (filters?: OpeningHours) => {
  let url = `${process.env.REACT_APP_API_URL}/openinghours`;
  if (filters) { url += `&filters=${encodeURIComponent(JSON.stringify(filters))}`; }
  return callBackend({
    url,
    method: 'GET',
  });
};

export default getOpeningHours;
