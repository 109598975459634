import { action, decorate, observable } from 'mobx';

class PopupModel {
  popupIDs = [];

  pushPopupID = (popupID) => {
    this.popupIDs = [...this.popupIDs, popupID];
    return this.popupIDs;
  };

  removePopupID = (popupID) => {
    this.popupIDs = this.popupIDs.filter((id) => id !== popupID);
    return this.popupIDs;
  };
}

export default decorate(PopupModel, {
  popupIDs: observable,
  pushPopupID: action,
  removePopupID: action,
});
