import React from 'react';
import TransportDateStatusCaption from '../../TransportDateStatusCaption/TransportDateStatusCaption';
import TransportCaption from '../../TransportCaption/TransportCaption';

function ArrivalCell({ data, row, value }) {
  let info = value;
  if (!info) {
    const { index: currentRow } = row;
    info = data[currentRow];
  }
  const {
    finalDestination,
  } = info;

  return (
    <TransportDateStatusCaption
      dateElement={(
        <>
          <TransportCaption date={finalDestination.arrivalActual} color="neutral80" />
          <TransportCaption time={finalDestination.arrivalActual} color="neutral80" />
        </>
      )}
    />
  );
}

export default ArrivalCell;
