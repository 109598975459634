import React from 'react';
import TransportDateStatusCaption from '../../TransportDateStatusCaption/TransportDateStatusCaption';
import TransportCaption from '../../TransportCaption/TransportCaption';

function DepartureCell({ data, row, value }) {
  let info = value;
  if (!info) {
    const { index: currentRow } = row;
    info = data[currentRow];
  }
  const {
    origin,
  } = info;

  return (
    <TransportDateStatusCaption
      dateElement={(
        <>
          <TransportCaption date={origin.departureActual} color="neutral80" />
          <TransportCaption time={origin.departureActual} color="neutral80" />
        </>
      )}
    />
  );
}

export default DepartureCell;
