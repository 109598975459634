import React from 'react';
import { observer } from 'mobx-react';
import QuickAccessBar from '../../shared/components/QuickAccessBar/QuickAccessBar';
import useQuickAccessBarItems from '../QuickAccess/useQuickAccessBarItems';
import withFilterInit from '../../Filters/withFilterInit';

function PageWrapper({ children }) {
  const quickAccessBarItems = useQuickAccessBarItems();

  return (
    <QuickAccessBar barItems={quickAccessBarItems}>
      {children}
    </QuickAccessBar>
  );
}

export default observer(withFilterInit(PageWrapper));
