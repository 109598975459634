import PropTypes from 'prop-types';
import React from 'react';
import Element from '../Element/Element';
import styles from './Heading.module.scss';

function Heading({
  tagName = 'h1', caption = false, className, children, ...props
}) {
  const classNames = [
    styles.heading,
    {
      [styles.heading__caption]: caption,
    },
    className,
  ];
  return (
    <Element tagName={tagName} className={classNames} {...props}>
      {children}
    </Element>
  );
}

const supportedTagNames = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

Text.propTypes = {
  tagName: PropTypes.oneOf(supportedTagNames),
};

export default Heading;
