import { useLocation, useHistory } from 'react-router-dom';
import Url from 'url-parse';

export default function useUrlLocationQuery() {
  const location = useLocation();
  const url = new Url(location.search, true);
  const history = useHistory();
  return {
    setQuery: (params) => {
      const newParams = { ...url.query, ...params };
      const newQuery = Object.keys(newParams).reduce(
        (acc, name) => {
          if (newParams[name]) {
            acc.push(`${name}=${encodeURIComponent(newParams[name])}`);
          }
          return acc;
        }, [] as Array<string>,
      );

      history.push({ search: `?${newQuery.join('&')}` });
    },
    getQuery: (name) => url.query[name],
    location,
    history,
    refresh: () => { history.push({ search: location.search }); },
  };
}
