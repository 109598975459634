import {
  action, autorun, decorate, observable,
} from 'mobx';
import contains from 'lodash/fp/contains';
import BreakpointModel from '../shared/styles/BreakpointModel';
import PopupModel from '../shared/components/Popup/PopupModel';
import TabMenuModel from '../shared/components/TabMenu/TabMenuModel';
import TransportModel from './Transport/TransportModel';
import ContainerModel from './Transport/ContainerModel';
import getModel from '../shared/methods/withModel/getModel';
import getTransport from './Transport/getTransport';
import setTransportPinStatus from './Transport/setTransportPinStatus';
import TransportCommentsModel from './Transport/TransportComments/TransportCommentsModel';

class TransportPopupModel {
  breakpointModel;

  expandedEvents = [];

  tabMenuModel;

  transportCommentsModel = new TransportCommentsModel();

  transportModel = new TransportModel();

  containerModel = new ContainerModel();

  transportLoadError;

  onTransportIsPinnedChanged;

  constructor() {
    this.breakpointModel = getModel(BreakpointModel);

    this.setTabMenuModel(new TabMenuModel());
  }

  setTransportPinStatus = async (transportID) => {
    const popupModel = getModel(PopupModel);

    const {
      callWasSuccessful,
    } = await setTransportPinStatus(transportID, !this.transportModel.isPinned);

    if (callWasSuccessful) {
      this.expandedEvents = [];

      this.transportModel.isPinned = !this.transportModel.isPinned;

      if (this.onTransportIsPinnedChanged) {
        this.onTransportIsPinnedChanged();
      }
    } else {
      popupModel.pushPopupID('transportLoadErrorPopup');
    }
  };

  openTransport = async (transportID, containerID) => {
    const popupModel = getModel(PopupModel);

    popupModel.pushPopupID('transportLoadingPopup');

    const { callWasSuccessful } = await this.loadTransport(transportID, containerID);

    popupModel.removePopupID('transportLoadingPopup');

    if (callWasSuccessful) {
      this.expandedEvents = [];

      if (containerID) {
        popupModel.pushPopupID('containerPopup');
      } else {
        popupModel.pushPopupID('transportPopup');
      }
    } else {
      popupModel.pushPopupID('transportLoadErrorPopup');
    }
  };

  loadTransport = async (transportID, containerID) => {
    const {
      callWasSuccessful, response, error,
    } = await getTransport(transportID);

    if (callWasSuccessful) {
      if (containerID) {
        const container = response.containers.filter((c) => c.containerId === containerID)[0];

        container.statusMessages = container.statusMessages || [];
        this.transportModel.setValues(response);
        this.containerModel.setValues(container);
      } else {
        this.transportModel.setValues(response, containerID);
      }

      this.expandedEvents = [];
    } else {
      this.transportLoadError = JSON.stringify(String(error));
    }

    return { callWasSuccessful, response, error };
  };

  postTransportComment = async () => {
    this.transportCommentsModel.commentIsBeingPosted = true;

    const {
      callWasSuccessful,
    } = await this.transportCommentsModel.postTransportComment(
      this.transportModel.transportId,
    );

    if (callWasSuccessful) {
      await this.loadTransport(this.transportModel.transportId);
    }

    this.transportCommentsModel.commentIsBeingPosted = false;
  };

  setTabMenuModel(value) {
    this.tabMenuModel = value;

    autorun(() => {
      if (this.breakpointModel.breakpoint) this.updateTransportTabMenuModelItems();
    });

    this.updateTransportTabMenuModelItems();
  }

  updateTransportTabMenuModelItems = () => {
    const items = [];

    let selectedID;

    if (this.breakpointModel.breakpointIsThinnerThan('l')) {
      items.push({ id: 'summary', label: 'Summary' });
      items.push({ id: 'map', label: 'Map' });

      selectedID = 'summary';
    } else {
      selectedID = 'details';
    }

    items.push({ id: 'details', label: 'Details' });

    items.push({ id: 'events', label: 'Events' });

    const commentsLength = this.transportModel.comments
      ? this.transportModel.comments.length
      : 0;
    items.push({
      id: 'comments',
      label: 'Comments',
      ...(commentsLength && {
        comments: {
          length: commentsLength,
          transportStage: this.transportModel.stage,
        },
      }),
    });

    this.tabMenuModel.setItems(items);
    this.tabMenuModel.setSelectedID(selectedID);
  };

  toggleEventExpanded(eventIndex) {
    if (contains(eventIndex, this.expandedEvents)) {
      this.expandedEvents.splice(this.expandedEvents.indexOf(eventIndex), 1);
    } else {
      this.expandedEvents.push(eventIndex);
    }
  }

  clearExpandedEvents() {
    this.expandedEvents = [];
  }
}

export default decorate(TransportPopupModel, {
  clearExpandedEvents: action,
  expandedEvents: observable,
  setTabMenuModel: action,
  tabMenuModel: observable,
  toggleEventExpanded: action,
  transportLoadError: observable,
});
