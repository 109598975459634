import callBackend from '../../../shared/methods/callBackend/callBackend';

const postComment = async ({ transportId, comment }) => {
  const stubbed = false;

  if (stubbed) {
    return new Promise(((resolve) => {
      setTimeout(resolve, 250, {
        callWasSuccessful: true,
      });
    }));
  }

  const url = `${process.env.REACT_APP_API_URL}/transport/comment/`;

  return callBackend({
    url,
    method: 'POST',
    body: { transportId, comment },
  });
};

export default postComment;
