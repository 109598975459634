import { observable, action, computed } from 'mobx';
import { findLastIndex } from 'lodash';
import setNewLocationPoint from './setNewLocationPoint';
import getFromMapsService from './getFromMapsService';
import { BackendCallResponse } from '../../../shared/methods/callBackend/callBackend';

type LatLongCoordinatesWithId = {
  latitude: number,
  longitude: number,
  id: string
}

const hasCoordinates = (location) => location.coordinates
&& location.coordinates.latitude
&& location.coordinates.longitude;

const getPathPoints = (locations, key) => locations && locations
  .filter((location) => hasCoordinates(location))
  .map((location, index) => ({
    key: `${key}-${index}`,
    id: location.id,
    ...location.coordinates,
    address: location.nameAddress.filter((v) => (v || '').replace(/\s/g, '')).join(', '),
  }));

const setPathPoints = (locations, key, lat, lng) => locations && locations
  .filter((location) => !hasCoordinates(location))
  .map((location, index) => ({
    key: `${key}-${index}`,
    id: location.id,
    latitude: lat,
    longitude: lng,
    address: location.nameAddress.filter((v) => (v || '').replace(/\s/g, '')).join(', '),
  }));

export default class TransportMapModel {
  @observable milestones: Array<any>;

  @observable path: Array<any>;

  @observable canEdit: boolean;

  @observable dropoffOrPickup: string;

  @observable isUpdating: boolean;

  isCurrentActive;

  isCurrentLate;

  currentSequence;

  transportId;

  pickupLocations;

  dropoffLocations;

  onUpdatefinalDestination;

  center: boolean;

  constructor({
    isCurrentLate,
    isCurrentActive,
    currentSequence,
    transportId,
    pickupLocations,
    dropoffLocations,
    milestones,
    center,
    onUpdatefinalDestination,
  }) {
    const initialPathPoints = (milestones || []).filter(
      (milestone) => milestone && milestone.latitude && milestone.longitude,
    );

    const pickupPathPoints = getPathPoints(pickupLocations, 'pickup') || [];

    const dropoffPathPoints = getPathPoints(dropoffLocations, 'dropoff') || [];

    const pathPoints = pickupPathPoints.concat(initialPathPoints, dropoffPathPoints);

    this.onUpdatefinalDestination = onUpdatefinalDestination;
    this.currentSequence = currentSequence;
    this.isCurrentActive = isCurrentActive;
    this.isCurrentLate = isCurrentLate;
    this.transportId = transportId;
    this.pickupLocations = pickupLocations;
    this.dropoffLocations = dropoffLocations;
    this.center = center;
    this.milestones = milestones || [];
    this.path = pathPoints;
    this.canEdit = false;
    this.dropoffOrPickup = 'dropoff';
    this.isUpdating = false;
  }

  areAllMilestonesAtSameLocation() {
    return this.milestones.length && this.path.every(
      ({ latitude, longitude }) => latitude === this.milestones[0].latitude
        && longitude === this.milestones[0].longitude,
    );
  }

  @computed
  get coordinates() {
    return this.path.map((point) => [
      point.latitude,
      point.longitude,
    ]);
  }

  @computed
  get canShowController() {
    return !!this.transportId;
  }

  @action
  updatePoint(point, index) {
    this.path[index] = point;
  }

  @action
  async toggleEdit(): Promise<boolean> {
    this.canEdit = !this.canEdit;

    await this.updatingLocationCoordinatesToAPI();

    return this.canEdit;
  }

  selectedLocation(selected : string) {
    this.dropoffOrPickup = selected;
  }

  async updatingLocationCoordinatesToAPI() {
    if (!this.canEdit) {
      const coordinates: Array<LatLongCoordinatesWithId> = this.getNewCoordinates();
      if (coordinates.length > 0) {
        this.isUpdating = true;
        const results: Array<BackendCallResponse> = [];
        // Array iterators are not generally async-aware, so using for-of
        // Reduce is technically possible, but complicated to implement
        // TODO: Update values as a single API call
        /* eslint-disable no-restricted-syntax */
        for await (const coordinate of coordinates) {
          const result = await setNewLocationPoint(
            this.transportId,
            coordinate.id,
            coordinate.latitude,
            coordinate.longitude,
            this.dropoffOrPickup,
          );
          results.push(result);
        }
        /* eslint-enable no-restricted-syntax */
        const error = results.some((result) => result.error);
        if (error) {
          // eslint-disable-next-line no-alert
          alert(`Sorry, ${this.dropoffOrPickup} update failed, please try again.`);
        } else if (typeof this.onUpdatefinalDestination === 'function') {
          // TODO: Fix this? Already broken from some previous changes
          // this.onUpdatefinalDestination(coordinates.latitude, coordinates.longitude);
        }
        this.isUpdating = false;
      }
    }
  }

  canDrag(point): boolean {
    const { key } = point;
    return this.canEdit && key && key.startsWith(this.dropoffOrPickup);
  }

  getLastLocationIndex(): number {
    return findLastIndex(this.path, (point) => point.key
    && point.key.startsWith(this.dropoffOrPickup));
  }

  getLocationsIndices(): Array<number> {
    const LocationsIndices : Array<number> = [];
    this.path.forEach((location, index) => {
      if (location.key && location.key.startsWith(this.dropoffOrPickup)) {
        LocationsIndices.push(index);
      }
    });
    return LocationsIndices;
  }

  @action
  setNewLocationCoordinates(lat: number, lng: number): boolean {
    if (!this.canEdit) {
      return false;
    }

    const lastLocationIndex = this.getLastLocationIndex();
    // If location coordinates are not missing
    if (lastLocationIndex > -1) {
      this.path[lastLocationIndex] = {
        ...this.path[lastLocationIndex],
        latitude: lat,
        longitude: lng,
      };
    } else {
      if (this.dropoffOrPickup === 'dropoff') {
        const dropoffPathPoints = getPathPoints(this.dropoffLocations, 'dropoff');
        if (dropoffPathPoints.length === 0) {
          const newPathPoints = setPathPoints(this.dropoffLocations, 'dropoff', lat, lng);
          this.path.push(newPathPoints[0]);
        }
      }
      if (this.dropoffOrPickup === 'pickup') {
        const pickupPathPoints = getPathPoints(this.pickupLocations, 'pickup');
        if (pickupPathPoints.length === 0) {
          const newPathPoints = setPathPoints(this.pickupLocations, 'pickup', lat, lng);
          this.path = newPathPoints.concat(this.path);
        }
      }
    }

    return true;
  }

  @action
  resetLocationCoordinates(): boolean {
    if (!this.canEdit) {
      return false;
    }
    if (this.dropoffOrPickup === 'dropoff') {
      const dropoffPathPoints = getPathPoints(this.dropoffLocations, 'dropoff');
      if (dropoffPathPoints.length > 0) {
        this.path.splice(-dropoffPathPoints.length);
        this.path.push(...dropoffPathPoints);
        return true;
      }
    }
    if (this.dropoffOrPickup === 'pickup') {
      const pickupPathPoints = getPathPoints(this.pickupLocations, 'pickup');
      if (pickupPathPoints.length > 0) {
        this.path.shift();
        this.path = pickupPathPoints.concat(this.path);
        return true;
      }
    }
    return false;
  }

  @action
  async fetchCoordinatesFromMapService() {
    if (!this.canEdit) {
      return;
    }
    if (this.dropoffOrPickup === 'dropoff') {
      try {
        const address = this.dropoffLocations[0].nameAddress.join();
        const mapsServiceResponse = await getFromMapsService(address);
        const { lat, lon } = mapsServiceResponse.response;
        this.setNewLocationCoordinates(lat, lon);
      } catch (error) {
        alert(`Sorry, MapsService not able to find location address: ${this.dropoffLocations[0].nameAddress.join()}`);
      }
    }
    if (this.dropoffOrPickup === 'pickup') {
      try {
        const address = this.pickupLocations[0].nameAddress.join();
        const mapsServiceResponse = await getFromMapsService(address);
        const { lat, lon } = mapsServiceResponse.response;
        this.setNewLocationCoordinates(lat, lon);
      } catch (error) {
        alert(`Sorry, MapsService not able to find location address: ${this.pickupLocations[0].nameAddress.join()}`);
      }
    }
  }

  getNewCoordinates(): Array<LatLongCoordinatesWithId> {
    const coordinates: Array<LatLongCoordinatesWithId> = [];
    const locationPointIndices = this.getLocationsIndices();
    locationPointIndices.forEach((locationPointIndex) => {
      const { id } = this.path[locationPointIndex];
      const oldLocationPoint = (this.dropoffOrPickup === 'dropoff') ? this.dropoffLocations.find((location) => location.id === id)
        : this.pickupLocations.find((location) => location.id === id);
      if (oldLocationPoint) {
        const { latitude: newLat, longitude: newLng } = this.path[locationPointIndex];
        const { latitude: oldLat, longitude: oldLng } = oldLocationPoint.coordinates;
        if (newLat !== oldLat || newLng !== oldLng) {
          coordinates.push({
            latitude: newLat,
            longitude: newLng,
            id,
          });
        }
      }
    });
    return coordinates;
  }
}
