import React from 'react';
import { Input, Button } from '@storaensods/seeds-react';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Form from '../shared/components/Form/Form';
import Flex from '../shared/components/Flex/Flex';
import { toLocaleDateFormat } from '../Filters/TimeRangeFilters/TimeRangeFormat';
import DatePicker from '../shared/components/DatePicker/DatePicker';
import Select from '../shared/components/Select/Select';
import { dayOfWeekOptions } from './OpeningHoursModel';

const validationSchema = yup.object({
  locationId: yup
    .string()
    .required(),
  open: yup
    .string()
    .required(),
  close: yup
    .string()
    .required(),
  dayOfWeek: yup
    .number().nullable(true),
  date: yup
    .date().nullable(true),
  timeZoneId: yup
    .string()
    .required(),
});

const initialValues = {
  locationId: '',
  close: '',
  dayOfWeek: '',
  date: '',
  timeZoneId: 'Europe/Paris',
};

export default function OpeningHoursForm({ data, timezones, onSubmit }) {
  const {
    values, handleChange, handleSubmit, errors, submitForm, isSubmitting, setFieldValue,
  } = useFormik({
    initialValues: data || initialValues,
    onSubmit,
    validationSchema,
  });

  const timezonesKeyValueList = timezones.map((timezone) => ({ label: timezone, value: timezone }));

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        style={{ marginBottom: 8 }}
        label="Location Id"
        id="locationId"
        size="sm"
        placeholder="Location id"
        value={values.locationId}
        onChange={handleChange}
        helpText={errors && errors.locationId}
        invalid={errors && !!errors.locationId}
      />
      <Input
        style={{ marginBottom: 8 }}
        label="Open"
        id="open"
        size="sm"
        placeholder="00:00"
        value={values.open}
        onChange={handleChange}
        helpText={errors && errors.open}
        invalid={errors && !!errors.open}
      />
      <Input
        style={{ marginBottom: 8 }}
        label="Close"
        id="close"
        size="sm"
        placeholder="00:00"
        value={values.close}
        onChange={handleChange}
        helpText={errors && errors.close}
        invalid={errors && !!errors.close}
      />

      <label htmlFor="dayOfWeek" style={{ marginBottom: 8 }}>Day of the week</label>
      <Select
        id="dayOfWeek"
        placeholder="Select day..."
        style={{ marginBottom: 8 }}
        isClearable
        isSearchable
        isMulti={false}
        options={dayOfWeekOptions}
        defaultValue={dayOfWeekOptions.filter((item) => item.value === values.dayOfWeek)}
        onChange={(val) => (val == null ? setFieldValue('dayOfWeek', null) : setFieldValue('dayOfWeek', val.value))}
        onClear={() => setFieldValue('dayOfWeek', null)}
      />
      <label htmlFor="date" style={{ marginBottom: 8 }}>Date</label>
      <DatePicker
        id="date"
        placeholderText="Select Date..."
        style={{ marginBottom: 8 }}
        isClearable
        selectsStart
        isMulti={false}
        selected={values.date ? new Date(values.date) : null}
        showTimeSelect={false}
        dateFormat={toLocaleDateFormat()}
        onChange={(val) => { setFieldValue('date', `${moment(val).format('yyyy-MM-DD')}`); }}
        onClear={() => setFieldValue('date', null)}
      />
      <label htmlFor="timeoneId" style={{ marginBottom: 8 }}>Timezone</label>
      <Select
        id="timeZoneId"
        style={{ marginBottom: 8 }}
        isSearchable
        isMulti={false}
        options={timezonesKeyValueList}
        value={{ value: values.timeZoneId, label: values.timeZoneId }}
        placeholder="Select time zone..."
        onChange={(val) => { setFieldValue('timeZoneId', val.value); }}
      />
      <Flex style={{ flexDirection: 'row-reverse' }}>
        <Button onClick={submitForm} disabled={isSubmitting}>
          {data ? 'Update' : 'Save'}
        </Button>
      </Flex>
    </Form>
  );
}
