import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import getTrackingRules from './getRules';
import getTrackingExcludes from './getExcluded';
import postTrackingRule from './postRule';
import postExcludedLocation from './postExclude';
import putTrackingRule from './putRule';
import deleteTrackingRule from './deleteRule';
import deleteExcluded from './deleteExcluded';

const ERROR_MESSAGE = 'Request failed, please try again later';

export type TrackingRuleListItem = {
  id: number,
  originId: string,
  destinationId: string,
  carrierId: string,
  transportModeType: string,
  trackingPartyName: string,
  isLicensePlateNumberRequired: boolean
};
export type ExcludedListItem = {
  id: number,
  excludedId: string,
};

export default class TrackingRulesModel {
  @observable rulesList: Array<TrackingRuleListItem> = [];

  @observable excludedList: Array<ExcludedListItem> = [];

  @observable loadingRules = false;

  @observable loadingExcluded = false;

  @action
  async addTrackingRulesModel(values) {
    this.loadingRules = true;
    const { callWasSuccessful, response } = await postTrackingRule(values);
    if (callWasSuccessful && response !== undefined) {
      this.rulesList.push(response as TrackingRuleListItem);
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loadingRules = false;
  }

  @action
  async removeRule(id) {
    this.loadingRules = true;
    const { callWasSuccessful } = await deleteTrackingRule(id);
    if (callWasSuccessful) {
      this.rulesList = this.rulesList.filter(({ id: itemId }) => itemId !== id);
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loadingRules = false;
  }

  @action
  async updateTrackingRulesModel(values) {
    this.loadingRules = true;
    const { callWasSuccessful } = await putTrackingRule(values.id, values);
    if (callWasSuccessful) {
      const index = this.rulesList.findIndex(({ id }) => values.id === id);
      if (index > -1) {
        this.rulesList[index] = values;
      }
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loadingRules = false;
  }

  @action
  async loadTrackingRules() {
    this.loadingRules = true;
    const { callWasSuccessful, response } = await getTrackingRules();
    if (callWasSuccessful) {
      this.rulesList = response;
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loadingRules = false;
  }

  // Excluded locations handling

  @action
  async addExcludedLocationModel(values) {
    this.loadingExcluded = true;
    const { callWasSuccessful, response } = await postExcludedLocation(values);
    if (callWasSuccessful && response !== undefined) {
      this.excludedList.push(response as ExcludedListItem);
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loadingExcluded = false;
  }

  @action
  async removeExcluded(id) {
    this.loadingExcluded = true;
    const { callWasSuccessful } = await deleteExcluded(id);
    if (callWasSuccessful) {
      this.excludedList = this.excludedList.filter(({ id: itemId }) => itemId !== id);
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loadingExcluded = false;
  }

  @action
  async loadTrackingExcludes() {
    this.loadingExcluded = true;
    const { callWasSuccessful, response } = await getTrackingExcludes();
    if (callWasSuccessful) {
      this.excludedList = response;
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loadingExcluded = false;
  }
}
