import { decorate, observable } from 'mobx';
import TextInputModel from '../../../shared/components/TextInput/TextInputModel';
import postComment from './postComment';
import getModel from '../../../shared/methods/withModel/getModel';
import PopupModel from '../../../shared/components/Popup/PopupModel';

class TransportCommentsModel {
  commentIsBeingPosted = false;

  commentPostError;

  inputModel = new TextInputModel();

  postTransportComment = async (transportId) => {
    this.commentPostError = null;

    const { callWasSuccessful, error } = await postComment({
      transportId,
      comment: this.inputModel.value,
    });

    if (callWasSuccessful) {
      this.inputModel.setValue('');
    } else {
      this.commentPostError = error;
      getModel(PopupModel).pushPopupID('transportCommentErrorPopup');
    }

    return { callWasSuccessful };
  };
}

export default decorate(TransportCommentsModel, {
  commentIsBeingPosted: observable,
  commentPostError: observable,
});
