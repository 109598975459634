import upperFirst from 'lodash/fp/upperFirst';

import styles from '../../styles/Margin.module.scss';

export default ({
  size,
  vertical = size,
  horizontal = size,
  left = horizontal,
  right = horizontal,
  top = vertical,
  bottom = vertical,
  lastChild = {},
}) => {
  const classes = {};

  if (left) classes[styles[`margin__size${upperFirst(left)}Left`]] = true;
  if (right) classes[styles[`margin__size${upperFirst(right)}Right`]] = true;
  if (top) classes[styles[`margin__size${upperFirst(top)}Top`]] = true;
  if (bottom) classes[styles[`margin__size${upperFirst(bottom)}Bottom`]] = true;
  if (lastChild.bottom === false) classes[styles.margin__lastChildSizeZeroBottom] = true;

  return classes;
};
