import callBackend from '../shared/methods/callBackend/callBackend';

const getFilterOptions = async () => {
  const url = `${process.env.REACT_APP_API_URL}/transportdata`;

  const result = await callBackend({ url, method: 'GET' });
  return result;
};

export default getFilterOptions;
