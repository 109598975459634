import { observable, action, computed } from 'mobx';

export default class SavedFilterSelectModel {
  @observable options = [] as Array<{value: string, label: string, isCheck?: boolean}>;

  @observable value: { label: string, value: string } | null = null;

  @action
  updateOptions(options) {
    this.options = options;
    this.setValue(null);
  }

  @action
  setValue(value) {
    this.value = value;
  }

  setValueByName(name: string) {
    const value = { label: name, value: name };
    this.setValue(value);
    return value;
  }

  @computed
  get allSelectedValues() {
    const options = this.options.filter(({ isCheck }) => isCheck);
    return options.map(({ value }) => value);
  }

  @action
  toggleCheckOption(value) {
    this.options = this.options.map((opt) => {
      if (opt.value === value) {
        return {
          ...opt,
          isCheck: !opt.isCheck,
        };
      }
      return opt;
    });
  }
}
