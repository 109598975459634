import React from 'react';
import { Input, Button, Checkbox } from '@storaensods/seeds-react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Form from '../shared/components/Form/Form';
import Flex from '../shared/components/Flex/Flex';

const validationSchema = yup.object({
  originId: yup
    .string()
    .required(),
  destinationId: yup
    .string()
    .required(),
  carrierId: yup
    .string()
    .required(),
  transportModeType: yup
    .string()
    .required(),
  trackingPartyName: yup
    .string()
    .required(),
});

const initialValues = {
  originId: '',
  destinationId: '',
  carrierId: '',
  transportModeType: '',
  trackingPartyName: '',
  isLicensePlateNumberRequired: true,
};

export default function TrackingRulesForm({ data, onSubmit }) {
  const {
    values, handleChange, handleSubmit, errors, submitForm, isSubmitting,
  } = useFormik({
    initialValues: data || initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        style={{ marginBottom: 8 }}
        label="Pick Up Location ID"
        id="originId"
        size="sm"
        value={values.originId}
        onChange={handleChange}
        helpText={errors && errors.originId}
        invalid={errors && !!errors.originId}
      />
      <Input
        style={{ marginBottom: 8 }}
        label="Drop Off Location ID"
        id="destinationId"
        size="sm"
        value={values.destinationId}
        onChange={handleChange}
        helpText={errors && errors.destinationId}
        invalid={errors && !!errors.destinationId}
      />
      <Input
        style={{ marginBottom: 8 }}
        label="Carrier ID"
        id="carrierId"
        size="sm"
        value={values.carrierId}
        onChange={handleChange}
        helpText={errors && errors.carrierId}
        invalid={errors && !!errors.carrierId}
      />
      <Input
        style={{ marginBottom: 8 }}
        label="Transport Mode"
        id="transportModeType"
        size="sm"
        value={values.transportModeType}
        onChange={handleChange}
        helpText={errors && errors.transportModeType}
        invalid={errors && !!errors.transportModeType}
      />
      <Input
        style={{ marginBottom: 8 }}
        label="Tracker"
        id="trackingPartyName"
        size="sm"
        value={values.trackingPartyName}
        onChange={handleChange}
        helpText={errors && errors.trackingPartyName}
        invalid={errors && !!errors.trackingPartyName}
      />
      <Checkbox
        id="isLicensePlateNumberRequired"
        onChange={handleChange}
        checked={values.isLicensePlateNumberRequired}
      >
        Is license plate number required
      </Checkbox>
      <Flex style={{ flexDirection: 'row-reverse' }}>
        <Button onClick={submitForm} disabled={isSubmitting}>
          { data ? 'Update' : 'Save'}
        </Button>
      </Flex>
    </Form>
  );
}
