import { action, computed, observable } from 'mobx';
import moment from 'moment';
import InputModel from '../../shared/components/InputModel/InputModel';

type TCurrentDateUse = {
  useCurrentDateForDeparture?: boolean,
  useCurrentDateForArrival?: boolean,
};

export function replaceWithCurrentDate(filterDateTime, days = 0) {
  const currentDate = moment();

  currentDate.hours(filterDateTime.hours());
  currentDate.minutes(filterDateTime.minutes());
  currentDate.milliseconds(0);

  if (days) {
    currentDate.add(days, 'day');
  }

  return currentDate.toDate();
}

function replaceFromToWithCurrentDateIfTrue(fromValue, toValue) {
  let from;
  let to;

  if (fromValue) {
    const dateFrom = moment(fromValue);
    from = replaceWithCurrentDate(dateFrom);
  } else {
    const startOfToday = moment().startOf('day');
    from = replaceWithCurrentDate(startOfToday);
  }

  if (toValue) {
    const dateTo = moment(toValue);

    let days = 0;
    if (fromValue) {
      const dateFrom = moment(fromValue);
      days = dateTo.diff(dateFrom, 'days');
    }
    to = replaceWithCurrentDate(dateTo, days);
  } else {
    const startOfToday = moment().startOf('day');
    to = replaceWithCurrentDate(startOfToday, 1);
  }

  return {
    from, to,
  };
}

export default class TimeRangeFiltersModel {
  departureFromModel = new InputModel();

  departureToModel = new InputModel();

  @observable
    useCurrentDateForDepartureModel = false;

  arrivalFromModel = new InputModel();

  arrivalToModel = new InputModel();

  @observable
    useCurrentDateForArrivalModel = false;

  @action
  toggleDepartureCheckbox() {
    this.useCurrentDateForDepartureModel = !this.useCurrentDateForDepartureModel;
    this.setCurrentDateIfTrue({
      useCurrentDateForDeparture: this.useCurrentDateForDepartureModel,
    });
  }

  @action
  toggleArrivalCheckbox() {
    this.useCurrentDateForArrivalModel = !this.useCurrentDateForArrivalModel;
    this.setCurrentDateIfTrue({
      useCurrentDateForArrival: this.useCurrentDateForArrivalModel,
    });
  }

  setCurrentDateIfTrue(currentDateUse: TCurrentDateUse) {
    const {
      useCurrentDateForDeparture,
      useCurrentDateForArrival,
    } = currentDateUse;

    if (useCurrentDateForDeparture) {
      const {
        from: departureFrom, to: departureTo,
      } = replaceFromToWithCurrentDateIfTrue(
        this.departureFromModel.value,
        this.departureToModel.value,
      );

      if (departureFrom) {
        this.departureFromModel.setValue(departureFrom);
      }
      if (departureTo) {
        this.departureToModel.setValue(departureTo);
      }
    }
    if (useCurrentDateForArrival) {
      const {
        from: arrivalFrom, to: arrivalTo,
      } = replaceFromToWithCurrentDateIfTrue(
        this.arrivalFromModel.value,
        this.arrivalToModel.value,
      );
      if (arrivalFrom) {
        this.arrivalFromModel.setValue(arrivalFrom);
      }
      if (arrivalTo) {
        this.arrivalToModel.setValue(arrivalTo);
      }
    }
  }

  @computed
  get filters() {
    const formatDate = (value) => moment(value)
      .toISOString();

    const departureFrom = this.departureFromModel.value;
    const departureTo = this.departureToModel.value;
    const arrivalFrom = this.arrivalFromModel.value;
    const arrivalTo = this.arrivalToModel.value;
    const useCurrentDateForDeparture = this.useCurrentDateForDepartureModel;
    const useCurrentDateForArrival = this.useCurrentDateForArrivalModel;

    return {
      ...((departureFrom || departureTo) && {
        departure: {
          ...(departureFrom && { from: formatDate(departureFrom) }),
          ...(departureTo && { to: formatDate(departureTo) }),
        },
      }),
      ...((arrivalFrom || arrivalTo) && {
        arrival: {
          ...(arrivalFrom && { from: formatDate(arrivalFrom) }),
          ...(arrivalTo && { to: formatDate(arrivalTo) }),
        },
      }),
      ...(useCurrentDateForDeparture) && {
        useCurrentDateForDeparture,
      },
      ...(useCurrentDateForArrival && {
        useCurrentDateForArrival,
      }),
    };
  }

  @computed
  get tags() {
    return {
      departureFrom: {
        value: this.departureFromModel.value,
        remove: () => { this.departureFromModel.setValue(null); },
      },
      departureTo: {
        value: this.departureToModel.value,
        remove: () => { this.departureToModel.setValue(null); },
      },
      arrivalFrom: {
        value: this.arrivalFromModel.value,
        remove: () => { this.arrivalFromModel.setValue(null); },
      },
      arrivalTo: {
        value: this.arrivalToModel.value,
        remove: () => { this.arrivalToModel.setValue(null); },
      },
      useCurrentDateForDeparture: {
        value: this.useCurrentDateForDepartureModel,
        remove: () => { this.useCurrentDateForDepartureModel = false; },
      },
      useCurrentDateForArrival: {
        value: this.useCurrentDateForArrivalModel,
        remove: () => { this.useCurrentDateForArrivalModel = false; },
      },
    };
  }

  @action
    setFilterValues = (filterValues, filterProps) => {
      if (filterValues.arrival) {
        if (filterValues.arrival.from) {
          this.arrivalFromModel.setValue(moment(filterValues.arrival.from).toDate());
        } else {
          this.arrivalFromModel.setValue(null);
        }
        if (filterValues.arrival.to) {
          this.arrivalToModel.setValue(moment(filterValues.arrival.to).toDate());
        } else {
          this.arrivalToModel.setValue(null);
        }
      } else {
        this.arrivalFromModel.setValue(null);
        this.arrivalToModel.setValue(null);
      }

      if (filterValues.departure) {
        if (filterValues.departure.from) {
          this.departureFromModel.setValue(moment(filterValues.departure.from).toDate());
        } else {
          this.departureFromModel.setValue(null);
        }
        if (filterValues.departure.to) {
          this.departureToModel.setValue(moment(filterValues.departure.to).toDate());
        } else {
          this.departureToModel.setValue(null);
        }
      } else {
        this.departureFromModel.setValue(null);
        this.departureToModel.setValue(null);
      }
      // Checks from old location of value for these fields (for now)
      // Realistically for forever :)
      const currentDateforDeparture = filterValues.useCurrentDateForDeparture
        || filterProps.isUseCurrentDateForDeparture;
      if (currentDateforDeparture) {
        this.useCurrentDateForDepartureModel = currentDateforDeparture;
      } else {
        this.useCurrentDateForDepartureModel = false;
      }
      const useCurrentDateForArrival = filterValues.useCurrentDateForArrival
        || filterProps.isUseCurrentDateForArrival;
      if (useCurrentDateForArrival) {
        this.useCurrentDateForArrivalModel = useCurrentDateForArrival;
      } else {
        this.useCurrentDateForArrivalModel = false;
      }
    };
}
