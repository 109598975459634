import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Heading from '../../../shared/components/typography/Heading';
import Div from '../../../shared/components/Div/Div';
import Badge from '../../../shared/components/Badge/Badge';
import styles from '../QuickAccessItems.module.scss';

function ErrorStatsList({ errorStats }) {
  const errorStatsFilterNames = Object.keys(errorStats);
  return (
    <Div>
      <Heading tagName="h6" style={{ margin: '20px 0' }}>Tracking Error</Heading>
      <Div>
        {errorStatsFilterNames.map((filtername) => (
          <Link
            to={`/?filtername=${filtername}`}
            key={filtername}
          >
            <Badge
              isOnRight
              value={errorStats[filtername]}
              className={styles.itemContainer}
            >
              {filtername}
            </Badge>
          </Link>
        ))}
        {!errorStatsFilterNames.length && (
          <span>No Errors found</span>
        )}
      </Div>
    </Div>
  );
}

export default observer(ErrorStatsList);
