import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
  auth: {
    authority: process.env.REACT_APP_AAD_API_ID_URI,
    clientId: process.env.REACT_APP_AAD_CLIENT_ID,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const loginAuthParameters = {
  scopes: [
    'user.read',
  ],
  redirectUri: `${window.location.origin}/auth.html`,
};

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: `${window.location.origin}/auth.html`,
};

const accessTokenAuthParams = {
  scopes: [
    process.env.REACT_APP_AAD_API_SCOPE_URI,
  ],
  redirectUri: `${window.location.origin}/auth.html`,
};

const createAuthProvider = () => new MsalAuthProvider(config, loginAuthParameters, options);

export {
  createAuthProvider,
  accessTokenAuthParams,
  loginAuthParameters,
};
