import React from 'react';
import { Icon } from '@storaensods/seeds-react';

import Div from '../Div/Div';
import Flex from '../Flex/Flex';

import styles from './ProgressLine.module.scss';

import Heading from '../typography/Heading';

import ProgressStepsEnum from './ProgressStepsEnum';

import { ReactComponent as StepFullIcon } from '../../../assets/step-full.svg';
import { ReactComponent as StepUnkownIcon } from '../../../assets/step-nutral.svg';
import { ReactComponent as StepEmptyIcon } from '../../../assets/step-empty.svg';
import { ReactComponent as StepErrorIcon } from '../../../assets/step-error.svg';

/**
  steps = Array<{
    title: string,
    body: [] react componets,
    type: ProgressStepsEnum.DONE,
    icon: string material design icon names,
  }>
 */
export default function ProgressLine({ steps = [], ...props }) {
  return (
    <Div className={styles.progressContainer}>
      <span className={styles.progressPath} />
      {steps.map(({
        icon, title, body, type,
      }, index) => {
        let StepIcon;
        let stepStyle;
        switch (type) {
          case ProgressStepsEnum.DONE:
            StepIcon = StepFullIcon;
            stepStyle = styles.enabled;
            break;
          // Note: this is currently not used, but kept here in case it is wanted back
          case ProgressStepsEnum.DONE_UNKNOWN:
            StepIcon = StepUnkownIcon;
            stepStyle = styles.enabled;
            break;
          case ProgressStepsEnum.ACTIVE:
            StepIcon = StepFullIcon;
            stepStyle = styles.active;
            break;
          case ProgressStepsEnum.ERROR:
            StepIcon = StepErrorIcon;
            stepStyle = styles.error;
            break;
          default:
            StepIcon = StepEmptyIcon;
            stepStyle = null;
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Flex className={styles.progressStep} key={`${title}-${index}`} {...props}>
            <Div className={[styles.step, stepStyle]}>
              <StepIcon />
            </Div>
            <Div className={styles.icon}>
              <Icon>{icon}</Icon>
            </Div>
            <Div>
              <Heading tagName="h6" className={styles.title}>{title}</Heading>
              <Div className={styles.body}>
                {body}
              </Div>
            </Div>
          </Flex>
        );
      })}
    </Div>
  );
}
