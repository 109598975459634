import React from 'react';
import Flex from '../shared/components/Flex/Flex';
import TransportDateStatusCaptionModel from './TransportDateStatusCaptionModel';
import addDecorators from '../shared/methods/addDecorators/addDecorators';
import withModel from '../shared/methods/withModel/withModel';

function TransportDateStatusCaption({
  dateElement,
  statusElement,
  dateStatusSwitch = true,
}) {
  return (
    <>
      <Flex shownResponsive={{ thinnerThan: 'm' }}>
        {dateStatusSwitch && dateElement}
        {!dateStatusSwitch && statusElement}
      </Flex>
      <Flex shownResponsive={{ widerThan: 's' }}>
        {dateElement}
        {statusElement}
      </Flex>
    </>
  );
}

export default addDecorators(
  withModel({
    Model: TransportDateStatusCaptionModel,
    props: ['dateStatusSwitch'],
  }),
).to(TransportDateStatusCaption);
