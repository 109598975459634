import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Icon, Spinner } from '@storaensods/seeds-react';
import { Link } from 'react-router-dom';
import Heading from '../../../shared/components/typography/Heading';
import Div from '../../../shared/components/Div/Div';
import Flex from '../../../shared/components/Flex/Flex';
import styles from '../QuickAccessItems.module.scss';
import AppContext from '../../../AppContext';

function FiltersAccessItems() {
  const { filtersModel } = useContext(AppContext);

  const [selectedFilterNames, setSelectedFilterNames] = useState([]);

  const isCheck = (filtername) => selectedFilterNames.includes(filtername);

  const removeFilterNameFromList = (filternames) => {
    const newSelected = selectedFilterNames.filter((item) => !filternames.includes(item));
    setSelectedFilterNames(newSelected);
  };

  const onCheck = (filtername) => {
    if (isCheck(filtername)) {
      removeFilterNameFromList([filtername]);
    } else {
      setSelectedFilterNames([
        ...selectedFilterNames,
        filtername,
      ]);
    }
  };

  const updateTransportsListSavedFilters = () => {
    filtersModel.savedFiltersSelectModel.updateOptions(filtersModel.getSavedFiltersOption());
  };

  const onDelete = () => {
    filtersModel.removeSelectedFilters(selectedFilterNames);
    removeFilterNameFromList(selectedFilterNames);
    updateTransportsListSavedFilters();
  };

  const isAlert = (filtername) => {
    const { props } = filtersModel.getSavedFilterValuesAndPropsByName(filtername);
    const { isAddToAccessBar } = props || {};
    return isAddToAccessBar;
  };

  const icon = (filtername) => {
    const { props } = filtersModel.getSavedFilterValuesAndPropsByName(filtername);
    const { alertIcon } = props || {};
    return alertIcon ? alertIcon.value : 'notifications';
  };

  const color = (filtername) => {
    const { props } = filtersModel.getSavedFilterValuesAndPropsByName(filtername);
    const { alertColor } = props || {};
    return alertColor ? alertColor.value : null;
  };

  return (
    <Div>
      <Flex centeredVertically>
        <Heading tagName="h6" style={{ margin: '20px 0' }} className={styles.itemLabel}>
          Filters
        </Heading>
        <Icon onClick={() => onDelete()} className={styles.itemIcon}>delete</Icon>
        <Link to="./filter">
          <Icon className={styles.itemIcon}>add</Icon>
        </Link>
      </Flex>
      <Div>
        {filtersModel.filterOptionsLoading
          ? <Spinner size="lg" />
          : filtersModel.savedFiltersNames.map((filtername) => (
            <Flex
              key={filtername}
              className={styles.itemContainer}
              centeredVertically
            >
              <Icon onClick={() => onCheck(filtername)}>
                {isCheck(filtername) ? 'check_box' : 'check_box_outline_blank'}
              </Icon>
              <span className={styles.itemLabel}>
                {filtername}
              </span>
              {isAlert(filtername) && (
                <Icon style={{ color: color(filtername) }}>
                  {icon(filtername)}
                </Icon>
              )}
              <Link
                to={`/filter?filtername=${filtername}`}
              >
                <Icon>edit</Icon>
              </Link>
            </Flex>
          ))}
      </Div>
    </Div>
  );
}

export default observer(FiltersAccessItems);
