export default function getLatenessStatusDifferenceLabel(latenessStatusDifference: number) {
  let label = '';

  if (latenessStatusDifference) {
    const absMinutes = Math.abs(latenessStatusDifference);
    const minutes = absMinutes % 60;
    const hours = Math.floor((absMinutes / 60) % 24);
    const days = Math.floor(absMinutes / 60 / 24);
    label = `${latenessStatusDifference < 0 ? '-' : '+'}`;
    label += days ? `${days.toString().padStart(2, '0')} D ` : '';
    label += `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  return label;
}
