import React from 'react';
import moment from 'moment';
import { Icon } from '@storaensods/seeds-react';
import Flex from '../../../shared/components/Flex/Flex';
import colors from '../../../shared/styles/Colors.module.scss';
import InternalEventCodeEnum from '../InternalEventCodeEnum';
import LabelAndValue from './LabelAndValue';
import { DEFAULT_FULL_FORMAT } from '../../../Filters/TimeRangeFilters/TimeRangeFormat';

export default function TransportStatusIconAndLabel({
  transportId, latestEventCode, style, lastEvent, ...props
}) {
  let icon = 'local_shipping';

  switch (latestEventCode) {
    case InternalEventCodeEnum.PLANNED: { icon = 'insert_drive_file'; break; }
    case InternalEventCodeEnum.IN_TRANSIT_PICK_UP: { icon = 'local_shipping'; break; }
    case InternalEventCodeEnum.ARRIVED_PICK_UP: { icon = 'home'; break; }
    case InternalEventCodeEnum.IN_TRANSIT_DROP_OFF: { icon = 'local_shipping'; break; }
    case InternalEventCodeEnum.ARRIVED_DROP_OFF: { icon = 'flag'; break; }
    case InternalEventCodeEnum.DEPARTED_DROP_OFF: { icon = 'flag'; break; }
    case InternalEventCodeEnum.CANCELLED: { icon = 'cancel'; break; }
    case InternalEventCodeEnum.TRACKING_FAILED: { icon = 'round_location_off'; break; }
    default: break;
  }

  return (
    <>
      <Flex
        padding={{ horizontal: 'l', vertical: 'l' }}
        fullWidth
        style={{
          backgroundColor: colors.yellow100,
          height: 134,
          flexDirection: 'column',
          ...style,
        }}
        {...props}
      >
        <Flex fullWidth fullHeight centered>
          <Flex centered style={{ width: 72, paddingRight: 16 }}>
            <Icon style={{ fontSize: '36px' }}>{icon}</Icon>
          </Flex>
          <Flex fullWidth centeredVertically>
            <LabelAndValue
              labelfontSize="14px"
              valuefontSize="18px"
              label="Transport ID"
              margin={{ bottom: undefined }}
              ellipseValue
              valueWidth={200}
            >
              {transportId}
            </LabelAndValue>
          </Flex>
        </Flex>
        <Flex style={{ paddingLeft: 60, fontSize: '80%' }}>
          {lastEvent && lastEvent.creationTime ? (`Last event: ${moment(lastEvent.creationTime).format(DEFAULT_FULL_FORMAT)}`) : ''}
        </Flex>
      </Flex>
    </>
  );
}
