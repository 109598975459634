import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from '@storaensods/seeds-react';
import classnames from 'classnames';
import Div from '../../../shared/components/Div/Div';
import Flex from '../../../shared/components/Flex/Flex';
import Grid from '../../../shared/components/Grid/Grid';
import Text from '../../../shared/components/typography/Text';
import TransportCaption from '../../../TransportCaption/TransportCaption';
import TransportMap from '../TransportMap/TransportMap';
import TransportMapModel from '../TransportMap/TransportMapModel';
import getDateDiff from '../../../shared/methods/getDateDiff/getDateDiff';
import styles from './TransportEvents.module.scss';
import eventFriendlyNames from '../../../shared/methods/externalEventFriendlyNames/externalEventFriendlyNames';
import SimpleMarker from '../TransportMap/SimpleMarker';

const Event = ({
  isCurrentLate,
  isCurrentActive,
  currentSequence,
  event,
  milestoneIndex,
  isMilestone = Boolean(event.milestone),
  hasEta = Boolean(event.milestone && event.milestone.eta),
  arrivalRequestedTo,
  isExpanded,
  onExpandToggle,
  onDownloadClick,
  toggleHideShow,
  groupLength,
  ...props
}) => (
  <Flex column fullWidth {...props}>
    {event.firstEventOfGroup && (
      <Grid
        className={classnames(
          [styles.accordianContainer, styles.transportEvents_event], { [styles.open]: event.show },
        )}
        fullWidth
        columns="32px 32px 1fr 1fr 1em"
        padding={{ vertical: 'xs' }}
        backgroundColor="neutral20"
        pointer={event.firstEventOfGroup}
      >
        <Flex centered onClick={() => toggleHideShow(event)}>
          <SimpleMarker
            label={groupLength}
            active={isCurrentActive}
            late={isCurrentLate}
            eventGroup
          />
        </Flex>
        <Flex centered onClick={() => toggleHideShow(event)}>
          <Icon
            className={styles.accordianButton}
            style={{ marginRight: 7 }}
          >
            keyboard_arrow_down
          </Icon>
        </Flex>
        <Flex
          style={{ msGridRow: 1, msGridColumn: 1 }}
          centeredVertically
          onClick={() => toggleHideShow(event)}
        >
          <TransportCaption
            date={event.creationTime}
            color="neutral80"
          />
          <TransportCaption
            time={event.creationTime}
            color="neutral80"
          />
          <TransportCaption
            color="neutral80"
            text={
              eventFriendlyNames[event.eventCode] !== undefined
                ? eventFriendlyNames[event.eventCode]
                : event.eventCode
                + (milestoneIndex ? ` ${milestoneIndex}` : '')
                + (event.count ? ` (${event.count})` : '')
            }
          />
        </Flex>

        <Flex
          style={{ msGridRow: 1, msGridColumn: 2 }}
          centeredVertically
          onClick={() => toggleHideShow(event)}
        >
          {(eventFriendlyNames[event.eventCode] === 'In Transit' && !hasEta) ? <TransportCaption color="neutral40" text="No ETA" />
            : (isMilestone && hasEta && (
              <>
                <TransportCaption color="neutral80" date={event.milestone.eta} />
                <TransportCaption color="neutral80" time={event.milestone.eta} />
                <TransportCaption color="neutral80" text="ETA" />
                <TransportCaption
                  diff={getDateDiff(arrivalRequestedTo, event.milestone.eta)}
                />
              </>
            ))}
        </Flex>

        <Flex style={{ msGridRow: 1, msGridColumn: 3 }} centeredVertically>
          {event.xmlMessageBlobId && (
            <Div
              className={styles.transportEvents_fileDownloadContainer}
              onClick={() => onDownloadClick(event.xmlMessageBlobId)}
            >
              <i className="material-icons se-icon se-icon--md">file_download</i>
            </Div>
          )}
        </Flex>
      </Grid>
    )}
    {event.show && (
      <Grid
        className={styles.transportEvents_event}
        fullWidth
        columns="64px 1fr 1fr 1em"
        padding={{ vertical: 'xs' }}
        pointer={isMilestone}
        onClick={isMilestone ? () => onExpandToggle() : undefined}
      >
        <Flex centered>
          {currentSequence ? (
            <SimpleMarker
              label={currentSequence}
              active={isCurrentActive}
              late={isCurrentLate}
            />
          ) : ''}
        </Flex>
        <Flex style={{ msGridRow: 1, msGridColumn: 1 }} centeredVertically>
          <TransportCaption
            date={event.creationTime}
            color={isMilestone ? 'neutral80' : 'neutral40'}
          />
          <TransportCaption
            time={event.creationTime}
            color={isMilestone ? 'neutral80' : 'neutral40'}
          />
          <TransportCaption
            text={
              eventFriendlyNames[event.eventCode] !== undefined
                ? eventFriendlyNames[event.eventCode]
                : event.eventCode
                + (milestoneIndex ? ` ${milestoneIndex}` : '')
                + (event.count ? ` (${event.count})` : '')
            }
            color={isMilestone ? 'neutral80' : 'neutral40'}
          />
        </Flex>

        <Flex style={{ msGridRow: 1, msGridColumn: 2 }} centeredVertically>
          {(eventFriendlyNames[event.eventCode] === 'In Transit' && !hasEta) ? <TransportCaption color="neutral40" text="No ETA" />
            : (isMilestone && hasEta && (
              <>
                <TransportCaption color="neutral80" date={event.milestone.eta} />
                <TransportCaption color="neutral80" time={event.milestone.eta} />
                <TransportCaption color="neutral80" text="ETA" />
                <TransportCaption
                  diff={getDateDiff(arrivalRequestedTo, event.milestone.eta)}
                />
              </>
            ))}
        </Flex>

        <Flex style={{ msGridRow: 1, msGridColumn: 3 }} centeredVertically>
          {event.xmlMessageBlobId && (
            <Div
              className={styles.transportEvents_fileDownloadContainer}
              onClick={() => onDownloadClick(event.xmlMessageBlobId)}
            >
              <i className="material-icons se-icon se-icon--md">file_download</i>
            </Div>
          )}
        </Flex>
      </Grid>
    )}
    {isExpanded && event.show && (
      <Grid fullWidth columns="1fr 1fr" padding={{ top: 's', bottom: 'm' }}>
        {(event.milestone.latitude && event.milestone.longitude) ? (
          <TransportMap
            model={new TransportMapModel({
              isCurrentLate,
              isCurrentActive,
              currentSequence,
              milestones: [event.milestone],
              center: [event.milestone.latitude, event.milestone.longitude],
            })}
            height="200px"
            padding={{ right: 'm' }}
            style={{ msGridRow: 1, msGridColumn: 1 }}
          />
        ) : ''}

        <Div
          padding={{ vertical: 'xs' }}
          style={{ msGridRow: 1, msGridColumn: 2 }}
        >
          {event.milestone.street && <Text>{event.milestone.street}</Text>}
          {event.milestone.city && <Text>{event.milestone.city}</Text>}
          {event.milestone.postalCode && (
            <Text>{event.milestone.postalCode}</Text>
          )}
          {event.milestone.country && (
            <Text padding={{ bottom: 'xs' }}>{event.milestone.country}</Text>
          )}
          <Text>
            {
              (event.milestone.latitude && event.milestone.longitude)
                ? `(${event.milestone.latitude}, ${event.milestone.longitude})`
                : 'Coordinates not available'
            }
          </Text>
        </Div>
      </Grid>
    )}
  </Flex>
);
export default observer(Event);
