/* eslint-disable react/no-array-index-key */
import React from 'react';
import { observer } from 'mobx-react';
import Text from '../../../shared/components/typography/Text';
import Flex from '../../../shared/components/Flex/Flex';
import Div from '../../../shared/components/Div/Div';
import LabelAndValue from './LabelAndValue';
import TransportStatusLabel from '../InternalEventCodeLabel';
import TrackingStatusLabel from '../TrackingStatusLabel';

function TransportDetails({ transportModel, ...props }) {
  const {
    pickupLocations,
    dropoffLocations,
    transportId,
    incoterms,
    incotermsLocation,
    transportModeType,
    vehicle,
    carrier,
    transportPlanner,
    statusMessages,
    loadingAgreement,
    waybills,
    latestEventCode,
    trackingStatus,
  } = transportModel;

  const carrierContacts = statusMessages && statusMessages.length
    ? statusMessages
      .filter((message) => message.contact)
      .map((message) => message.contact)
    : [];

  const carrierContact = carrierContacts && carrierContacts.length
    ? carrierContacts.pop()
    : undefined;

  const totalWeight = waybills && waybills.reduce((total, waybill) => total + waybill.weight, 0);

  return (
    <Flex style={{ flexWrap: 'wrap' }} {...props}>
      <Flex>
        {pickupLocations && pickupLocations.length > 0 && (
          <LabelAndValue label="Pick up location" style={{ minWidth: 300 }}>
            {pickupLocations.map((pickupLocation) => (
              <Div key={pickupLocation.id} margin={{ bottom: 's' }}>
                <Text tagName="p">
                  {pickupLocation.nameAddress.length && `${pickupLocation.nameAddress[0]}, `}
                  {pickupLocation.id}
                </Text>
                {pickupLocation.nameAddress.length > 0 && (
                  <Text tagName="p" style={{ msGridRow: 3, msGridColumn: 1 }}>
                    <span>
                      {pickupLocation.nameAddress[1]}
                      <br />
                    </span>
                    <span>
                      {[...pickupLocation.nameAddress].slice(2, pickupLocation.nameAddress.length).join(', ')}
                    </span>
                  </Text>
                )}
              </Div>
            ))}
          </LabelAndValue>
        )}

        {dropoffLocations && dropoffLocations.length > 0 && (
          <LabelAndValue label="Drop off location" style={{ minWidth: 300 }}>
            {dropoffLocations.map((dropoffLocation) => (
              <Div key={dropoffLocation.id} margin={{ bottom: 's' }}>
                <Text tagName="p">
                  {dropoffLocation.nameAddress.length && `${dropoffLocation.nameAddress[0]}, `}
                  {dropoffLocation.id}
                </Text>
                {dropoffLocation.nameAddress.length > 0 && (
                  <Text tagName="p" style={{ msGridRow: 3, msGridColumn: 1 }}>
                    <span>
                      {dropoffLocation.nameAddress[1]}
                      <br />
                    </span>
                    <span>
                      {[...dropoffLocation.nameAddress].slice(2, dropoffLocation.nameAddress.length).join(', ')}
                    </span>
                  </Text>
                )}
              </Div>
            ))}
          </LabelAndValue>
        )}
      </Flex>

      <Flex style={{ flexWrap: 'wrap' }}>
        <LabelAndValue label="Waybill ID" style={{ minWidth: 300 }}>
          {waybills && waybills.map((waybill, index) => (
            <Text key={`${waybill.id}-${index}`} tagName="p">
              {waybill.id}
            </Text>
          ))}
        </LabelAndValue>

        {carrier && (
          <LabelAndValue label="Carrier" style={{ minWidth: 300 }}>
            {carrier.id}
            <br />
            {carrier.nameAddress && carrier.nameAddress.length > 0 ? carrier.nameAddress[0] : ''}
          </LabelAndValue>
        )}

        {vehicle && (
          <LabelAndValue
            label="Vehicle License Plate Number"
            onChange={(value) => transportModel.setVehicleLicensePlateNumber(value)}
            loading={transportModel.loading}
            style={{ minWidth: 300 }}
          >
            {vehicle.licensePlateNumber}
          </LabelAndValue>
        )}

        {vehicle && (
          <LabelAndValue label="Vehicle Code" style={{ minWidth: 300 }}>{vehicle.code}</LabelAndValue>
        )}

        {loadingAgreement && (
          <LabelAndValue label="Loading Agreement" style={{ minWidth: 300 }}>
            {loadingAgreement}
          </LabelAndValue>
        )}

        {totalWeight > 0 && (
          <LabelAndValue label="Loaded Weight" style={{ minWidth: 300 }}>
            {`${totalWeight} Kg (Gross)`}
          </LabelAndValue>
        )}

        {/* <LabelAndValue label="Issue Event">{loadPlanStatus}</LabelAndValue> */}

        <LabelAndValue label="Incoterms / Location" style={{ minWidth: 300 }}>
          {`${incoterms} / ${incotermsLocation}`}
        </LabelAndValue>

        <LabelAndValue label="Transport Mode Type" style={{ minWidth: 300 }}>
          {`${transportModeType}`}
        </LabelAndValue>

        {/* {carrier && carrier.nameAddress instanceof Array && (
          <LabelAndValue label="Carrier">
            {[carrier.id]
              .concat(carrier.nameAddress.map((value) => value))
              .map((value, index) => (
                <span key={index}>
                  {value}
                  <br />
                </span>
              ))}
          </LabelAndValue>
        )} */}

        {carrierContact && (
          <LabelAndValue label="Carrier Contact" style={{ minWidth: 300 }}>
            {carrierContact.name}
            <br />
            {carrierContact.phone}
            <br />
            {carrierContact.email}
          </LabelAndValue>
        )}

        {transportPlanner && (
          <LabelAndValue label="Transport Planner" margin={null} style={{ minWidth: 300 }}>
            <span>
              {transportPlanner.name}
            </span>
          </LabelAndValue>
        )}

        <LabelAndValue label="Transport ID" style={{ minWidth: 300 }}>{transportId}</LabelAndValue>

        <LabelAndValue label="Transport Status" style={{ minWidth: 300 }}>
          {(latestEventCode && TransportStatusLabel[latestEventCode]) || latestEventCode || '-'}
        </LabelAndValue>

        <LabelAndValue label="Tracking Status" style={{ minWidth: 300 }}>
          {(trackingStatus && TrackingStatusLabel[trackingStatus]) || trackingStatus || '-'}
        </LabelAndValue>

        {dropoffLocations && dropoffLocations.length && (
          <LabelAndValue label="Mill Order / Call Off No / Customer Order / QTY" style={{ minWidth: 300 }}>
            {dropoffLocations.map((location) => (location.orders.map((order, index) => (
              <span key={`${location.id}-${index}`} style={{ fontSize: 12 }}>
                {`${order.number}-${order.lineItemNumber} / ${order.callOffNumber}-${order.callOffLineItemNumber} / ${order.customerOrder} / ${order.numberOfPackages || '-'}`}
                <br />
              </span>
            ))))}
          </LabelAndValue>
        )}
      </Flex>
    </Flex>
  );
}

export default observer(TransportDetails);
