import { action, observable } from 'mobx';
import TransportModel from '../TransportPopup/Transport/TransportModel';
import getTransports from './getTransports';
import getTransport from '../TransportPopup/Transport/getTransport';

export default class TransportsModel {
  @observable transports = [];

  @observable transportsLoadContRef;

  @observable transportsLoadError;

  @observable transportsLoading = false;

  mapResponse = (transportsResponse) => transportsResponse.map(
    (transport) => new TransportModel(transport),
  );

  @action
    loadTransports = async ({
      filters, sorting, overwriteExistingData, transportId,
    }) => {
      if (overwriteExistingData) this.transportsLoadContRef = undefined;

      this.transportsLoadError = undefined;
      this.transportsLoading = true;

      const { callWasSuccessful, response, error } = transportId
        ? await getTransport(transportId)
        : await getTransports({
          contRef: this.transportsLoadContRef,
          filters,
          sorting,
        });

      if (callWasSuccessful && response !== undefined) {
        this.transportsLoadContRef = response.contRef;

        const transportData = response.transports ?? [response];
        this.transports = overwriteExistingData
          ? this.mapResponse(transportData)
          : this.transports.concat(this.mapResponse(transportData));
      } else {
        this.transportsLoadContRef = undefined;
        this.transportsLoadError = JSON.stringify(String(error));
        this.transports = [];
      }

      this.transportsLoading = false;
    };
}
