import callBackend from '../../../shared/methods/callBackend/callBackend';

const setNewLocationPoint = async (transportID, locationId, lat, lng, dropoffOrPickup) => {
  const url = `${process.env.REACT_APP_API_URL}/transport/${transportID}/location/${locationId}`;

  return callBackend({
    url,
    method: 'PUT',
    body: {
      dropoffOrPickup,
      lat,
      lng,
    },
  });
};

export default setNewLocationPoint;
