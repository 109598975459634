import React from 'react';
import { Select } from '@storaensods/seeds-react';
import { components } from 'react-select';

import styles from './FilterForm.module.scss';

const colorOptions = [
  {
    label: 'Pistachio',
    value: '#3CA078',
  },
  {
    label: 'GreenLimon',
    value: '#b2be12',
  },
  {
    label: 'Army',
    value: '#87AA70',
  },
  {
    label: 'BlueViolet',
    value: '#8A2BE2',
  },
  {
    label: 'CadetBlue',
    value: '#5F9EA0',
  },
  {
    label: 'Coral',
    value: '#FF7F50',
  },
  {
    label: 'DarkwingNight',
    value: '#705081',
  },
  {
    label: 'Crimson',
    value: '#DC143C',
  },
  {
    label: 'Copper',
    value: '#B78C46',
  },
  {
    label: 'DarkMagenta',
    value: '#8B008B',
  },
  {
    label: 'DeepPink',
    value: '#FF1493',
  },
  {
    label: 'Gold',
    value: '#FDC80A',
  },
  {
    label: 'OrangeCake',
    value: '#E66E1B',
  },
  {
    label: 'IndianRed',
    value: '#CD5C5C',
  },
];

const ColorOptionItem = ({ children, value, innerProps }) => (
  <div {...innerProps} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <span
      className={styles.optionColor}
      style={{ backgroundColor: value }}
    />
    {children}
  </div>
);

const ColorSingleValue = ({
  children, data, innerProps, ...props
}) => (
  <components.SingleValue {...props}>
    <ColorOptionItem innerProps={innerProps} value={data.value}>
      {children}
    </ColorOptionItem>
  </components.SingleValue>
);

const ColorOption = ({
  children, innerProps, data, ...props
}) => (
  <components.Option {...props} style={{ padding: 0 }}>
    <ColorOptionItem innerProps={innerProps} value={data.value}>
      {children}
    </ColorOptionItem>
  </components.Option>
);

export default function ColorSelect(props) {
  return (
    <Select
      placeholder="Select color"
      options={colorOptions}
      components={{ Option: ColorOption, SingleValue: ColorSingleValue }}
      style={{ maxWidth: 350, marginBottom: '1em' }}
      isClearable
      {...props}
    />
  );
}
