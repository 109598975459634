import { observable, action, computed } from 'mobx';
import * as yup from 'yup';

const emailValidation = yup.string().email();
export default class FilterFormModel {
  @observable filterName = '';

  @observable emails = '';

  @observable isAddToAccessBar = false;

  @observable alertIcon: string | null = null;

  @observable alertColor: string | null = null;

  @action
  setFilterName(name) {
    this.filterName = name;
  }

  @action
  onFilterNameChange(event) {
    this.filterName = event.target.value;
  }

  @action
  setEmails(value) {
    this.emails = value;
  }

  @action
  onEmailsChange(event) {
    this.emails = event.target.value;
  }

  @action
  setAddToAccessBarCheckBox(value) {
    this.isAddToAccessBar = value;
  }

  @action
  setAlertIcon(value) {
    this.alertIcon = value;
  }

  @action
  setAlertColor(value) {
    this.alertColor = value;
  }

  @action
  reset() {
    this.filterName = '';
    this.emails = '';
    this.isAddToAccessBar = false;
    this.alertIcon = null;
    this.alertColor = null;
  }

  @computed
  get error() {
    const totalErrors = {} as any;

    if (!this.filterName || this.filterName.length === 0) {
      totalErrors.filterName = 'Required';
    }
    const emailsAreInvalid = this.emails && this.emails.split(';').some((email) => !emailValidation.isValidSync(email));
    if (emailsAreInvalid) {
      totalErrors.emails = 'Invalid email(s). Separate multiple emails with ;';
    }

    return Object.keys(totalErrors).length ? totalErrors : null;
  }
}
