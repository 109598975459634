import React from 'react';
import { observer, useObserver } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppContext from './AppContext';
import AppModel from './AppModel';
import Div from './shared/components/Div/Div';
import TransportPopup, { useTransportPopup } from './TransportPopup/TransportPopup';
import addDecorators from './shared/methods/addDecorators/addDecorators';
import styles from './App.module.scss';
import withModel from './shared/methods/withModel/withModel';
import withRouter from './shared/methods/withRouter/withRouter';
import FileUpload from './FileUpload/FileUpload';
import uploadWaybill from './FileUpload/uploadWaybill';
import uploadLoadplanPM from './FileUpload/uploadLoadplanPM';
import uploadBM from './FileUpload/uploadBM';
import DialogsMainContainer from './shared/components/Dialog/DialogsMainContainer';
import TransportListPage from './pages/TransportListPage';
import TransportPage from './pages/TransportPage';
import TrackingRulesPage from './pages/TrackingRulesPage';
import FiltersAndAlertsPage from './pages/FiltersAndAlertsPage';
import Header from './shared/components/Header/Header';
import SideMenu from './shared/components/Menu/Menu';
import Page from './pages/Page/Page';
import PageWrapper from './pages/Page/PageWrapper';
import useAppLinks from './useAppLinks';
import AuthenticationChecker from './AuthenticationChecker';
import LocationsPage from './pages/LocationsPage';
import OpeningHoursPage from './pages/OpeningHoursPage';

import './momentLocales';

import 'react-toastify/dist/ReactToastify.css';

function App(props) {
  const {
    filtersModel,
    searchModel,
    transportsModel,
    transportPopupModel,
    TrackingRulesModel,
    quickAccessBarModel,
    locationModel,
    openingHoursModel,
    sortingModel,
  } = props;

  const {
    loadTransports,
  } = transportsModel;

  transportPopupModel.onTransportIsPinnedChanged = () => {
    const { filters } = filtersModel;

    loadTransports({ overwriteExistingData: true, filters, sorting: sortingModel });
  };
  sortingModel.onSortingValuesAreChanged = () => {
    const { filters } = filtersModel;

    loadTransports({ overwriteExistingData: true, filters, sorting: sortingModel });
  };

  const onSearchResultSelected = async (transportId) => {
    await loadTransports({
      overwriteExistingData: true, filters: null, sorting: sortingModel, transportId,
    });
  };

  const {
    open: triggerTransportPopupOpen,
  } = useTransportPopup();

  /**
   * TODO
   * AppContext should be the provider for all models
   * This will be changed gradually as more and more feature or micro factoring happning
   */
  const store = useObserver(() => ({
    quickAccessBarModel,
    filtersModel,
    transportsModel,
    sortingModel,
  }));

  return (
    <DialogsMainContainer>
      <AppContext.Provider value={store}>
        <Div>
          <SideMenu menuItem={useAppLinks()}>
            <Header
              searchModel={searchModel}
              onTransportSearch={onSearchResultSelected}
            />
            <Div className={styles.app}>
              <AuthenticationChecker>
                {(userModel) => (
                  <PageWrapper>
                    <Switch>
                      <Route exact path={['/', '/transports']}>
                        <TransportListPage
                          filtersModel={filtersModel}
                          searchModel={searchModel}
                          transportsModel={transportsModel}
                          sortingModel={sortingModel}
                          openTransport={triggerTransportPopupOpen}
                        />
                      </Route>
                      <Route path="/transport/:transportId">
                        <TransportPage
                          transportPopupModel={transportPopupModel}
                        />
                      </Route>
                      <Route path="/tracking-rules">
                        <TrackingRulesPage model={TrackingRulesModel} />
                      </Route>
                      <Route path="/locations">
                        <LocationsPage model={locationModel} />
                      </Route>
                      <Route path="/openinghours">
                        <OpeningHoursPage model={openingHoursModel} />
                      </Route>
                      <Route path="/filter">
                        <FiltersAndAlertsPage />
                      </Route>

                      {userModel.canUploadTestMessages && (
                        <>
                          <Route path="/upload-message">
                            <Page title="Upload message">
                              <FileUpload message="WP/PM waybill" onChange={uploadWaybill} />
                              <FileUpload message="PM loadplan" onChange={uploadLoadplanPM} />
                              <FileUpload message="BM loadplan/waybill" onChange={uploadBM} />
                            </Page>
                          </Route>
                        </>
                      )}
                      <Redirect to="/" />
                    </Switch>
                  </PageWrapper>
                )}
              </AuthenticationChecker>
            </Div>
          </SideMenu>
          <TransportPopup model={transportPopupModel} />
          <ToastContainer hideProgressBar />
        </Div>
      </AppContext.Provider>
    </DialogsMainContainer>
  );
}

export default addDecorators(
  observer,
  withRouter,
  withModel(
    {
      Model: AppModel,
      props: [
        'filtersModel',
        'searchModel',
        'transportsModel',
        'transportPopupModel',
        'TrackingRulesModel',
        'quickAccessBarModel',
        'locationModel',
        'openingHoursModel',
        'sortingModel',
      ],
    },
  ),
).to(App);
