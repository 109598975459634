import React from 'react';
import { DataTable, Icon, Spinner } from '@storaensods/seeds-react';
import Div from '../shared/components/Div/Div';
import Flex from '../shared/components/Flex/Flex';

export default function LocationTable({
  data, onEdit, onRemove, loading,
}) {
  const columns = [
    {
      header: 'LocationId',
      key: 'locationId',
    },
    {
      header: 'Latitude',
      key: 'latitude',
    },
    {
      header: 'Longitude',
      key: 'longitude',
    },
    {
      header: 'GeofenceRadius',
      key: 'geofenceRadius',
    },
    {
      header: 'UpdatedBy',
      key: 'updatedBy',
    },
    {
      content: (value, item) => (
        <Icon onClick={() => { onEdit(item); }}>
          edit
        </Icon>
      ),
      header: '',
      key: 'edit',
      sorting: false,
      width: '30px',
    },
    {
      content: (value, item) => (
        <Icon onClick={() => { onRemove(item.locationId); }}>
          delete
        </Icon>
      ),
      header: '',
      key: 'delete',
      sorting: false,
      width: '30px',
    },
  ];

  return (
    <Div relative style={{ maxWidth: 1024 }}>
      {loading && (
        <Flex
          absolute
          centered
          fullWidth
          fullHeight
          style={{ backgroundColor: '#ffffffaa', zIndex: 1 }}
        >
          <Spinner size="lg" />
        </Flex>
      )}
      <DataTable
        columns={columns}
        data={data}
      />
    </Div>
  );
}
