import React from 'react';
import { observer } from 'mobx-react';
import Checkbox from '../../shared/components/Checkbox/Checkbox';
import Text from '../../shared/components/typography/Text';
import Grid from '../../shared/components/Grid/Grid';
import DatePicker from '../../shared/components/DatePicker/DatePicker';
import Div from '../../shared/components/Div/Div';
import Flex from '../../shared/components/Flex/Flex';
import { DEFAULT_TIME_FORMAT, toLocaleDateTimeFormat } from './TimeRangeFormat';

function TimeRangeFilters({ model, ...props }) {
  const {
    departureFromModel,
    departureToModel,
    useCurrentDateForDepartureModel,
    arrivalFromModel,
    arrivalToModel,
    useCurrentDateForArrivalModel,
  } = model;

  return (
    <Grid columns="1fr 1fr" fullWidth {...props}>
      <Text
        captionSmall
        bold
        padding={{ bottom: 's' }}
        color="neutral100"
        style={{ msGridRow: 1, msGridColumn: 1 }}
      >
        Pick up RTA
      </Text>

      <Text
        captionSmall
        bold
        padding={{ bottom: 's' }}
        color="neutral100"
        margin={{ left: 'xs' }}
        style={{ msGridRow: 1, msGridColumn: 2 }}
      >
        Drop off RTA
      </Text>

      <Grid columns="1fr 1fr" style={{ msGridRow: 2, msGridColumn: 1 }}>
        <Div style={{ msGridRow: 1, msGridColumn: 1 }}>
          <DatePicker
            isClearable
            selected={departureFromModel.value}
            disabled={useCurrentDateForDepartureModel}
            selectsStart
            startDate={departureFromModel.value}
            endDate={departureToModel.value}
            maxDate={departureToModel.value && departureToModel.value}
            timeFormat={DEFAULT_TIME_FORMAT}
            dateFormat={toLocaleDateTimeFormat()}
            placeholderText="Select From Date..."
            onChange={(date) => departureFromModel.setValue(date)}
            onClear={() => departureFromModel.setValue(undefined)}
          />
        </Div>

        <Div margin={{ left: 'xxs' }} style={{ msGridRow: 1, msGridColumn: 2 }}>
          <DatePicker
            isClearable
            selected={departureToModel.value}
            disabled={useCurrentDateForDepartureModel}
            selectsEnd
            startDate={departureFromModel.value}
            endDate={departureToModel.value}
            minDate={departureFromModel.value && departureFromModel.value}
            timeFormat={DEFAULT_TIME_FORMAT}
            dateFormat={toLocaleDateTimeFormat()}
            placeholderText="Select To Date..."
            onChange={(date) => departureToModel.setValue(date)}
            onClear={() => departureToModel.setValue(undefined)}
          />
        </Div>
        <Flex centeredVertically margin={{ vertical: 's' }}>
          <Checkbox
            id="useCurrentDateForDeparture"
            onChange={() => { model.toggleDepartureCheckbox(); }}
            checked={useCurrentDateForDepartureModel}
          >
            Use current date for Pick up RTA
          </Checkbox>
        </Flex>
      </Grid>

      <Grid
        columns="1fr 1fr"
        margin={{ left: 'xs' }}
        style={{ msGridRow: 2, msGridColumn: 2 }}
      >
        <Div style={{ msGridRow: 1, msGridColumn: 1 }}>
          <DatePicker
            isClearable
            selected={arrivalFromModel.value}
            disabled={useCurrentDateForArrivalModel}
            selectsStart
            startDate={arrivalFromModel.value}
            endDate={arrivalToModel.value}
            maxDate={arrivalToModel.value && arrivalToModel.value}
            timeFormat={DEFAULT_TIME_FORMAT}
            dateFormat={toLocaleDateTimeFormat()}
            placeholderText="Select From Date..."
            onChange={(date) => arrivalFromModel.setValue(date)}
            onClear={() => arrivalFromModel.setValue(undefined)}
          />
        </Div>

        <Div margin={{ left: 'xxs' }} style={{ msGridRow: 1, msGridColumn: 2 }}>
          <DatePicker
            isClearable
            selected={arrivalToModel.value}
            disabled={useCurrentDateForArrivalModel}
            selectsEnd
            startDate={arrivalFromModel.value}
            endDate={arrivalToModel.value}
            minDate={arrivalFromModel.value && arrivalFromModel.value}
            timeFormat={DEFAULT_TIME_FORMAT}
            dateFormat={toLocaleDateTimeFormat()}
            placeholderText="Select To Date..."
            onChange={(date) => arrivalToModel.setValue(date)}
            onClear={() => arrivalToModel.setValue(undefined)}
          />
        </Div>
        <Flex centeredVertically margin={{ vertical: 's' }}>
          <Checkbox
            id="useCurrentDateForArrival"
            onChange={() => { model.toggleArrivalCheckbox(); }}
            checked={useCurrentDateForArrivalModel}
          >
            Use current date for Drop off RTA
          </Checkbox>
        </Flex>
      </Grid>
    </Grid>
  );
}

export default observer(TimeRangeFilters);
