import React from 'react';
import { Tag } from '@storaensods/seeds-react';
import TransportCaption from '../../TransportCaption/TransportCaption';
import Text from '../../shared/components/typography/Text';
import Div from '../../shared/components/Div/Div';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import getLatenessStatusDifferenceLabel from '../LatenessStatusDifferenceCell/getLatenessStatusDifferenceLabel';
import EventCodeEnum, { EventCodeEnumOrdered } from '../../TransportPopup/Transport/InternalEventCodeEnum';

function PickupCell({ data, row, value }) {
  let info = value;
  if (!info) {
    const { index: currentRow } = row;
    info = data[currentRow];
  }
  const {
    origin,
    latenessStatusDifference,
    latestEventCode,
  } = info;

  let lateness;
  let latenessColor = 'neutral40';
  let dateType;
  let dateTypeColor = 'attention';
  let dateColor = 'neutral80';
  let dateTime;
  let tooltip = '';

  switch (latestEventCode) {
    case EventCodeEnum.PLANNED:
      tooltip = 'Planned Time of Departure';
      dateType = 'PTD';
      dateTime = origin.loadingPlannedTo;
      dateColor = 'neutral40';
      dateTypeColor = 'primary';
      break;
    case EventCodeEnum.IN_TRANSIT_PICK_UP:
      tooltip = 'Predicted Time of Arrival';
      dateType = 'PTA';
      dateTime = origin.arrivalEstimated;
      dateColor = 'neutral80';
      lateness = getLatenessStatusDifferenceLabel(latenessStatusDifference);
      latenessColor = lateness && lateness[0] === '+' ? 'red100' : 'neutral40';
      break;
    case EventCodeEnum.ARRIVED_PICK_UP:
      tooltip = 'Actual Time of Arrival';
      dateType = 'ATA';
      dateTime = origin.arrivalActual;
      dateColor = 'neutral80';
      lateness = getLatenessStatusDifferenceLabel(latenessStatusDifference);
      latenessColor = lateness && lateness[0] === '+' ? 'red100' : 'neutral40';
      break;
    case EventCodeEnum.CANCELLED:
      dateType = 'Canceled';
      dateTypeColor = 'negative';
      break;
    default:
      if (EventCodeEnumOrdered[latestEventCode] >= EventCodeEnumOrdered.IN_TRANSIT_DROP_OFF) {
        tooltip = 'Actual Time of Departure';
        dateType = 'ATD';
        dateTime = origin.departureActual;
        dateColor = 'neutral80';
        dateTypeColor = 'positive';
      }
      break;
  }

  return (
    <Div>
      <Text captionSmall padding={{ vertical: 'xxxs' }}>
        {origin.id}
      </Text>
      {lateness && (
        <Text captionSmall padding={{ horizontal: 'xxs', vertical: 'xxxs' }} color={latenessColor}>
          {lateness}
        </Text>
      )}
      <br />
      <Tooltip tooltip={tooltip}>
        <Tag type={dateTypeColor}>{dateType}</Tag>
      </Tooltip>
      <TransportCaption date={dateTime} color={dateColor} />
      <TransportCaption time={dateTime} color={dateColor} />
    </Div>
  );
}

export default PickupCell;
