import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import {
  Input, Button, ButtonGroup, Spinner,
} from '@storaensods/seeds-react';
import IconSelect from './IconSelectForFilterForm';
import ColorSelect from './ColorSelectForFilterForm';
import Div from '../shared/components/Div/Div';
import Flex from '../shared/components/Flex/Flex';
import Filters from '../Filters/Filters';
import Accordion from '../shared/components/Accordion/Accordion';
import Checkbox from '../shared/components/Checkbox/Checkbox';

import useUrlLocationQuery from '../shared/methods/useUrlLocationQuery/useUrlLocationQuery';
import AppContext from '../AppContext';

import styles from './FilterForm.module.scss';

function FilterForm({ model, filtersModel: localFiltersModel }) {
  const { filtersModel, quickAccessBarModel } = useContext(AppContext);

  const { setQuery, getQuery } = useUrlLocationQuery();
  const filternameFromUrl = getQuery('filtername') || '';

  useEffect(() => {
    if (filtersModel.optionsLoaded) {
      const loadedFilterOptions = filtersModel.getFilterOptions();
      localFiltersModel.copyFilterOptions(loadedFilterOptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtersModel.optionsLoaded, // when options are loaded
  ]);

  useEffect(() => {
    if (localFiltersModel.optionsLoaded) {
      localFiltersModel.setSavedFiltersObj(filtersModel.savedFilters);

      if (filternameFromUrl) {
        localFiltersModel.setSavedFilterValuesByName(filternameFromUrl);
        const {
          props: {
            alertIcon,
            alertColor,
            isAddToAccessBar,
            emails,
          },
        } = localFiltersModel.getSelectedSavedFilterValuesAndProps();

        model.setFilterName(filternameFromUrl);
        model.setAlertIcon(alertIcon);
        model.setAlertColor(alertColor);
        model.setAddToAccessBarCheckBox(isAddToAccessBar);
        model.setEmails(emails);
      }

      if (!filternameFromUrl) {
        localFiltersModel.unsetFilterValuesAndProps();
        localFiltersModel.unsetSavedFilterSelect();
        model.reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filternameFromUrl,
    localFiltersModel.optionsLoaded, // when options are copied
    filtersModel.savedFilters, // when saved filters are loaded
  ]);

  const onCancel = () => {
    setQuery({ filtername: null });
  };

  const onSave = () => {
    if (!model.error) {
      localFiltersModel.saveFilter(model);
      // so the main source of filters be updated
      filtersModel.setSavedFiltersObj(localFiltersModel.savedFilters);
      quickAccessBarModel.updateAlerts(localFiltersModel.listOfFilterNamesAndValues);
      toast('Filter saved!');
    } else {
      toast.error('Please check filter name.');
    }
  };

  return (
    <Div data-testid="saving-filter-form">
      <Flex centeredVertically margin={{ bottom: 'm' }}>
        <Div style={{ width: 350 }}>
          <Input
            label="Filter name"
            size="sm"
            value={model.filterName}
            onChange={(ev) => { model.onFilterNameChange(ev); }}
            helpText={model.error && model.error.filterName}
            invalid={model.error && !!model.error.filterName}
          />
        </Div>
        {filtersModel.filterOptionsLoading && (
          <Div margin={{ left: 'm' }}>
            <Spinner />
          </Div>
        )}
      </Flex>

      <Filters model={localFiltersModel} noSavingControls />

      <Accordion title="Alert">
        <Div margin={{ bottom: 's' }}>
          <Input
            label="Send emails to"
            size="sm"
            value={model.emails}
            onChange={(e) => { model.onEmailsChange(e); }}
            style={{ maxWidth: 350, marginBottom: '1em' }}
            helpText={model.error && model.error.emails}
            invalid={model.error && !!model.error.emails}
          />
        </Div>
        <Div style={{ width: 350 }} margin={{ bottom: 's' }}>
          <Checkbox
            id="addToQuickAccessBar"
            onChange={() => { model.setAddToAccessBarCheckBox(!model.isAddToAccessBar); }}
            checked={model.isAddToAccessBar}
          >
            Add to Quick-Access bar
          </Checkbox>

          <Div margin={{ top: 's', bottom: 's' }}>
            <IconSelect
              value={model.alertIcon}
              onChange={(value) => {
                model.setAlertIcon(value);
              }}
            />
          </Div>
          <Div>
            <ColorSelect
              value={model.alertColor}
              onChange={(value) => {
                model.setAlertColor(value);
              }}
            />
          </Div>
        </Div>
      </Accordion>

      <ButtonGroup className={styles.formButtons}>
        <Button icon="save" onClick={onSave}>Save</Button>
        <Button type="secondary" onClick={onCancel}>Cancel</Button>
      </ButtonGroup>

      {/* Padding to show the last accordion on scrolling */}
      <Div style={{ height: 100 }} />
    </Div>
  );
}

export default observer(FilterForm);
