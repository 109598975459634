import React from 'react';
import { observer } from 'mobx-react';
import { Icon, Spinner, Button } from '@storaensods/seeds-react';
import Div from '../../../shared/components/Div/Div';
import styles from './TransportMap.module.scss';
import Flex from '../../../shared/components/Flex/Flex';

import Tooltip from '../../../shared/components/Tooltip/Tooltip';

function TransportMapCustomController({ mapModel }) {
  const {
    canEdit,
    isUpdating,
    dropoffOrPickup,
  } = mapModel;

  return (
    <Div className={styles.mapButtonContainer}>
      <Flex column="true">
        <Div>
          <Flex row="true" spaceBetween>
            <Div
              className={`${styles.mapButton} ${!canEdit && styles.disabled}`}
              onClick={() => {
                mapModel.resetLocationCoordinates();
              }}
            >
              <Tooltip placement="top" tooltip="Reset selected location update">
                <Icon>loop</Icon>
              </Tooltip>
            </Div>

            <Div
              className={`${styles.mapButton} ${!canEdit && styles.disabled}`}
              onClick={() => {
                mapModel.fetchCoordinatesFromMapService();
              }}
            >
              <Tooltip placement="top" tooltip="Fetch coordinates from MapService API">
                <Icon>my_location</Icon>
              </Tooltip>
            </Div>

            {isUpdating ? (
              <Div className={styles.mapButton}>
                <Spinner style={{ fontSize: 20 }} />
              </Div>
            ) : (
              <Div
                className={styles.mapButton}
                onClick={() => {
                  mapModel.toggleEdit();
                }}
              >
                <Tooltip placement="top" tooltip="Update location coordinates">
                  {canEdit ? (
                    <Icon>location_searching</Icon>
                  ) : (
                    <Icon>location_disabled</Icon>
                  )}
                </Tooltip>
              </Div>
            )}
          </Flex>
        </Div>
        {canEdit ? (
          <Div>
            <Flex column="true">
              <Button
                onClick={() => {
                  mapModel.selectedLocation('dropoff');
                }}
                className={`${dropoffOrPickup === 'dropoff' && styles.selectedLocation}`}
                type="secondary"
              >
                <Icon
                  style={{ marginRight: 7 }}
                >
                  flag
                </Icon>
                Drop-off
              </Button>
              <Button
                onClick={() => {
                  mapModel.selectedLocation('pickup');
                }}
                className={`${dropoffOrPickup === 'pickup' && styles.selectedLocation}`}
                type="secondary"
              >
                <Icon
                  style={{ marginRight: 7 }}
                >
                  home
                </Icon>
                Pick-up
              </Button>
            </Flex>
          </Div>
        ) : (
          null
        )}
      </Flex>
    </Div>
  );
}

export default observer(TransportMapCustomController);
