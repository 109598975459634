import { computed } from 'mobx';
import SelectModel from '../../shared/components/Select/SelectModel';
import EventCodeEnum from '../../TransportPopup/Transport/InternalEventCodeLabel';

export default class EventFiltersModel {
  eventModel = new SelectModel();

  @computed
  get filters() {
    return {
      ...(this.eventModel.value && {
        latestEventCode: this.eventModel.getValue(),
      }),
    };
  }

  @computed
  get tags() {
    return {
      latestEventCode: {
        list: this.eventModel.getLabelAndValue(),
        remove: (value) => { this.eventModel.remove(value); },
      },
    };
  }

  setFilterOptions() {
    const eventOptions = Object.keys(EventCodeEnum).map((key) => ({
      value: key,
      label: EventCodeEnum[key],
    }));

    this.eventModel.setOptions(eventOptions);
  }

  setFilterValues = (filterValues) => {
    if (filterValues.latestEventCode) {
      this.eventModel.setValue(filterValues.latestEventCode);
    } else {
      this.eventModel.setValue(null);
    }
  };
}
