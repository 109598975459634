import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import getOpeningHours from './getOpeningHours';
import postOpeningHours from './postOpeningHours';
import putOpeningHours from './putOpeningHours';
import deleteOpeningHours from './deleteOpeningHours';

const ERROR_MESSAGE = 'Request failed, please try again later';

export const dayOfWeekOptions = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 0, label: 'Sunday' },
];

export type OpeningHoursListItem = {
  id: number,
  locationId: string,
  close: string,
  dayOfWeek: number,
  date: Date,
  timeZoneId: string,
};

export default class OpeningHoursModel {
  @observable list: Array<OpeningHoursListItem> = [];

  @observable loading = false;

  @action
  async addOpeningHoursModel(values) {
    this.loading = true;
    const { callWasSuccessful, response } = await postOpeningHours(values);
    if (callWasSuccessful && response !== undefined) {
      this.list.push(response as OpeningHoursListItem);
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loading = false;
  }

  @action
  async remove(id) {
    this.loading = true;
    const { callWasSuccessful } = await deleteOpeningHours(id);
    if (callWasSuccessful) {
      this.list = this.list.filter(({ id: itemId }) => itemId !== id);
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loading = false;
  }

  @action
  async updateOpeningHoursModel(values) {
    this.loading = true;
    const { callWasSuccessful } = await putOpeningHours(values.id, values);
    if (callWasSuccessful) {
      const index = this.list.findIndex(({ id }) => values.id === id);
      if (index > -1) {
        this.list[index] = values;
      }
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loading = false;
  }

  @action
  async loadOpeningHours() {
    this.loading = true;
    const { callWasSuccessful, response } = await getOpeningHours();
    if (callWasSuccessful) {
      this.list = response;
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loading = false;
  }
}
