import { computed } from 'mobx';
import SelectModel from '../../shared/components/Select/SelectModel';
import TrackingStatusLabel from '../../TransportPopup/Transport/TrackingStatusLabel';

export default class TrackingFilterModel {
  trackingFilterSelectModel = new SelectModel();

  @computed
  get filters() {
    return {
      ...(this.trackingFilterSelectModel.value && {
        trackingStatus: this.trackingFilterSelectModel.getValue(),
      }),
    };
  }

  @computed
  get tags() {
    return {
      trackingStatus: {
        list: this.trackingFilterSelectModel.getLabelAndValue(),
        remove: (value) => { this.trackingFilterSelectModel.remove(value); },
      },
    };
  }

  setFilterOptions() {
    const options = Object.keys(TrackingStatusLabel).map((key) => ({
      value: key,
      label: TrackingStatusLabel[key],
    }));

    this.trackingFilterSelectModel.setOptions(options);
  }

  setFilterValues = (filterValues) => {
    if (filterValues.trackingStatus) {
      this.trackingFilterSelectModel.setValue(filterValues.trackingStatus);
    } else {
      this.trackingFilterSelectModel.setValue(null);
    }
  };
}
