import React from 'react';
import Flex from '../../shared/components/Flex/Flex';
import PinIcon from '../../shared/components/PinIcon/PinIcon';

function PinIconCell({ value: isPinned }) {
  return (
    isPinned ? (
      <Flex centered style={{ minWidth: 24, position: 'relative' }}>
        <PinIcon
          isPinned={isPinned}
        />
      </Flex>
    ) : null
  );
}

export default PinIconCell;
