/* eslint-disable max-len */
import { action, decorate, observable } from 'mobx';
import moment from 'moment/moment';
import TransportStage from './TransportStage';
import getScheduleStatus from './getScheduleStatus';
import getDateDiff from '../../shared/methods/getDateDiff/getDateDiff';

class ContainerModel {
  arrivalActual;

  arrivalEstimated;

  arrivalRequested;

  arrivalRequestedVsActual;

  arrivalRequestedVsEstimated;

  carrierId;

  comments;

  departureActual;

  departurePlanned;

  departurePlannedVsActual;

  destination;

  hasArrived;

  hasDeparted;

  loadedWeight;

  loadPlanStatus;

  milestones;

  numberOfPackages;

  origin;

  scheduleStatus;

  stage;

  statusMessages;

  containerId;

  orders;

  filterEventsBySource;

  constructor(values) {
    if (values) this.setValues(values);
  }

  setStatusMessagesAndMilestones(trackerNameAndId) {
    this.statusMessages.forEach((statusMessage) => {
      // eslint-disable-next-line no-param-reassign
      statusMessage.showInUI = trackerNameAndId === null || (statusMessage.eventCode !== 'ETA' && statusMessage.eventCode !== 'Arrived')
        || (statusMessage.trackerName === trackerNameAndId.trackerName && statusMessage.trackerId === trackerNameAndId.trackerId);
    });

    this.statusMessages = this.statusMessages.slice().sort((eventA, eventB) => (moment(eventA.creationTime).isBefore(eventB.creationTime)
      ? -1
      : 1));

    // Filter milestones for easy access. Take into account all messages that have milestone set
    // (Can be ETA or Arrived or Loaded too)
    this.milestones = this.statusMessages
      .filter((message) => message.showInUI && message.milestone && message.milestone.latitude && message.milestone.longitude)
      .map((milestoneMessage) => ({
        ...milestoneMessage.milestone,
        eventCode: milestoneMessage.eventCode,
      }));
  }

  setValues(values) {
    Object.assign(this, values);

    if (this.statusMessages) {
      // Set requested vs estimated arrival and arrival status for each milestone
      this.statusMessages = this.statusMessages.map((statusMessage) => {
        if (statusMessage.milestone) {
          const arrivalRequestedVsEstimated = getDateDiff(
            this.arrivalRequested,
            statusMessage.milestone.eta,
          );

          Object.assign(statusMessage.milestone, {
            arrivalRequestedVsEstimated,
            scheduleStatus: getScheduleStatus(arrivalRequestedVsEstimated),
          });
        }
        return statusMessage;
      });

      // Sort events, no tracker name at this point (= show all)
      this.setStatusMessagesAndMilestones(null);

      const trackerNameAndIdConcatenated = this.statusMessages.filter((message) => message.trackerName).map((message) => `${message.trackerName} - ${message.trackerId}`);

      const uniqueTrackerNameAndIdCombos = new Set(trackerNameAndIdConcatenated);

      this.uniqueTrackerNames = [...uniqueTrackerNameAndIdCombos].map((trackerNameAndId) => ({
        trackerName: trackerNameAndId.split(' - ')[0],
        trackerId: trackerNameAndId.split(' - ')[1],
      }));
    }

    this.filterEventsBySource = (trackerNameAndId) => {
      this.setStatusMessagesAndMilestones(trackerNameAndId);
    };

    // Set arrivalEstimated from last milestone if not defined otherwise
    this.arrivalEstimated = !this.arrivalEstimated && this.milestones && this.milestones.length
      ? this.milestones[this.milestones.length - 1].eta
      : this.arrivalEstimated;

    // Set planned vs actual departure time
    this.departurePlannedVsActual = this.departureActual
      ? getDateDiff(this.departurePlanned, this.departureActual)
      : null;

    // Set requested vs estimated arrival time and arrival status
    this.arrivalRequestedVsEstimated = this.arrivalEstimated
      ? getDateDiff(this.arrivalRequested, this.arrivalEstimated)
      : null;
    this.scheduleStatus = this.arrivalEstimated
      ? getScheduleStatus(this.arrivalRequestedVsEstimated)
      : null;

    // Set requested vs actual arrival time and arrival status
    this.arrivalRequestedVsActual = this.arrivalActual
      ? getDateDiff(this.arrivalRequested, this.arrivalActual)
      : null;
    this.scheduleStatus = this.arrivalActual
      ? getScheduleStatus(this.arrivalRequestedVsActual)
      : this.scheduleStatus;

    this.hasDeparted = this.stage === TransportStage.IN_TRANSIT
      || this.stage === TransportStage.ARRIVED;
    this.hasArrived = this.stage === TransportStage.ARRIVED;
  }
}

export default decorate(ContainerModel, {
  arrivalActual: observable,
  arrivalEstimated: observable,
  arrivalRequested: observable,
  arrivalRequestedVsActual: observable,
  arrivalRequestedVsEstimated: observable,
  carrierId: observable,
  comments: observable,
  departureActual: observable,
  departurePlanned: observable,
  departurePlannedVsActual: observable,
  destination: observable,
  hasArrived: observable,
  hasDeparted: observable,
  loadedWeight: observable,
  loadPlanStatus: observable,
  milestones: observable,
  numberOfPackages: observable,
  origin: observable,
  scheduleStatus: observable,
  stage: observable,
  orders: observable,
  statusMessages: observable,
  containerId: observable,
  setValues: action,
  filterEventsBySource: action,
});
