import { useMemo, useContext, useEffect } from 'react';
import {
  useTable,
  useExpanded,
  useColumnOrder,
  useResizeColumns,
} from 'react-table';
import getColumns from './getColumns';
import AppContext from '../AppContext';

export default function useTransportsTable() {
  const { transportsModel, sortingModel } = useContext(AppContext);
  const { transports } = transportsModel;

  const hiddenColumns = JSON.parse(localStorage.getItem('hiddenColumns')) || [];
  const columnsOrder = JSON.parse(localStorage.getItem('columnsOrder')) || [];

  const table = useTable(
    {
      columns: useMemo(() => getColumns(sortingModel),
      // eslint-disable-next-line react-hooks/exhaustive-deps
        [sortingModel]),
      data: useMemo(() => transports, [transports]),
      getSubRows: (row) => row.transportErrors || [],
      expandSubRows: false,
      autoResetExpanded: false,
    },
    useExpanded,
    useColumnOrder,
    useResizeColumns,
  );

  useEffect(() => {
    table.setHiddenColumns(hiddenColumns);
    table.setColumnOrder(columnsOrder);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return table;
}
