/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react';
import React from 'react';
import Div from '../../shared/components/Div/Div';
import Flex from '../../shared/components/Flex/Flex';
import Grid from '../../shared/components/Grid/Grid';
import TabMenu from '../../shared/components/TabMenu/TabMenu';
import TransportComments from './TransportComments/TransportComments';
import TransportDepartureAndArrival from './TransportDepartureAndArrival/TransportDepartureAndArrival';
import ContainerDetails from './ContainerDetails/ContainerDetails';
import TransportEvents from './TransportEvents/TransportEvents';
import TransportMap from './TransportMap/TransportMap';
import TransportOriginAndDestination from './TransportOriginAndDestination/TransportOriginAndDestination';
// import colors from '../../shared/styles/colors.scss';
import colors from '../../shared/styles/Colors.module.scss';
import styles from './Transport.module.scss';
import CloseButton from '../../shared/components/CloseButton/CloseButton';
import TransportStage from './TransportStage';

function Container({ transportPopupModel, onCloseButtonClick, ...props }) {
  const {
    transportModel,
    containerModel,
    tabMenuModel,
    expandedEvents,
    transportCommentsModel,
  } = transportPopupModel;

  const {
    milestones, stage, origin, destination,
  } = transportModel;

  const cornerFoldColor = stage === TransportStage.SCHEDULED
    ? colors.neutral30
    : stage === TransportStage.IN_TRANSIT
      ? colors.blue100
      : colors.neutral40;

  return (
    <Grid className={styles.transport} margin={{ vertical: 'l' }} {...props}>
      <Flex style={{ msGridRow: 1, msGridColumn: 1 }}>
        <Div
          className={styles.transport_cornerFold}
          style={{
            borderBottomColor: cornerFoldColor,
          }}
        />
        <Div
          backgroundColor="neutral0"
          style={{ width: '100%', height: '100%' }}
        />
      </Flex>

      <Flex
        relative
        alignBottom
        backgroundColor="neutral0"
        padding={{ horizontal: 'l', top: 'l', bottom: 'm' }}
        style={{ msGridRow: 1, msGridColumn: 2 }}
      >
        <TransportOriginAndDestination transportModel={transportModel} />

        <CloseButton
          absolute
          style={{ top: 0, right: 0 }}
          padding={{ size: 'xl' }}
          onClick={() => onCloseButtonClick()}
        />
      </Flex>

      <Div
        shownResponsive={{ widerThan: 'm' }}
        backgroundColor="neutral10"
        padding={{ horizontal: 'l', vertical: 'l' }}
        style={{ msGridRow: 2, msGridColumn: 1 }}
      >
        <ContainerDetails transportModel={transportModel} containerModel={containerModel} />
      </Div>

      <Div
        backgroundColor="neutral0"
        padding={{ horizontal: 'l', vertical: 'l' }}
        style={{ msGridRow: 2, msGridColumn: 2 }}
      >
        <TransportDepartureAndArrival
          transportModel={containerModel}
          padding={{ bottom: 'xl' }}
        />

        <TabMenu
          model={tabMenuModel}
          margin={{ bottom: 'l' }}
          onChange={(selectedID) => tabMenuModel.setSelectedID(selectedID)}
        />

        <ContainerDetails
          shown={tabMenuModel.selectedID === 'details'}
          transportModel={transportModel}
          containerModel={containerModel}
        />

        <TransportEvents
          shown={tabMenuModel.selectedID === 'events'}
          transportModel={containerModel}
          expandedEvents={expandedEvents}
          onEventSourceSelected={(eventSource) => transportModel.filterEventsBySource(eventSource)}
          onEventExpandToggle={(eventIndex) => transportPopupModel.toggleEventExpanded(eventIndex)}
        />

        {tabMenuModel.selectedID === 'map' && (
          <TransportMap milestones={milestones} origin={origin} destination={destination} />
        )}

        {tabMenuModel.selectedID === 'comments' && (
          <TransportComments
            comments={transportModel.comments}
            model={transportCommentsModel}
            onComment={transportPopupModel.postTransportComment}
          />
        )}
      </Div>
    </Grid>
  );
}

export default observer(Container);
