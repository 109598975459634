import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MapContainer, TileLayer, useMapEvent } from 'react-leaflet';
import Div from '../../../shared/components/Div/Div';
import styles from './TransportMap.module.scss';

import TransportPathMarkers from './TransportPathMarkers';
import TransportMapCustomController from './TransportMapCustomController';

function CustomMap({ model }) {
  const [mapClicked, setMapClicked] = useState(false);

  const map = useMapEvent(' click', ({ latlng }) => {
    setMapClicked(true);
    const { lat, lng } = latlng;
    model.setNewLocationCoordinates(lat, lng);
  });

  // Used for setting intial bounds when loading data
  // /transport page gets TransportMapModel data while it is still incomplete
  // so it can't use MapContainer whenReady
  useEffect(() => {
    if (!mapClicked && !model.center && model.path.length > 0) {
      if (model.areAllMilestonesAtSameLocation()) {
        map.setView([
          model.path[0].latitude,
          model.path[0].longitude,
        ]);
      } else {
        map.fitBounds(
          model.path
            .map((point) => [
              point.latitude,
              point.longitude,
            ]),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.path]);
  return null;
}

function TransportMap({
  model, height, ...props
}) {
  const {
    center,
  } = model;

  return (
    <Div className={styles.mapContainer} {...props}>
      {model.canShowController && <TransportMapCustomController mapModel={model} />}
      <MapContainer
        className={styles.map}
        style={{ height }}
        center={center || [0, 0]}
        zoom={center ? 10 : 2}
        scrollWheelZoom
        onClick={({ latlng }) => {
          const { lat, lng } = latlng;
          model.setNewLocationCoordinates(lat, lng);
        }}
      >
        <CustomMap model={model} />
        <TileLayer
          attribution="&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <TransportPathMarkers mapModel={model} />
      </MapContainer>
    </Div>
  );
}

export default observer(TransportMap);
