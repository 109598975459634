import React from 'react';
import Text from '../../shared/components/typography/Text';
import Select from '../../shared/components/Select/Select';
import Div from '../../shared/components/Div/Div';

function TrackingFilter({
  trackingFilterModel, filterOptionsLoading,
}) {
  const { trackingFilterSelectModel } = trackingFilterModel;

  return (
    <Div style={{ width: '50%' }} margin={{ left: 'xs' }}>
      <Div margin={{ bottom: 's' }}>
        <Text
          captionSmall
          bold
          color="neutral100"
        >
          Tracking Status
        </Text>
      </Div>
      <Div margin={{ bottom: 'l' }}>
        <Select
          isMulti
          options={trackingFilterSelectModel.options}
          value={trackingFilterSelectModel.value}
          isSearchable
          isClearable
          isLoading={filterOptionsLoading}
          isDisabled={filterOptionsLoading}
          placeholder="Select Tracking Status..."
          onChange={(option) => trackingFilterSelectModel.setValue(option)}
        />
      </Div>
    </Div>
  );
}

export default TrackingFilter;
