import React from 'react';
import EventCodeEnum from '../../TransportPopup/Transport/InternalEventCodeLabel';
import Flex from '../../shared/components/Flex/Flex';

export default function EventCell({ value: latestEventCode }) {
  return (
    <Flex
      style={{ minWidth: 24 }}
      padding={{ size: 'xs' }}
    >
      {(latestEventCode && EventCodeEnum[latestEventCode]) || latestEventCode || '-'}
    </Flex>
  );
}
