import React, { useContext } from 'react';
import { Icon } from '@storaensods/seeds-react';
import Flex from '../../shared/components/Flex/Flex';
import useDialog from '../../shared/components/Dialog/useDialog';
import OrderAndShowColumnsDialog from '../OrderAndShowColumnsDialog';
import TransportsTableContext from '../TransportsTableContext';

export default function PinIconHeader() {
  const { addDialog } = useDialog();
  const table = useContext(TransportsTableContext);

  return (
    <Flex
      centeredHorizontally
      padding={{ horizontal: 'xs', top: 'xs' }}
    >
      <Icon
        onClick={() => {
          addDialog(<OrderAndShowColumnsDialog table={table} />,
            {
              buttons: [
                {
                  label: 'Close',
                  onClick: () => true,
                },
              ],
            });
        }}
      >
        settings
      </Icon>
    </Flex>
  );
}
