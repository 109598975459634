import moment from 'moment';

export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATETIME_FORMAT = `yyyy-MM-dd ${DEFAULT_TIME_FORMAT}`;
export const DEFAULT_FULL_FORMAT = `L ${DEFAULT_TIME_FORMAT}`;
export const DEFAULT_DATE_ONLY_FORMAT = 'L';

export function toLocaleDateTimeFormat() {
  let result = DEFAULT_DATETIME_FORMAT;
  try {
    const momentDateLocaleFormat = moment.localeData()._config.longDateFormat.L;
    const nativeDateLocaleFormat = momentDateLocaleFormat.replace(/Y/g, 'y').replace(/D/g, 'd');
    result = `${nativeDateLocaleFormat} ${DEFAULT_TIME_FORMAT}`;
  // eslint-disable-next-line no-empty
  } catch (e) {}

  return result;
}

export function toLocaleDateFormat() {
  let result = DEFAULT_DATE_ONLY_FORMAT;
  try {
    const momentDateLocaleFormat = moment.localeData()._config.longDateFormat.L;
    const nativeDateLocaleFormat = momentDateLocaleFormat.replace(/Y/g, 'y').replace(/D/g, 'd');
    result = `${nativeDateLocaleFormat}`;
  // eslint-disable-next-line no-empty
  } catch (e) {}

  return result;
}
