import callBackend from '../../shared/methods/callBackend/callBackend';

export type TTrackingStatus = [
  'REQUEST_TRACKING',
  'CANCEL_TRACKING',
];

const setTransportTrackingStatus = async (transportID, trackingStatus) => {
  const url = `${process.env.REACT_APP_API_URL}/transports/${transportID}/trackingStatus/`;

  return callBackend({
    url,
    method: 'POST',
    body: trackingStatus,
  });
};

export default setTransportTrackingStatus;
