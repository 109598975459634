import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/es-us';
import 'moment/locale/eu';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/sv';

try {
  moment.locale(navigator.language);
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Unsupprted locale');
}
