import React from 'react';
import Element from '../Element/Element';
import styles from './Grid.module.scss';

function Grid({
  columns,
  columnGap,
  rows,
  className,
  children,
  style: explicitStyles = {},
  ...props
}) {
  const classNames = [styles.grid, className];

  const gridStyles = {
    msGridColumns: columns,
    msGridRows: rows,
    gridTemplateColumns: columns,
    gridTemplateRows: rows,
    gridColumnGap: columnGap,
  };

  return (
    <Element
      tagName="div"
      className={classNames}
      style={{ ...gridStyles, ...explicitStyles }}
      {...props}
    >
      {children}
    </Element>
  );
}

export default Grid;
