export default {
  ARRIVED: 'Arrived',
  DELIVERED: 'Delivered',
  IN_TRANSIT: 'In Transit',
  LOADING_STARTED: 'Loading started',
  PLANNED: 'Planned',
  READY_TO_SHIP: 'Ready to ship',
  SHIPPED: 'Shipped',
  TRACKING_CANCELLED: 'Tracking cancelled',
  VEHICLE_ARRIVED: 'Vehicle arrived',
};
