import React, { useState, useEffect } from 'react';
import { Checkbox } from '@storaensods/seeds-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Div from '../shared/components/Div/Div';

export const MANDATORY_COLUMNS = ['isPinned', 'expander', 'trackingStatus', 'comments'];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function OrderAndShowColumnsDialog({ table }) {
  const {
    allColumns,
    setColumnOrder,
  } = table;

  const currentInitVisibleColumns = {};
  allColumns.forEach((column) => {
    currentInitVisibleColumns[column.id] = column.isVisible;
  });

  const [checkedColumns, setCheckedColummns] = useState(currentInitVisibleColumns);
  const [draggedColumnsOrder, setdraggedColumnOrder] = useState(allColumns);

  useEffect(() => {
    const hiddenColumns = Object.keys(checkedColumns).filter((key) => !checkedColumns[key]);
    const columnsOrder = [
      ...MANDATORY_COLUMNS,
      ...draggedColumnsOrder.map((item) => item.id),
    ];
    localStorage.setItem('hiddenColumns', JSON.stringify(hiddenColumns));
    localStorage.setItem('columnsOrder', JSON.stringify(columnsOrder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedColumns, draggedColumnsOrder]);

  if (!allColumns) {
    return null;
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      draggedColumnsOrder,
      result.source.index,
      result.destination.index,
    );

    setdraggedColumnOrder(items);

    const columnsOrder = [
      ...MANDATORY_COLUMNS,
      ...items.map((item) => item.id),
    ];

    setColumnOrder(columnsOrder);
  };

  return (
    <>
      <Div backgroundColor="neutral20" padding={{ size: 's' }}>You can drag and drop to order the columns.</Div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {({ droppableProps, placeholder, innerRef: droppableInnerRef }) => (
            <div
              {...droppableProps}
              ref={droppableInnerRef}
            >
              {draggedColumnsOrder.map((column, index) => (
                <Draggable key={column.id} draggableId={column.id} index={index}>
                  {({ draggableProps, dragHandleProps, innerRef: draggableInnerRef }) => (
                    <div
                      ref={draggableInnerRef}
                      {...draggableProps}
                      {...dragHandleProps}
                    >
                      {!MANDATORY_COLUMNS.includes(column.id) && (
                        <Checkbox
                          id={column.id}
                          onClick={() => {
                            column.toggleHidden();
                            setCheckedColummns({
                              ...checkedColumns,
                              [column.id]: !checkedColumns[column.id],
                            });
                          }}
                          defaultChecked={checkedColumns[column.id]}
                        >
                          {column.header || column.id}
                        </Checkbox>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              <br />
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
