import { action, decorate, observable } from 'mobx';
// import { breakpointNames, breakpoints } from './breakpoints.scss';

// CRA 4.0.0 broke named imports from scss and not fixed by 5.0.0
// More about this: https://github.com/facebook/create-react-app/issues/10047
const breakpoints = '0 480px 680px 960px 1120px 1280px 1440px';
const breakpointNames = 'xxs xs s m l xl xxl';

class BreakpointModel {
  breakpoint;

  _breakpointNames;

  _breakpoints;

  _updateTimeoutID;

  constructor() {
    this._breakpointNames = breakpointNames.replace(/"/g, '').split(' ');
    this._breakpoints = breakpoints
      .replace(/px/g, '')
      .split(' ')
      .slice(1);

    window.addEventListener('resize', () => {
      clearTimeout(this._updateTimeoutID);
      this._updateTimeoutID = setTimeout(this.update, 100);
    });

    this.update();
  }

  update = () => {
    if (!this._breakpoints) return;

    this.breakpoint = this._breakpointNames[
      this._breakpoints.filter(
        (breakpoint) => window.innerWidth > breakpoint,
      ).length
    ];
  };

  breakpointIsThinnerThan = (comparison) => (
    this._breakpointNames.indexOf(this.breakpoint)
      < this._breakpointNames.indexOf(comparison)
  );

  breakpointIsEqualOrBiggerThan = (comparison) => {
    const b = this._breakpointNames.indexOf(comparison);
    return (
      window.innerWidth
      >= Number(this._breakpoints[b - 1])
    );
  };
}

export default decorate(BreakpointModel, {
  breakpoint: observable,
  update: action,
});
