import { decorate, observable } from 'mobx';
import getSearchResults from './getSearchResults';
import InputModel from '../shared/components/InputModel/InputModel';

class SearchModel {
  selectedTransportModel = new InputModel();

  searchResultsContRef;

  isSearchCurrentlyOnGoing;

  mapResponse = (response) => {
    const resultGroups = [
      { key: 'transportId', label: 'Transport ID' },
      { key: 'waybillId', label: 'Waybill ID' },
    ];

    return response.transports.map((resultGroup) => ({
      label: `Match: ${resultGroups.find((group) => group.key === resultGroup.match).label
      }`,
      options: resultGroup.results.map((result) => ({
        value: result.transportId,
        // label: `${result[resultGroup.match]} ${result.origin} - ${
        //   result.destination
        // }`
        label: result[resultGroup.match] || result.transportId,
      })),
    }));
  };

  loadSearchResults = async (query) => {
    if (!query || query.length < 5) {
      return Promise.resolve([]);
    }

    this.searchResultsContRef = null;

    this.isSearchCurrentlyOnGoing = true;

    const { callWasSuccessful, response } = await getSearchResults(query);

    this.isSearchCurrentlyOnGoing = false;

    this.searchResultsContRef = response.contRef;

    return !callWasSuccessful || this.searchResultsContRef
      ? []
      : this.mapResponse(response);
  };
}

export default decorate(SearchModel, {
  searchResultsContRef: observable,
  isSearchCurrentlyOnGoing: observable,
});
