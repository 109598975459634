import { computed } from 'mobx';
import SelectModel from '../../shared/components/Select/SelectModel';
import LateAndEarlyModel from './LateAndEarlyModel';

export default class EarlyAndLateFiltersModel {
  dayModelEarly = new SelectModel();

  hourModelEarly = new SelectModel();

  minuteModelEarly = new SelectModel();

  dayModelLate = new SelectModel();

  hourModelLate = new SelectModel();

  minuteModelLate = new SelectModel();

  lateAndEarlyModel = new LateAndEarlyModel();

  @computed
  get filters() {
    return {
      ...this.lateAndEarlyModel.getValue(),
    };
  }

  @computed
  get tags() {
    return {
      earlierThan: {
        list: this.lateAndEarlyModel.getEarlierThanLabelandValue(),
        remove: () => { this.lateAndEarlyModel.earlierThan = 0; },
      },
      laterThan: {
        list: this.lateAndEarlyModel.getLaterThanLabelandValue(),
        remove: () => { this.lateAndEarlyModel.laterThan = 0; },
      },
    };
  }

  setFilterOptions() {
    const dayOptions = Array.from(Array(51).keys()).map((day) => ({
      value: day,
      label: day,
    }));
    const hourOptions = Array.from(Array(24).keys()).map((hour) => ({
      value: hour,
      label: hour,
    }));
    const minuteOptions = Array.from(Array(60).keys()).map((minute) => ({
      value: minute,
      label: minute,
    }));

    this.dayModelEarly.setOptions(dayOptions);
    this.hourModelEarly.setOptions(hourOptions);
    this.minuteModelEarly.setOptions(minuteOptions);
    this.dayModelLate.setOptions(dayOptions);
    this.hourModelLate.setOptions(hourOptions);
    this.minuteModelLate.setOptions(minuteOptions);
  }

  setFilterValues = (filterValues) => {
    if (filterValues.earlierThan || filterValues.laterThan) {
      this.lateAndEarlyModel.initValue({
        earlierThan: filterValues.earlierThan,
        laterThan: filterValues.laterThan,
      });
    } else {
      this.lateAndEarlyModel.initValue({
        earlierThan: 0,
        laterThan: 0,
      });
    }
  };
}
