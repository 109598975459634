import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { throttle } from 'lodash';
import Div from '../shared/components/Div/Div';
import Select from '../shared/components/Select/Select';

function Search({ model, onTransportSearch, ...props }) {
  const {
    selectedTransportModel,
    searchResultsContRef,
    loadSearchResults,
  } = model;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadSuggestedOptions = useCallback(throttle((inputValue, callback) => {
    loadSearchResults(inputValue).then((options) => callback(options));
  }, 1000),
  []);

  return (
    <Div {...props}>
      <Select
        inputId="search"
        maxWidth={500}
        async
        defaultOptions
        dropdownIsHidden
        borderBottom
        value={selectedTransportModel.value}
        openMenuOnClick={false}
        noOptionsMessage={() => (searchResultsContRef
          ? 'Too many results, please refine search'
          : 'No results')}
        placeholder="Enter min. first 5 characters of Transport ID or Waybill ID"
        loadOptions={loadSuggestedOptions}
        onChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'select-option') {
            onTransportSearch(newValue.value);
            selectedTransportModel.setValue(null);
          }
        }}
      />
    </Div>
  );
}

export default observer(Search);
