import {
  action, autorun, computed, observable,
} from 'mobx';
import SelectModel from '../../shared/components/Select/SelectModel';

type TFilterOptions = {
  origins?: any,
  destinations?: any,
}

export default class OriginDestinationFiltersModel {
  @observable filterOptions: TFilterOptions = {};

  originCountryModel = new SelectModel();

  originLocationModel = new SelectModel();

  destinationCountryModel = new SelectModel();

  destinationLocationModel = new SelectModel();

  @computed
  get filters() {
    const originCountry = this.originCountryModel.getValue();

    const originLocation = this.originLocationModel.getValue();

    const destinationCountry = this.destinationCountryModel.getValue();

    const destinationLocation = this.destinationLocationModel.getValue();

    const origin = {
      ...(originCountry && !originLocation && { country: originCountry }),
      ...(originLocation && { location: originLocation }),
    };

    const destination = {
      ...(destinationCountry
        && !destinationLocation && { country: destinationCountry }),
      ...(destinationLocation && { location: destinationLocation }),
    };

    return {
      ...((origin.country || origin.location) && { origin }),
      ...((destinationCountry || destinationLocation) && {
        destination,
      }),
    };
  }

  @computed
  get exactFilters() {
    const originCountry = this.originCountryModel.getValue();

    const originLocation = this.originLocationModel.getValue();

    const destinationCountry = this.destinationCountryModel.getValue();

    const destinationLocation = this.destinationLocationModel.getValue();

    const origin = {
      ...(originCountry && { country: originCountry }),
      ...(originLocation && { location: originLocation }),
    };

    const destination = {
      ...(destinationCountry && { country: destinationCountry }),
      ...(destinationLocation && { location: destinationLocation }),
    };

    return {
      ...((origin.country || origin.location) && { origin }),
      ...((destinationCountry || destinationLocation) && {
        destination,
      }),
    };
  }

  @computed
  get tags() {
    return {
      originCountry: {
        list: this.originCountryModel.getLabelAndValue(),
        remove: (value) => { this.originCountryModel.remove(value); },
      },
      originLocation: {
        list: this.originLocationModel.getLabelAndValue(),
        remove: (value) => { this.originLocationModel.remove(value); },
      },
      destinationCountry: {
        list: this.destinationCountryModel.getLabelAndValue(),
        remove: (value) => { this.destinationCountryModel.remove(value); },
      },
      destinationLocation: {
        list: this.destinationLocationModel.getLabelAndValue(),
        remove: (value) => { this.destinationLocationModel.remove(value); },
      },
    };
  }

  constructor() {
    autorun(() => {
      this.originLocationModel.setOptions(
        this.getLocationOptions(
          this.filterOptions.origins,
          this.originCountryModel.value,
        ),
      );
    });

    autorun(() => {
      this.destinationLocationModel.setOptions(
        this.getLocationOptions(
          this.filterOptions.destinations,
          this.destinationCountryModel.value,
        ),
      );
    });
  }

  @action
  setFilterOptions(options) {
    this.filterOptions = options || {};

    this.originCountryModel.setOptions(
      this.getCountryOptions(this.filterOptions.origins),
    );

    this.destinationCountryModel.setOptions(
      this.getCountryOptions(this.filterOptions.destinations),
    );
  }

  getFilterOptions() {
    return this.filterOptions;
  }

  copyFilterOptions(options) {
    this.setFilterOptions(options);
  }

  getCountryOptions = (countries) => (countries
    ? countries.map((country) => ({
      value: country.code,
      label: `${country.code} - ${country.name}`,
    }))
    : []);

  getLocationOptions = (countries, selectedCountryOptions) => {
    const selectedValues = selectedCountryOptions || [];
    const showAllLocations = !selectedValues.length;

    return (countries
      ? countries
        .reduce(
          (locations, country) => [
            ...locations,
            ...(showAllLocations || selectedValues.filter((v) => v.value === country.code).length
              ? country.locations
              : []),
          ],
          [],
        )
        .map((location) => ({
          value: location.Code,
          label: `${location.Code}`,
        }))
      : []);
  };

  setFilterValues = (filterValues) => {
    if (filterValues.origin) {
      if (filterValues.origin.country) {
        this.originCountryModel.setValue(filterValues.origin.country);
      }
      if (filterValues.origin.location) {
        this.originLocationModel.setValue(filterValues.origin.location);
      }
    } else {
      this.originCountryModel.setValue(null);
      this.originLocationModel.setValue(null);
    }
    if (filterValues.destination) {
      if (filterValues.destination.country) {
        this.destinationCountryModel.setValue(filterValues.destination.country);
      }
      if (filterValues.destination.location) {
        this.destinationLocationModel.setValue(filterValues.destination.location);
      }
    } else {
      this.destinationCountryModel.setValue(null);
      this.destinationLocationModel.setValue(null);
    }
  };
}
