import React from 'react';
import Flex from '../Flex/Flex';
import styles from './CloseButton.module.scss';
import Div from '../Div/Div';

function CloseButton({ small, disabled, ...props }) {
  return (
    <Flex
      className={[
        styles.closeButton,
        disabled && styles.closeButton_disabled,
        styles.closeButton_crossContainer,
      ]}
      centered
      fullHeight={false}
      padding={{ size: 's' }}
      pointer
      {...props}
    >
      <Div
        className={
          small
            ? styles.closeButton_small_cross
            : styles.closeButton_large_cross
        }
      />
    </Flex>
  );
}

export default CloseButton;
