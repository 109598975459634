import callBackend from '../shared/methods/callBackend/callBackend';

const getFilterValues = async () => {
  const url = `${process.env.REACT_APP_API_URL}/user/filters`;

  const result = await callBackend({ url, method: 'GET' });
  return result;
};

export default getFilterValues;
