import flow from 'lodash/fp/flow';

const addDecorators = (...decorators) => ({
  to: (toBeDecorated) => {
    const decorated = flow(...decorators)(toBeDecorated);

    decorated.__decoratorObjects = decorators.map(
      ({ name, __decoratorParameters: parameters = null }) => ({
        name,
        parameters,
      }),
    );

    decorated.displayName = toBeDecorated.name;

    decorated.withDisplayName = (displayName) => {
      decorated.displayName = displayName;

      return decorated;
    };

    return decorated;
  },
});

export default addDecorators;
