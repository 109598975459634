import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { push as BurgerMenu } from 'react-burger-menu';
import { Icon } from '@storaensods/seeds-react';
import classnames from 'classnames';
import Div from '../Div/Div';
import Badge from '../Badge/Badge';
import colors from '../../styles/Colors.module.scss';
import styles from './QuickAccessBar.module.scss';
import { useMenu } from '../Menu/Menu';
import Tooltip from '../Tooltip/Tooltip';

const mainMenuStyles = {
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: colors.neutral20,
    // paddingTop: 90,
  },
  bmOverlay: {
    background: 'transparent',
  },
};

const QuickAccessBarContext = React.createContext({});

let closeTimeout = null;

export function useDrawer() {
  const {
    drawerOpenState, drawerContentState, quickAccessBtnState, quickAccessItemsState,
  } = useContext(QuickAccessBarContext);

  if (!drawerOpenState || !drawerContentState || !quickAccessBtnState) {
    return {
      open: () => {},
      close: () => {},
      toggleDrawer: () => {},
    };
  }

  const [isDrawerOpen, setDrawerOpen] = drawerOpenState;
  const [drawerContent, setDrawerContent] = drawerContentState;
  const [activeButtonKey, setActiveButtonKey] = quickAccessBtnState;
  const [quickAccessItems, setQuickAccessItems] = quickAccessItemsState;

  function open(btnKey) {
    const [quickAccessItem] = quickAccessItems.filter((item) => item.id === btnKey);
    if (quickAccessItem) {
      clearTimeout(closeTimeout);
      closeTimeout = null;
      setDrawerContent(quickAccessItem.Content);
      setActiveButtonKey(btnKey);
      setDrawerOpen(true);
    }
  }

  function close() {
    setDrawerOpen(false);

    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
    closeTimeout = setTimeout(() => {
      setActiveButtonKey(null);
      setDrawerContent(null);
    }, 100);
  }

  return {
    isDrawerOpen,
    setDrawerOpen,
    toggleDrawer: (btnKey) => {
      if (activeButtonKey !== btnKey) {
        open(btnKey);
      } else {
        close();
      }
    },
    open,
    close,
    setDrawerContent,
    drawerContent,
    activeButtonKey,
    setActiveButtonKey,
    quickAccessItems,
    setQuickAccessItems,
  };
}

function IconWithBadge({
  isDivider, Content, id, onClick, color, icon, badge, showBadge, tooltip, autoOpen,
}) {
  const {
    toggleDrawer, setDrawerContent, activeButtonKey, open, quickAccessItems,
  } = useDrawer();
  const { close: closeMainMenu } = useMenu();

  useEffect(() => {
    if (autoOpen) {
      open(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickAccessItems]);

  return (
    <Div>
      {isDivider && (
        <hr className={styles.divider} />
      )}
      <Div
        onClick={() => {
          if (Content) {
            setDrawerContent(Content);
            toggleDrawer(id);
            closeMainMenu();
          }
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
        // padding={{ left: 'xl' }}
        className={classnames([styles.barBtn], { [styles.active]: id === activeButtonKey })}
      >
        <Badge className={styles.badgeContainer} hide={!showBadge} value={badge || '•'}>
          {tooltip ? (
            <Tooltip tooltip={tooltip}>
              <Icon size="lg" style={{ color }} className={styles.barBtnIcon}>{icon || 'notifications'}</Icon>
            </Tooltip>
          ) : (
            <Icon size="lg" style={{ color }} className={styles.barBtnIcon}>{icon || 'notifications'}</Icon>
          )}
        </Badge>
      </Div>
    </Div>
  );
}

function QuickAccessSideBar({ barItems }) {
  return (
    <Div className={styles.QuickAccessSideBar}>
      {barItems.map((itemProps) => (
        itemProps.path ? (
          <Link key={itemProps.id} to={itemProps.path}>
            <IconWithBadge {...itemProps} />
          </Link>
        ) : (
          <IconWithBadge key={itemProps.id} {...itemProps} />
        )
      ))}
    </Div>
  );
}

function QuickAccessBarConsumer({ children, barItems }) {
  const {
    setDrawerOpen, isDrawerOpen, drawerContent, setQuickAccessItems,
  } = useDrawer();

  useEffect(() => {
    setQuickAccessItems(barItems);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barItems]);

  return (
    <Div>
      <QuickAccessSideBar barItems={barItems} />
      <Div id="qa-drawer-container" className={styles.ContentNextToQuickAccessBar}>
        <BurgerMenu
          isOpen={isDrawerOpen}
          onStateChange={({ isOpen }) => setDrawerOpen(isOpen)}
          styles={mainMenuStyles}
          width={280}
          pageWrapId="qa-bar-wrap"
          outerContainerId="qa-drawer-container"
          noOverlay
          customBurgerIcon={false}
          customCrossIcon={false}
          disableAutoFocus
        >
          <Div className={styles.drawerContentContainer}>
            {drawerContent}
          </Div>
        </BurgerMenu>
        <Div id="qa-bar-wrap">
          {children}
        </Div>
      </Div>
    </Div>
  );
}

export function QuickAccessBarProvider({ children }) {
  const drawerOpenState = useState(false);
  const drawerContentState = useState(null);
  const quickAccessBtnState = useState(null);
  const quickAccessItemsState = useState([]);
  return (
    <QuickAccessBarContext.Provider value={{
      drawerOpenState, drawerContentState, quickAccessBtnState, quickAccessItemsState,
    }}
    >
      {children}
    </QuickAccessBarContext.Provider>
  );
}

function QuickAccessBar({ children, barItems }) {
  return (
    <QuickAccessBarProvider>
      <QuickAccessBarConsumer barItems={barItems}>
        {children}
      </QuickAccessBarConsumer>
    </QuickAccessBarProvider>
  );
}

export default observer(QuickAccessBar);
