/* eslint-disable no-nested-ternary */
import TransportScheduleStatus from './TransportScheduleStatus';

const earlyThreshold = 1000 * 60 * 60 * 12;

const getScheduleStatus = (diff) => (diff !== undefined
  ? diff < -earlyThreshold
    ? TransportScheduleStatus.EARLY
    : diff > 0
      ? TransportScheduleStatus.LATE
      : TransportScheduleStatus.ON_TIME
  : undefined);

export default getScheduleStatus;
