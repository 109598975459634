import callBackend from '../shared/methods/callBackend/callBackend';

export type OpeningHours = {
  id: string,
  locationId: string,
  close: string,
  dayOfWeek: number,
  date: Date,
  timeZone: string,
};

const putOpeningHours = async (id, body: OpeningHours) => callBackend({
  url: `${process.env.REACT_APP_API_URL}/openinghours/${id}`,
  method: 'PUT',
  body,
});

export default putOpeningHours;
