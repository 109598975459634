/* eslint-disable react/no-array-index-key */
import { observer } from 'mobx-react';
import React from 'react';
import Flex from '../Flex/Flex';
import Text from '../typography/Text';
import styles from './TabMenu.module.scss';
import colors from '../../styles/Colors.module.scss';
import CommentBubble from '../CommentBubble/CommentBubble';

function TabMenu({
  model,
  items = model.items,
  selectedID = model.selectedID,
  style,
  onChange,
  ...props
}) {
  return (
    <Flex
      fullHeight={false}
      style={{
        borderBottom: `${colors.neutral30} 1px solid`,
        ...style,
      }}
      {...props}
    >
      {items.map((item, index) => (
        <Flex
          key={index}
          centeredVertically
          className={[
            styles.tabMenu_item,
            selectedID === item.id && styles.tabMenu_item_selected,
          ]}
          padding={{ bottom: 'xs' }}
          pointer={selectedID !== item.id}
          onClick={() => onChange(item.id)}
        >
          <Flex centeredVertically style={{ height: '26px' }}>
            <Text tagName="span" bold caption color="neutral100">
              {item.label}
            </Text>
          </Flex>

          {item.comments && item.comments.length && (
            <CommentBubble
              numComments={item.comments.length}
              transportStage={item.comments.transportStage}
              margin={{ left: 'xs' }}
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
}

export default observer(TabMenu);
