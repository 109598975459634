import { observer } from 'mobx-react';
import React from 'react';
import Div from '../Div/Div';
import Label from '../Label/Label';

function Checkbox({
  id,
  checked,
  onChange,
  children,
}) {
  return (
    <Div className="se-checkbox se-checkbox--md " margin={{ bottom: 's' }}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <Label htmlFor={id}>
        <span className="se-checkbox-label">{children}</span>
      </Label>
    </Div>
  );
}

export default observer(Checkbox);
