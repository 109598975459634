import React from 'react';
import Text from '../typography/Text';
import styles from './Button.module.scss';

function Button({ enabled = true, children, ...props }) {
  return (
    <Text
      className={[styles.button, !enabled && styles.button_disabled]}
      tagName="span"
      bold
      caption
      pointer={enabled}
      color="blue100"
      padding={{ size: 's' }}
      {...props}
    >
      {children}
    </Text>
  );
}

export default Button;
