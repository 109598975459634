/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
import { Icon } from '@storaensods/seeds-react';
import Div from '../../../shared/components/Div/Div';
import Grid from '../../../shared/components/Grid/Grid';
import Heading from '../../../shared/components/typography/Heading';
import Text from '../../../shared/components/typography/Text';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';

import styles from './TransportOriginAndDestination.module.scss';

/*
Note that the day-of-week information returned from the API is currently not correct.
It is not needed though, as moment.js is perfectly able to give us the weekday from the date itself.
*/
function OriginOpeningHours({ openings }) {
  return (
    <Div padding={{ size: 'xs' }}>
      {openings.map(({
        dayOfWeek, date, open, close,
      }, i) => (
        <Grid
          columns="36px 1fr"
          fullWidth
          key={`dayOfWeek-${dayOfWeek}`}
          style={{ fontWeight: i === 2 && 'bold' }}
        >
          <Text captionSmall>
            {moment(date).format('ddd')}
          </Text>
          <Text captionSmall>
            {moment(date).format('L')}
            &ensp;
            {moment(`${date.split('T')[0]}T${open}`).format('LT')}
            -
            {moment(`${date.split('T')[0]}T${close}`).format('LT')}
          </Text>
        </Grid>
      ))}
    </Div>
  );
}

function TransportOriginAndfinalDestination({
  transportModel: { origin, finalDestination, originOpeningHours },
  withFullAddress = true,
}) {
  const openingHours = originOpeningHours && originOpeningHours.length ? (
    <OriginOpeningHours openings={originOpeningHours} />
  ) : 'Opening hours missing';

  return (
    <Div fullWidth columns="1fr 1fr" rows="auto">
      {origin && (
        <>
          <Heading
            tagName="h6"
            padding={{ bottom: 'xs' }}
            color="neutral100"
            className={styles.ellipse}
          >
            <Icon style={{ marginRight: 8, marginLeft: 4 }}>home</Icon>
            {origin.nameAddress.length && `${origin.nameAddress[0]}, `}
            {origin.id}
            <Tooltip placement="top" tooltip={openingHours}>
              <Icon style={{ marginLeft: 8, cursor: 'pointer' }}>access_time</Icon>
            </Tooltip>
          </Heading>
        </>
      )}

      {finalDestination && (
        <Heading
          tagName="h6"
          padding={{ bottom: 'xs' }}
          color="neutral100"
          className={styles.ellipse}
        >
          <Icon style={{ marginRight: 8, marginLeft: 4 }}>flag</Icon>
          {finalDestination.nameAddress.length && `${finalDestination.nameAddress[0]}, `}
          {finalDestination.id}
        </Heading>
      )}

      {withFullAddress && origin && origin.nameAddress.length > 0 && (
        <Text style={{ msGridRow: 3, msGridColumn: 1 }}>
          <span>
            {origin.nameAddress[1]}
            <br />
          </span>
          <span>
            {[...origin.nameAddress].slice(2, origin.nameAddress.length).join(', ')}
          </span>
        </Text>
      )}

      {withFullAddress && finalDestination && finalDestination.nameAddress.length > 0 && (
        <Text style={{ msGridRow: 3, msGridColumn: 2 }}>
          <span>
            {finalDestination.nameAddress[1]}
            <br />
          </span>
          <span>
            {[...finalDestination.nameAddress].slice(2, finalDestination.nameAddress.length).join(', ')}
          </span>
        </Text>
      )}
    </Div>
  );
}

export default TransportOriginAndfinalDestination;
