import { action, observable } from 'mobx';

export default class InputModel {
  @observable value;

  constructor({ defaultValue }: any = {}) {
    this.setValue(defaultValue);
  }

  @action
    setValue = (value) => {
      this.value = value;
    };

  remove() {
    this.value = null;
  }
}
