/* eslint-disable react/no-array-index-key */
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import contains from 'lodash/fp/contains';
import Div from '../../../shared/components/Div/Div';
import Flex from '../../../shared/components/Flex/Flex';
import Text from '../../../shared/components/typography/Text';
import Select from '../../../shared/components/Select/Select';
import getXmlMessageUrl from './getXmlMessageUrl';
import TransportScheduleStatus from '../TransportScheduleStatus';
import Event from './Event';

function hasMilestonePoistion(event) {
  return event.milestone && event.milestone.latitude && event.milestone.longitude;
}

function TransportEvents({
  transportModel,
  expandedEvents,
  onEventExpandToggle,
  onEventSourceSelected,
  ...props
}) {
  const {
    arrivalRequestedTo, statusMessages, uniqueTrackerNames,
  } = transportModel;

  const [statusMessagesToShowInUI, setStatusMessagesToShowInUI] = useState([]);

  useEffect(() => {
    const filteredMessages = (statusMessages || []).filter(
      (statusMessage) => statusMessage.showInUI,
    );

    let groupId = 0;
    let previousEventCode = '';
    let firstEventOfGroup = false;
    const groupedMessages = filteredMessages.reverse().map((message) => {
      const currentEventCode = message.eventCode;
      if (currentEventCode !== previousEventCode) {
        groupId += 1;
        firstEventOfGroup = true;
      } else {
        firstEventOfGroup = false;
      }
      previousEventCode = message.eventCode;
      return {
        ...message, firstEventOfGroup, groupId, show: false,
      };
    });
    setStatusMessagesToShowInUI(groupedMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkGroupLength(event) {
    if (event.firstEventOfGroup) {
      const groupLength = statusMessagesToShowInUI.filter(
        (firstEvent) => firstEvent.groupId === event.groupId,
      ).length;
      return groupLength;
    }
    return null;
  }
  // Milestones contain also Arrived event possibly, but here we want to calculate only ETA messages
  let milestoneIndex = statusMessagesToShowInUI.filter((message) => message.eventCode === 'ETA').length;

  const lastSequenceNumber = statusMessagesToShowInUI.filter(
    (event) => hasMilestonePoistion(event),
  ).length;

  let currentSequence = lastSequenceNumber + 1; // will be decreased by one

  const eventSourceOptions = (uniqueTrackerNames || []).map((trackerName) => ({
    value: trackerName,
    label: `${trackerName.trackerName} - ${trackerName.trackerId}`,
  }));

  const downloadFile = async (blobName) => {
    if (blobName) {
      const blobUrl = await getXmlMessageUrl(blobName);

      if (blobUrl) {
        window.open(blobUrl, '_blank');
      } else {
        toast.error('Did not receive URL to download XML message.');
      }
    }
  };

  const toggleHideShow = (selectedEvent) => {
    const updatedEventArray = statusMessagesToShowInUI.slice(0).map((eventToUpdate) => {
      if (eventToUpdate.groupId === selectedEvent.groupId) {
        return { ...eventToUpdate, show: !eventToUpdate.show };
      }
      return eventToUpdate;
    });
    setStatusMessagesToShowInUI(updatedEventArray);
  };

  return (
    <Div {...props}>
      {eventSourceOptions && eventSourceOptions.length > 1
        && (
          <>
            <Flex
              alignLeft
              centeredVertically
              {...props}
            >
              <Text>EVENT SOURCE</Text>
              <Div
                {...props}
                style={{ width: '50%', marginLeft: '1em' }}
              >
                <Select
                  options={eventSourceOptions}
                  isSearchable
                  isClearable
                  placeholder="Select source..."
                  onChange={(option) => onEventSourceSelected(option ? option.value : '')}
                />
              </Div>
            </Flex>
          </>
        )}
      {statusMessagesToShowInUI
        && statusMessagesToShowInUI
          .slice(0)
          .map((event, index) => {
            milestoneIndex -= 1;
            const hasPoition = hasMilestonePoistion(event);
            if (hasPoition) {
              currentSequence -= 1;
            }
            return (
              <Event
                key={index}
                isCurrentLate={
                  event.milestone && event.milestone.scheduleStatus === TransportScheduleStatus.LATE
                }
                isCurrentActive={lastSequenceNumber === currentSequence}
                currentSequence={hasPoition && currentSequence}
                event={event}
                milestoneIndex={
                  event.eventCode === 'ETA' ? milestoneIndex : undefined
                }
                arrivalRequestedTo={arrivalRequestedTo}
                isExpanded={contains(index, expandedEvents)}
                onExpandToggle={() => onEventExpandToggle(index)}
                toggleHideShow={() => toggleHideShow(event)}
                onDownloadClick={downloadFile}
                groupLength={checkGroupLength(event)}
              />
            );
          })}
    </Div>
  );
}

export default observer(TransportEvents);
