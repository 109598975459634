/* eslint-disable no-restricted-globals */

import React, { useEffect } from 'react';
import { Icon } from '@storaensods/seeds-react';
import CommentsCell from './CommentsCell/CommentsCell';
import PickupCell from './SmartCells/PickupCell';
import DropOffCell from './SmartCells/DropOffCell';
import TrackingStatusCell from './TrackingStatusCell/TrackingStatusCell';
import EventCell from './EventCell/EventCell';
import LatenessStatusDifferenceCell from './LatenessStatusDifferenceCell/LatenessStatusDifferenceCell';
import Flex from '../shared/components/Flex/Flex';
import PinIconCell from './PinIconCell/PinIconCell';
import PinIconHeader from './PinIconCell/PinIconHeader';
import Text from '../shared/components/typography/Text';
import TransportCaption from '../TransportCaption/TransportCaption';

import styles from './TransportsTable.module.scss';

const CellWrapper = ({ children, ...props }) => (
  <Flex {...props}>
    {children}
  </Flex>
);

const sortingIcon = (id, sortingModel) => {
  let icon;
  if (id === sortingModel.sortingColumn) {
    if (sortingModel.sortingDirection === 'DESC') {
      icon = 'arrow_downwards';
    } else {
      icon = 'arrow_upward';
    }
  } else {
    icon = 'sort';
  }
  return icon;
};

const HeaderCell = ({
  column, centered, tooltip, onClick, icon,
}) => {
  useEffect(() => {
    if (!column.isResizing) {
      localStorage.setItem(`width-${column.id}`, `${column.width}`);
    }
  }, [column.isResizing, column.id, column.width]);
  return (
    <CellWrapper
      className={styles.HeaderCell}
      centered={centered}
      onClick={onClick}
    >
      {icon && (
      <Icon className={styles.icon}>
        {icon}
      </Icon>
      )}
      <Text caption bold color="neutral100" centered={centered} tooltip={tooltip} className={styles.HeaderCellText}>
        {column.header}
      </Text>
    </CellWrapper>
  );
};

const TextBaseCell = ({
  value, alignRight, centered, tooltip,
}) => (
  <CellWrapper
    centeredVertically
    className={styles.TextBaseCell}
    alignRight={alignRight}
    centered={centered}
  >
    <Text
      captionSmall
      tooltip={tooltip}
      className={[styles.TextBaseCellText, tooltip && styles.withTooltip]}
    >
      {value}
    </Text>
  </CellWrapper>
);

export default function getColumns(sortingModel) {
  const columns = [];

  columns.push({
    id: 'isPinned',
    accessor: 'isPinned',
    Header: (currentProps) => <PinIconHeader {...currentProps} />,
    Cell: (currentProps) => <PinIconCell centered {...currentProps} />,
    width: 30,
  },
  {
    id: 'expander',
    Header: (currentProps) => <HeaderCell centered {...currentProps} />,
    width: 30,
    Cell: ({ row }) => {
      const { transportErrors } = row.original;
      const hasErrors = Array.isArray(transportErrors) && transportErrors.length;
      const expanderIcon = hasErrors ? (
        <span className={styles.badge}>{transportErrors.length}</span>
      ) : (
        <span style={{ fontSize: 25 }}>&#x2295;</span>
      );
      return row.canExpand ? (
        <span {...row.getToggleRowExpandedProps()}>
          {!row.isExpanded ? expanderIcon : <span style={{ fontSize: 25 }}>&#x2299;</span>}
        </span>
      ) : null;
    },
  },
  {
    id: 'trackingStatus',
    accessor: 'trackingStatus',
    Cell: (currentProps) => <TrackingStatusCell {...currentProps} />,
    width: 36,
  },
  {
    id: 'comments',
    accessor: 'comments',
    Cell: (currentProps) => <CommentsCell centered {...currentProps} />,
    width: 36,
  });

  columns.push({
    id: 'carrierId',
    accessor: 'carrier.id',
    header: 'Carrier',
    sortable: true,
    Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('carrier.id', sortingModel)} onClick={() => sortingModel.setSorting('carrier.id')} />,
    Cell: (currentProps) => <TextBaseCell {...currentProps} />,
    width: parseInt(localStorage.getItem('width-carrierId'), 10) || 150,
    minWidth: 130,
  },
  {
    id: 'carrierName',
    accessor: 'carrier.nameAddress[0]',
    header: 'Carrier name',
    sortable: true,
    Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('carrier.nameAddress[0]', sortingModel)} onClick={() => sortingModel.setSorting('carrier.nameAddress[0]')} />,
    Cell: (currentProps) => <TextBaseCell {...currentProps} />,
    width: parseInt(localStorage.getItem('width-carrierName'), 10) || 200,
    minWidth: 200,
  },
  {
    id: 'transportId',
    accessor: 'transportId',
    header: 'Transport ID',
    sortable: true,
    Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('transportId', sortingModel)} onClick={() => sortingModel.setSorting('transportId')} />,
    Cell: (currentProps) => <TextBaseCell {...currentProps} />,
    width: parseInt(localStorage.getItem('width-transportId'), 10) || 200,
    minWidth: 190,
  },
  {
    id: 'licensePlateNumber',
    accessor: 'vehicle.licensePlateNumber',
    header: 'License Plate',
    sortable: true,
    Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('vehicle.licensePlateNumber', sortingModel)} onClick={() => sortingModel.setSorting('vehicle.licensePlateNumber')} />,
    Cell: (currentProps) => <TextBaseCell {...currentProps} />,
    width: parseInt(localStorage.getItem('width-licensePlateNumber'), 10) || 150,
    minWidth: 150,
  },
  {
    id: 'customer',
    accessor: 'buyer.id',
    header: 'Buyer',
    sortable: true,
    Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('buyer.id', sortingModel)} onClick={() => sortingModel.setSorting('buyer.id')} />,
    Cell: (currentProps) => <TextBaseCell {...currentProps} />,
    width: parseInt(localStorage.getItem('width-customer'), 10) || 200,
    minWidth: 130,
  });

  columns.push(
    {
      id: 'pickUp',
      accessor: 'pickUp',
      header: 'Pick up +',
      Header: (currentProps) => <HeaderCell {...currentProps} />,
      Cell: (currentProps) => (
        <PickupCell {...currentProps} />
      ),
      width: parseInt(localStorage.getItem('width-pickUp'), 10) || 260,
      minWidth: 150,
    },
    {
      id: 'dropOff',
      accessor: 'dropOff',
      header: 'Drop off +',
      Header: (currentProps) => <HeaderCell {...currentProps} />,
      Cell: (currentProps) => (
        <CellWrapper>
          <DropOffCell {...currentProps} />
        </CellWrapper>
      ),
      width: parseInt(localStorage.getItem('width-dropOff'), 10) || 260,
      minWidth: 150,
    },
    {
      id: 'dropoffLocations.name',
      accessor: 'finalDestination.id',
      header: 'Drop Off Location name',
      Header: (currentProps) => <HeaderCell {...currentProps} />,
      Cell: (currentProps) => {
        const { row } = currentProps;
        let cellValues = [];
        try {
          cellValues = row.original.dropoffLocations.map((location) => {
            let locationValue = '';
            locationValue = location.nameAddress[0] ?? 'N/A';
            return { locationValue, id: location.id };
          });
        } catch (err) {
          cellValues = ['N/A'];
        }
        return cellValues.map((value) => (
          <TextBaseCell
            key={value.id}
            value={value.locationValue}
          />
        ));
      },
      width: parseInt(localStorage.getItem('width-dropoffLocations.name'), 10) || 200,
      minWidth: 150,
    },
    {
      id: 'latenessStatusDifference',
      header: 'Lateness',
      accessor: 'latenessStatusDifference',
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('latenessStatusDifference', sortingModel)} onClick={() => sortingModel.setSorting('latenessStatusDifference')} />,
      Cell: (currentProps) => <LatenessStatusDifferenceCell {...currentProps} />,
      width: parseInt(localStorage.getItem('width-latenessStatusDifference'), 10) || 150,
      minWidth: 140,
    },
    {
      id: 'event',
      header: 'Latest Event',
      accessor: 'latestEventCode',
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('latestEventCode', sortingModel)} onClick={() => sortingModel.setSorting('latestEventCode')} />,
      Cell: (currentProps) => <EventCell {...currentProps} />,
      width: parseInt(localStorage.getItem('width-latest-event'), 10) || 160,
      minWidth: 120,
    },
    {
      id: 'pickuLocations.ids',
      accessor: (row) => row.pickupLocations && row.pickupLocations.map(
        (location) => location.id,
      ),
      header: 'Pick Up Location',
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} tooltip="Pick up location" icon={sortingIcon('pickupLocations[0].id', sortingModel)} onClick={() => sortingModel.setSorting('pickupLocations[0].id')} />,
      Cell: ({ row, value }) => {
        let tooltip = null;
        try {
          tooltip = row.original.pickupLocations.map((location) => location.nameAddress.join('\n')).join(',\n');
          // eslint-disable-next-line no-empty, keyword-spacing
        } catch (err) { }
        return value.map((id) => (
          <TextBaseCell
            key={id}
            tooltip={tooltip}
            value={id}
          />
        ));
      },
      width: parseInt(localStorage.getItem('width-pickuLocations.ids'), 10) || 200,
      minWidth: 150,
    },
    {
      id: 'loadingPlannedFrom',
      header: 'Pick Up RTA From',
      sortable: true,
      accessor: (row) => row.pickupLocations && row.pickupLocations.map(
        (location) => location.loadingPlannedFrom,
      ),
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('pickupLocations[0].loadingPlannedFrom', sortingModel)} onClick={() => sortingModel.setSorting('pickupLocations[0].loadingPlannedFrom')} />,
      Cell: ({ value }) => (
        value.map((dateTime, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${dateTime}-${index}`}>
            <TransportCaption date={dateTime} color="neutral40" />
            <TransportCaption time={dateTime} color="neutral40" />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-loadingPlannedFrom'), 10) || 200,
      minWidth: 160,
    },
    {
      id: 'loadingPlannedTo',
      header: 'Pick Up RTA To',
      sortable: true,
      accessor: (row) => row.pickupLocations && row.pickupLocations.map(
        (location) => location.loadingPlannedTo,
      ),
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('pickupLocations[0].loadingPlannedTo', sortingModel)} onClick={() => sortingModel.setSorting('pickupLocations[0].loadingPlannedTo')} />,
      Cell: ({ value }) => (
        value.map((dateTime, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${dateTime}-${index}`}>
            <TransportCaption date={dateTime} color="neutral40" />
            <TransportCaption time={dateTime} color="neutral40" />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-loadingPlannedTo'), 10) || 200,
      minWidth: 160,
    },
    {
      id: 'departure',
      accessor: (row) => row.pickupLocations && row.pickupLocations.map(
        (location) => location.departureActual,
      ),
      header: 'Departure Time',
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('pickupLocations[0].departureActual', sortingModel)} onClick={() => sortingModel.setSorting('pickupLocations[0].departureActual')} />,
      Cell: (currentProps) => (
        currentProps.value.map((departure, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${departure}-${index}`}>
            <TransportCaption date={departure} color="neutral80" />
            <TransportCaption time={departure} color="neutral80" />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-departure'), 10) || 260,
      minWidth: 160,
    },
    {
      id: 'dropoffLocations.ids',
      accessor: 'finalDestination.id', // Not really used, legacy at this point
      header: 'Drop Off Location',
      Header: (currentProps) => <HeaderCell {...currentProps} tooltip="Drop off location" />,
      Cell: (currentProps) => {
        const { row } = currentProps;
        let tooltip = '';
        let cellValues = [];

        try {
          tooltip = row.original.dropoffLocations.map((location) => location.nameAddress.join('\n')).join(',\n');
          // eslint-disable-next-line no-empty, keyword-spacing
        } catch (err) { }

        try {
          cellValues = row.original.dropoffLocations.map((location) => {
            let locationValue;
            if (location.nameAddress.length > 3) {
              locationValue = location.nameAddress[location.nameAddress.length - 1] ?? 'N/A';
              locationValue += ' - ';
              locationValue += location.nameAddress[3] ?? 'N/A';
            } else {
              locationValue = 'Incomplete address data';
              tooltip += locationValue;
            }
            return { locationValue, id: location.id };
          });
        } catch (err) {
          cellValues = ['N/A'];
          tooltip = 'Error parsing address data';
        }

        return cellValues.map((value) => (
          <TextBaseCell
            key={value.id}
            tooltip={tooltip}
            value={value.locationValue}
          />
        ));
      },
      width: parseInt(localStorage.getItem('width-dropoffLocations.ids'), 10) || 200,
      minWidth: 150,
    },
    {
      id: 'consignee',
      header: 'Consignee',
      accessor: (row) => row.dropoffLocations && row.dropoffLocations.map(
        (location) => location.id,
      ),
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('dropoffLocations[0].id', sortingModel)} onClick={() => sortingModel.setSorting('dropoffLocations[0].id')} />,
      Cell: ({ value }) => value.map((id) => <TextBaseCell key={id} value={id} />),
      width: parseInt(localStorage.getItem('width-consignee'), 10) || 200,
      minWidth: 160,
    },
    {
      id: 'arrivalRequestedFrom',
      header: 'Drop Off RTA From',
      accessor: (row) => row.dropoffLocations && row.dropoffLocations.map(
        (location) => location.arrivalRequestedFrom,
      ),
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('dropoffLocations[0].arrivalRequestedFrom', sortingModel)} onClick={() => sortingModel.setSorting('dropoffLocations[0].arrivalRequestedFrom')} />,
      Cell: ({ value }) => (
        value.map((dateTime, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${dateTime}-${index}`}>
            <TransportCaption date={dateTime} color="neutral40" />
            <TransportCaption time={dateTime} color="neutral40" />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-arrivalRequestedFrom'), 10) || 200,
      minWidth: 160,
    },
    {
      id: 'arrivalRequestedTo',
      header: 'Drop Off RTA To',
      accessor: (row) => row.dropoffLocations && row.dropoffLocations.map(
        (location) => location.arrivalRequestedTo,
      ),
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('dropoffLocations[0].arrivalRequestedTo', sortingModel)} onClick={() => sortingModel.setSorting('dropoffLocations[0].arrivalRequestedTo')} />,
      Cell: ({ value }) => (
        value.map((dateTime, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${dateTime}-${index}`}>
            <TransportCaption date={dateTime} color="neutral40" />
            <TransportCaption time={dateTime} color="neutral40" />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-arrivalRequestedTo'), 10) || 200,
      minWidth: 160,
    },
    {
      id: 'arrivalEstimatedDestination',
      header: 'ETA',
      accessor: (row) => row.dropoffLocations && row.dropoffLocations.map(
        (location) => location.arrivalEstimated,
      ),
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('dropoffLocations[0].arrivalEstimated', sortingModel)} onClick={() => sortingModel.setSorting('dropoffLocations[0].arrivalEstimated')} />,
      Cell: ({ value }) => (
        value.map((dateTime, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${dateTime}-${index}`}>
            <TransportCaption date={dateTime} color="neutral40" />
            <TransportCaption time={dateTime} color="neutral40" />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-arrivalEstimatedDestination'), 10) || 200,
      minWidth: 160,
    },
    {
      id: 'arrival',
      accessor: (row) => row.dropoffLocations && row.dropoffLocations.map(
        (location) => location.arrivalActual,
      ),
      header: 'Arrival Time',
      sortable: true,
      Header: (currentProps) => <HeaderCell {...currentProps} icon={sortingIcon('dropoffLocations[0].arrivalActual', sortingModel)} onClick={() => sortingModel.setSorting('dropoffLocations[0].arrivalActual')} />,
      Cell: ({ value }) => (
        value.map((dateTime, index) => (
          dateTime ? (
            // eslint-disable-next-line react/no-array-index-key
            <CellWrapper key={`${dateTime}-${index}`}>
              <TransportCaption date={dateTime} color="neutral80" />
              <TransportCaption time={dateTime} color="neutral80" />
            </CellWrapper>
            // eslint-disable-next-line react/no-array-index-key
          ) : <TextBaseCell key={index} value="" />
        ))
      ),
      width: parseInt(localStorage.getItem('width-arrival'), 10) || 260,
      minWidth: 160,
    },
  );

  columns.splice(columns.length - 1, 0, {
    id: 'weight',
    accessor: (row) => row.waybills && row.waybills
      .reduce((total, waybill) => total + waybill.weight, 0),
    header: 'Weight',
    Cell: ({ value }) => (
      <TextBaseCell value={value ? `${value} kg` : ''} />
    ),
    Header: (currentProps) => <HeaderCell {...currentProps} />,
    width: parseInt(localStorage.getItem('width-weight'), 10) || 120,
    minWidth: 100,
  });

  columns.push(
    {
      id: 'millOrderNumber',
      header: 'Mill Order',
      accessor: (row) => row.dropoffLocations && row.dropoffLocations.map(
        (location) => location.orders.map((order) => `${order.number}-${order.lineItemNumber}`),
      ).flat(),
      Header: (currentProps) => <HeaderCell {...currentProps} />,
      Cell: ({ value }) => (
        value.map((orderLine, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${orderLine}-${index}`}>
            <TextBaseCell value={orderLine} />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-millOrderNumber'), 10) || 200,
      minWidth: 140,
    },
    {
      id: 'millOrderCallOff',
      header: 'Call Off No',
      accessor: (row) => row.dropoffLocations && row.dropoffLocations.map(
        (location) => location.orders.map((order) => `${order.callOffNumber}-${order.callOffLineItemNumber}`),
      ).flat(),
      Header: (currentProps) => <HeaderCell {...currentProps} />,
      Cell: ({ value }) => (
        value.map((orderLine, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${orderLine}-${index}`}>
            <TextBaseCell value={orderLine} />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-millOrderCallOff'), 10) || 200,
      minWidth: 150,
    },
    {
      id: 'millOrderCustomerOrder',
      header: 'Customer Order',
      accessor: (row) => row.dropoffLocations && row.dropoffLocations.map(
        (location) => location.orders.map((order) => order.customerOrder),
      ).flat(),
      Header: (currentProps) => <HeaderCell {...currentProps} />,
      Cell: ({ value }) => (
        value.map((orderLine, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CellWrapper key={`${orderLine}-${index}`}>
            <TextBaseCell value={orderLine} />
          </CellWrapper>
        ))
      ),
      width: parseInt(localStorage.getItem('width-millOrderCustomerOrder'), 10) || 200,
      minWidth: 150,
    },
  );

  return columns;
}
