import {
  action,
  autorun,
  observable,
  toJS,
} from 'mobx';

export type SortingColumn = string | null;

export type SortingDirection ='ASC' | 'DESC' | null;

const storeName = 'sortingModel';

export default class SortingModel {
  @observable sortingColumn: SortingColumn = null;

  @observable sortingDirection: SortingDirection = null;

  onSortingValuesAreChanged: Function | undefined;

  constructor() {
    const storedData = localStorage.getItem(storeName);
    if (storedData) {
      try {
        Object.assign(this, JSON.parse(storedData));
      } catch (e) {
        localStorage.removeItem(storeName); // Remove faulty data
      }
    }
    autorun(() => {
      localStorage.setItem(storeName, JSON.stringify(toJS(this)));
    });
  }

  @action
  setSorting(sortingColumn: SortingColumn) {
    if (this.sortingColumn === sortingColumn) {
      this.flipSortingDirection();
    } else {
      this.sortingColumn = sortingColumn;
      this.sortingDirection = 'DESC';
    }
    if (this.onSortingValuesAreChanged) {
      this.onSortingValuesAreChanged();
    }
  }

  @action
  setSortingColumn(sortingColumn: SortingColumn) {
    this.sortingColumn = sortingColumn;
  }

  @action
  setSortingDirection(sortingDirection: SortingDirection) {
    this.sortingDirection = sortingDirection;
  }

  @action
  flipSortingDirection() {
    if (this.sortingDirection === 'DESC') {
      this.sortingDirection = 'ASC';
    } else {
      this.sortingDirection = 'DESC';
    }
  }
}
