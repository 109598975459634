import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Flex from '../shared/components/Flex/Flex';
import Popup from '../shared/components/Popup/Popup';
import PopupModel from '../shared/components/Popup/PopupModel';
import Text from '../shared/components/typography/Text';
import Transport from './Transport/Transport';
import addDecorators from '../shared/methods/addDecorators/addDecorators';
import withModel from '../shared/methods/withModel/withModel';
import ErrorPopup from '../ErrorPopup/ErrorPopup';
import Container from './Transport/Container';
import useUrlLocationQuery from '../shared/methods/useUrlLocationQuery/useUrlLocationQuery';

export function useTransportPopup() {
  const { setQuery } = useUrlLocationQuery();
  const open = (transportId) => {
    setQuery({ transportId });
  };
  const close = () => {
    setQuery({ transportId: null });
  };

  return {
    open,
    close,
  };
}

const TransportPopup = ({ model, removePopupID }) => {
  const {
    openTransport,
    transportLoadError,
    transportCommentsModel: { commentPostError },
  } = model;

  const { close } = useTransportPopup();
  const { getQuery } = useUrlLocationQuery();

  /**
   * Open or close popup if transportId in url
   */
  const transportIdInQuery = getQuery('transportId');
  useEffect(() => {
    if (transportIdInQuery) {
      openTransport(transportIdInQuery);
    } else {
      removePopupID('transportPopup');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportIdInQuery]);

  return (
    <>
      <Popup id="transportPopup" closePopupManually={close}>
        <Transport
          transportPopupModel={model}
          onCloseButtonClick={close}
        />
      </Popup>

      <Popup id="containerPopup">
        <Container
          transportPopupModel={model}
          onCloseButtonClick={() => removePopupID('containerPopup')}
        />
      </Popup>

      <Popup id="transportLoadingPopup">
        <Flex centered fullHeight>
          <Text
            caption
            color="neutral40"
            backgroundColor="neutral0"
            padding={{
              horizontal: 'xl',
              vertical: 'l',
            }}
          >
            Loading Transport...
          </Text>
        </Flex>
      </Popup>

      <ErrorPopup
        id="transportLoadErrorPopup"
        title="Error Loading Transport"
        error={transportLoadError}
        onDismiss={() => removePopupID('transportLoadErrorPopup')}
      />

      <ErrorPopup
        id="transportCommentErrorPopup"
        title="Error Posting Comment"
        error={commentPostError}
        onDismiss={() => removePopupID('transportCommentErrorPopup')}
      />
    </>
  );
};

export default addDecorators(
  observer,
  withModel({ Model: PopupModel, props: ['removePopupID'] }),
).to(TransportPopup);
