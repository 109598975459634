import callBackend from '../shared/methods/callBackend/callBackend';

export type TTrackingRule = {
  originId: string,
  destinationId: string,
  carrierId: string,
  transportModeType: string,
  trackingPartyName: string,
};

const postTrackingRule = async (body: TTrackingRule) => callBackend({
  url: `${process.env.REACT_APP_API_URL}/trackingrules`,
  method: 'POST',
  body,
});

export default postTrackingRule;
