/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import context from './DialogContext';

export default function DialogsMainContainer({ children }) {
  const dialogsStore = useState([]);
  const location = useLocation();

  // if route changed to other page, remove all dialogs
  useEffect(() => {
    const [, setDialog] = dialogsStore;
    setDialog([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <context.Provider value={dialogsStore}>
      {children}
      <context.Consumer>
        {([dialogs]) => dialogs.map((d, i) => (<div key={i}>{d}</div>))}
      </context.Consumer>
    </context.Provider>
  );
}
