import { Router } from 'react-router-dom';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import React from 'react';
import { createBrowserHistory } from 'history';
import getModel from '../withModel/getModel';

const browserHistory = createBrowserHistory();
const routerStore = getModel(RouterStore);
const history = syncHistoryWithStore(browserHistory, routerStore);

export default function withRouter(ToBeDecorated) {
  return ({ children, ...props }) => (
    <Router history={history}>
      <ToBeDecorated {...props}>{children}</ToBeDecorated>
    </Router>
  );
}
