/* eslint-disable no-nested-ternary */
import React from 'react';
import { Icon } from '@storaensods/seeds-react';
import Flex from '../Flex/Flex';
import Div from '../Div/Div';
import Text from '../typography/Text';
import colors from '../../styles/Colors.module.scss';

function CommentBubble({
  numComments, style, ...props
}) {
  return (
    <Flex
      centered
      style={{
        borderRadius: '50%',
        width: '26px',
        height: '26px',
        ...style,
      }}
      {...props}
    >
      <Div style={{
        position: 'absolute',
        display: 'inline-block',
        zIndex: 2,
        color: 'white',
      }}
      >
        <Text captionSmall bold color="black" style={{ fontSize: 10, verticalAlign: 'top' }}>
          {numComments}
        </Text>
      </Div>
      <Div style={{ position: 'absolute', paddingTop: '1px', fontSize: '200%' }}>
        <Icon size="lg" style={{ color: colors.blue100 }}>chat_bubble</Icon>
      </Div>
    </Flex>
  );
}

export default CommentBubble;
