import { decorate, observable } from 'mobx';
import InputModel from '../InputModel/InputModel';

class TextInputModel extends InputModel {
  placeholderValue;

  constructor({ placeholderValue = '', ...rest } = {}) {
    super({
      defaultValue: '',
      ...rest,
    });

    this.placeholderValue = placeholderValue;
  }
}

export default decorate(TextInputModel, { placeholderValue: observable });
