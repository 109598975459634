import React from 'react';
import { Icon } from '@storaensods/seeds-react';
import TrackingStatusEnum from './TrackingStatusEnum';
import Flex from '../../shared/components/Flex/Flex';
import colors from '../../shared/styles/Colors.module.scss';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import TrackingStatusLabel from './TrackingStatusLabel';

export default function TrackingStatusIcon({ trackingStatus, style, ...props }) {
  let color;
  let icon = 'rss_feed';

  switch (trackingStatus) {
    case TrackingStatusEnum.TRACKING_REQUESTED: { color = 'blue100'; break; }
    case TrackingStatusEnum.TRACKING_ONGOING: { color = 'green100'; break; }
    case TrackingStatusEnum.TRACKING_ERROR: { color = 'orange'; break; }
    case TrackingStatusEnum.TRACKING_COMPLETED: { icon = 'flag'; color = 'neutral100'; break; }
    case TrackingStatusEnum.NO_TRACKING: { color = 'neutral40'; break; }
    case TrackingStatusEnum.TRACKING_CANCELLED: { color = 'red100'; break; }
    case TrackingStatusEnum.TRACKING_FAILED: { color = 'red100'; break; }
    default: break;
  }

  const iconElement = (
    <Flex
      {...props}
      style={{
        color: colors[color],
        ...style,
      }}
    >
      <Icon size="lg">{icon}</Icon>
    </Flex>
  );

  return TrackingStatusLabel[trackingStatus] ? (
    <Tooltip placement="top" tooltip={TrackingStatusLabel[trackingStatus]}>
      {iconElement}
    </Tooltip>
  ) : iconElement;
}
