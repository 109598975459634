import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../shared/components/Button/Button';
import Filters from '../Filters/Filters';
import Flex from '../shared/components/Flex/Flex';
import Page from './Page/Page';
import TransportTable from '../TransportsTable/TransportsTable';

function TransportListPage({
  filtersModel,
  transportsModel,
  openTransport,
  sortingModel,
}) {
  const {
    loadTransports,
    transports,
    transportsLoadContRef,
    transportsLoadError,
    transportsLoading,
  } = transportsModel;

  const { filters } = filtersModel;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transportTable = useCallback(<TransportTable
    style={{ opacity: transportsLoading ? 0.5 : 1 }}
    onTransportTrigger={openTransport}
  />, [transports, transportsLoading, transportsLoadError]);

  return (
    <Page title="Transports" icon="home">
      <Flex inline column margin={{ bottom: 'xl' }}>
        <Filters model={filtersModel} />
      </Flex>

      <Flex column margin={{ bottom: 'xxl' }}>
        {transportTable}

        {transports.length > 0 && (
        <Flex
          centeredHorizontally
          style={{
            visibility: transportsLoading ? 'hidden' : 'visible',
          }}
        >
          <Button
            enabled={transportsLoadContRef}
            padding={{ size: 'xl' }}
            onClick={() => transportsLoadContRef
                && loadTransports({ filters, sorting: sortingModel })}
          >
            Load more
          </Button>
        </Flex>
        )}
      </Flex>
    </Page>
  );
}

export default observer(TransportListPage);
