import React from 'react';
import Element from '../Element/Element';
import styles from './TextArea.module.scss';

function TextArea({ rows = 6, placeholder = '', ...props }) {
  return (
    <Element
      tagName="textarea"
      className={styles.textArea}
      rows={rows}
      placeholder={placeholder}
      {...props}
    />
  );
}

export default TextArea;
