import React from 'react';
import getClassNames from 'classnames';
import upperFirst from 'lodash/fp/upperFirst';
import _styles from './Element.module.scss';
import colors from '../../styles/Colors.module.scss';
import getMarginClasses from './getMarginClasses';
import getPaddingClasses from './getPaddingClasses';
import hiddenStyles from '../../styles/Hidden.module.scss';
import shownStyles from '../../styles/Shown.module.scss';

function Element({
  backgroundColor,
  block,
  bold,
  children,
  className,
  color,
  margin,
  noPointerEvents,
  padding,
  pointer,
  fullWidth,
  fullHeight,
  relative,
  absolute,
  shown,
  hidden = shown === undefined ? undefined : !shown,
  hiddenResponsive,
  shownResponsive,
  tagName: Tag = 'span',
  tooltip,
  ...props
}) {
  const classNameParameters = [_styles.element];

  if (backgroundColor) classNameParameters.push(colors[`color_background__${backgroundColor}`]);
  if (block) classNameParameters.push(_styles.element__block);
  if (bold) classNameParameters.push(_styles.element__bold);
  if (pointer) classNameParameters.push(_styles.element__pointer);
  if (noPointerEvents) classNameParameters.push(_styles.element__noPointerEvents);
  if (fullWidth) classNameParameters.push(_styles.element__fullWidth);
  if (fullHeight) classNameParameters.push(_styles.element__fullHeight);
  if (relative) classNameParameters.push(_styles.element__relative);
  if (absolute) classNameParameters.push(_styles.element__absolute);
  if (tooltip) classNameParameters.push(_styles.element__tooltip);
  if (className) classNameParameters.push(className);
  if (color) classNameParameters.push(colors[`color__${color}`]);
  if (hidden !== undefined) {
    classNameParameters.push({
      [_styles.element__hidden]: hidden,
    });
  }
  if (hiddenResponsive) {
    if (hiddenResponsive.thinnerThan) {
      classNameParameters.push(
        hiddenStyles[
          `hidden__thinnerThan${upperFirst(hiddenResponsive.thinnerThan)}`
        ],
      );
    }

    if (hiddenResponsive.widerThan) {
      classNameParameters.push(
        hiddenStyles[
          `hidden__widerThan${upperFirst(hiddenResponsive.widerThan)}`
        ],
      );
    }

    if (hiddenResponsive.at) {
      classNameParameters.push(
        hiddenStyles[`hidden__at${upperFirst(hiddenResponsive.at)}`],
      );
    }
  }
  if (shownResponsive) {
    if (shownResponsive.thinnerThan) {
      classNameParameters.push(
        shownStyles[
          `shown__thinnerThan${upperFirst(shownResponsive.thinnerThan)}`
        ],
      );
    }

    if (shownResponsive.widerThan) {
      classNameParameters.push(
        shownStyles[`shown__widerThan${upperFirst(shownResponsive.widerThan)}`],
      );
    }

    if (shownResponsive.at) {
      classNameParameters.push(
        shownStyles[`shown__at${upperFirst(shownResponsive.at)}`],
      );
    }
  }
  if (margin) classNameParameters.push(getMarginClasses(margin));
  if (padding) classNameParameters.push(getPaddingClasses(padding));

  const classNames = getClassNames(...classNameParameters);

  return (
    <Tag className={classNames} tooltip={tooltip} {...props}>
      {children}
    </Tag>
  );
}

export default Element;
