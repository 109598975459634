import {
  observable,
} from 'mobx';

type TErrorStats = { filtername: string, count: number };
type TFilterStats = {
  id: string,
  path: string | null,
  showBadge: boolean
  badge: number,
  onClick: (() => void) | null,
  Content: (() => void) | null,
  icon: string | null,
  color: string | null,
  isAlert: boolean,
  isDivider: boolean,
  autoOpen: boolean,
  tooltip: string | null,
};

const defaultFilterItem = {
  id: null,
  badge: 0,
  icon: null,
  color: null,
  showBadge: false,
  isAlert: false,
  path: null,
  onClick: null,
  Content: null,
  isDivider: false,
  autoOpen: false,
  tooltip: null,
};

export default class QuickAccessBarModel {
  @observable errors : any = {};

  filters : Array <TFilterStats> = []; // Making this @observable will lead to rendering loop bug

  // @action
  setErrorStats({ filtername, count } : TErrorStats) {
    this.errors[filtername] = count;
  }

  getFilterIndexByName(filtername) {
    return this.filters.map(({ id }) => id).indexOf(filtername);
  }

  // @action
  setFilter({
    id, badge, icon, color, isAlert, path, tooltip,
  }) {
    const index = this.getFilterIndexByName(id);
    const filterToAdd = {
      id,
      badge,
      icon,
      color,
      showBadge: !!badge,
      isAlert,
      path,
      onClick: null,
      Content: null,
      isDivider: false,
      autoOpen: false,
      tooltip,
    };
    if (index >= 0) {
      this.filters[index] = filterToAdd;
    } else {
      this.filters.push(filterToAdd);
    }
  }

  getErrorStats() {
    const onlyErrorsWithStatsBiggerThanZero = {};
    Object.keys(this.errors).forEach((filtername) => {
      if (this.errors[filtername]) {
        onlyErrorsWithStatsBiggerThanZero[filtername] = this.errors[filtername];
      }
    });
    return onlyErrorsWithStatsBiggerThanZero;
  }

  getAlerts() {
    return this.filters ? this.filters.filter(({ isAlert }) => isAlert) : [];
  }

  updateAlerts(listOfFilterNamesAndValues) {
    const newFilters : Array <TFilterStats> = [];
    Object.keys(listOfFilterNamesAndValues).forEach((filtername) => {
      const {
        props: {
          isAddToAccessBar,
          alertIcon,
          alertColor,
        },
      } = listOfFilterNamesAndValues[filtername];
      let oldFilter = {};
      const index = this.getFilterIndexByName(filtername);
      if (index >= 0) {
        oldFilter = this.filters[index];
      }
      newFilters.push({
        ...defaultFilterItem,
        ...oldFilter,
        id: filtername,
        isAlert: isAddToAccessBar,
        icon: alertIcon ? alertIcon.value : null,
        color: alertColor ? alertColor.value : null,
      });
    });
    this.filters = newFilters;
  }

  clearAlerts() {
    this.filters = [];
  }

  // @action
  updateCounter({
    filtername, counters, filterProps, path,
  }) {
    this.setErrorStats({
      filtername,
      count: counters.transportsWithTrackingError,
    });
    this.setFilter({
      id: filtername,
      badge: counters.totalTransports,
      isAlert: filterProps.isAddToAccessBar,
      icon: filterProps.alertIcon ? filterProps.alertIcon.value : null,
      color: filterProps.alertColor ? filterProps.alertColor.value : null,
      path,
      tooltip: filtername,
    });
  }

  updateAllCounter(filtersList) {
    const newFilters: Array<TFilterStats> = [];
    const newErrors = {};
    this.filters = filtersList.forEach(({
      filtername, path, counters, filterProps,
    }) => {
      newErrors[filtername] = counters.transportsWithTrackingError;
      newFilters.push({
        id: filtername,
        badge: counters.totalTransports,
        icon: filterProps.alertIcon ? filterProps.alertIcon.value : null,
        color: filterProps.alertColor ? filterProps.alertColor.value : null,
        showBadge: !!counters.totalTransports,
        isAlert: filterProps.isAddToAccessBar,
        path,
        onClick: null,
        Content: null,
        isDivider: false,
        autoOpen: false,
        tooltip: filtername,
      });
    });

    this.filters = newFilters;
    this.errors = newErrors;
  }
}
