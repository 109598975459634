import { computed, observable } from 'mobx';

export interface ISavedFilterProps {
  isUseCurrentDateForDeparture?: boolean,
  isUseCurrentDateForArrival?: boolean,
  emails: string,
  isAddToAccessBar: boolean,
  alertIcon: { value: string },
  alertColor: { value: string },
}

export interface ISavedFilterValuesAndProps {
  props: ISavedFilterProps,
  values: any,
}

export interface ISavedFilterNameAndValuesDictionary {
  [filterName: string]: ISavedFilterValuesAndProps,
}

export interface ISavedFilters {
  latestFilter: any,
  selectedFilterName: string | null,
  listOfFilterNamesAndValues: ISavedFilterNameAndValuesDictionary,
}

export default class SavedFilterListModel {
  @observable latestFilter: any = {};

  @observable selectedFilterName: string | null = null;

  @observable listOfFilterNamesAndValues: ISavedFilterNameAndValuesDictionary = {};

  set(obj: ISavedFilters) {
    const {
      latestFilter,
      selectedFilterName,
      listOfFilterNamesAndValues,
    } = obj;

    this.latestFilter = latestFilter || {};
    this.selectedFilterName = selectedFilterName || null;
    this.listOfFilterNamesAndValues = listOfFilterNamesAndValues || {};
  }

  getSavedFilterValuesAndPropsByName(name: string): ISavedFilterValuesAndProps {
    return this.listOfFilterNamesAndValues[name] || { props: {}, values: {} };
  }

  getCurrentFilterName() {
    return this.selectedFilterName;
  }

  getCurrentFilterValuesAndProps() {
    if (this.selectedFilterName) {
      return this.getSavedFilterValuesAndPropsByName(this.selectedFilterName);
    }

    return { values: this.latestFilter };
  }

  clearCurrentFilterValues() {
    this.latestFilter = {};
    this.selectedFilterName = null;
  }

  updateLatestFilterValue(selectedFilterName, latestfilterValues) {
    this.latestFilter = latestfilterValues;
    this.selectedFilterName = selectedFilterName;
  }

  updateSavedFilterValues(
    filterName,
    filterValues,
    {
      emails,
      isAddToAccessBar,
      alertIcon,
      alertColor,
    },
  ) {
    this.latestFilter = {};
    this.selectedFilterName = filterName;
    this.listOfFilterNamesAndValues[filterName] = {
      values: filterValues,
      props: {
        emails,
        isAddToAccessBar,
        alertIcon,
        alertColor,
      },
    };
  }

  removeSavedFiltersNameAndValues(filterNames) {
    const names = Object.keys(this.listOfFilterNamesAndValues || {});
    this.listOfFilterNamesAndValues = names.reduce((acc, name) => {
      if (!filterNames.includes(name)) {
        acc[name] = this.listOfFilterNamesAndValues[name];
      }
      return acc;
    }, {});
  }

  @computed
  get savedFiltersNames() {
    return Object.keys(this.listOfFilterNamesAndValues || {});
  }

  @computed
  get savedFiltersNamesWithLabels() {
    const names = Object.keys(this.listOfFilterNamesAndValues || {});
    return names.map((filterName) => ({
      label: filterName,
      value: filterName,
    }));
  }

  @computed
  get savedFilters() {
    return {
      latestFilter: this.latestFilter,
      selectedFilterName: this.selectedFilterName,
      listOfFilterNamesAndValues: this.listOfFilterNamesAndValues,
    };
  }
}
