import React, { useEffect, useContext } from 'react';
import useUrlLocationQuery from '../shared/methods/useUrlLocationQuery/useUrlLocationQuery';
import AppContext from '../AppContext';
import useCountersUpdate from './useCountersUpdate';

export default function withFilterInit(FilterToBeInit) {
  return ({ children }) => {
    const {
      filtersModel,
      transportsModel,
    } = useContext(AppContext);

    const { updateTransportCounts, updateAllTransportCounts } = useCountersUpdate();

    const {
      getQuery, setQuery, location,
    } = useUrlLocationQuery();
    const filternameFromUrl = getQuery('filtername');
    const filtersFromUrl = getQuery('filters');

    filtersModel.setOnUpdateSavedFilterInUrlSearch((queryParams) => {
      setQuery(queryParams || { filtername: null, filters: null });
    });

    const isTransportList = () => ['/', '/transports'].includes(location.pathname);

    useEffect(() => {
      if (!transportsModel.transports.length) {
        filtersModel.init(
          transportsModel.loadTransports, updateTransportCounts, updateAllTransportCounts,
        ).then(() => {
          if (filtersFromUrl) {
            filtersModel.setFiltersFromUrl(filtersFromUrl);
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isTransportList() && filternameFromUrl) {
        filtersModel.setSavedFilterValuesByName(filternameFromUrl);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filternameFromUrl, location.pathname]);
    return (
      <FilterToBeInit>
        {children}
      </FilterToBeInit>
    );
  };
}
