import React from 'react';
import { DataTable, Icon, Spinner } from '@storaensods/seeds-react';
import Div from '../shared/components/Div/Div';
import Flex from '../shared/components/Flex/Flex';

export default function TrackingRulesTable({
  data, onEdit, onRemove, loading,
}) {
  const columns = [
    {
      header: 'Pick Up Location ID',
      key: 'originId',
      minWidth: '200px',
    },
    {
      header: 'Drop Off Location ID',
      key: 'destinationId',
      minWidth: '200px',
    },
    {
      header: 'Carrier ID',
      key: 'carrierId',
      minWidth: '200px',
    },
    {
      header: 'Transport Mode',
      key: 'transportModeType',
      minWidth: '200px',
    },
    {
      header: 'Tracker',
      key: 'trackingPartyName',
      minWidth: '200px',
    },
    {
      header: 'License Plate Required',
      key: 'isLicensePlateNumberRequired',
      minWidth: '200px',
    },
    {
      content: (value, item) => (
        <Icon onClick={() => { onEdit(item); }}>
          edit
        </Icon>
      ),
      header: '',
      key: 'edit',
      sorting: false,
      width: '30px',
    },
    {
      content: (value, item) => (
        <Icon onClick={() => { onRemove(item.id); }}>
          delete
        </Icon>
      ),
      header: '',
      key: 'delete',
      sorting: false,
      width: '30px',
    },
  ];

  return (
    <Div relative style={{ width: '100%' }}>
      {loading && (
        <Flex
          absolute
          centered
          fullWidth
          fullHeight
          style={{ backgroundColor: '#ffffffaa', zIndex: 1 }}
        >
          <Spinner size="lg" />
        </Flex>
      )}
      <DataTable
        columns={columns}
        data={data}
      />
    </Div>
  );
}
