import { useContext } from 'react';
import { toast } from 'react-toastify';
import getTransportCounts from './getTransportCounts';
import AppContext from '../AppContext';

export default function useCountersUpdate() {
  const {
    filtersModel,
    quickAccessBarModel,
  } = useContext(AppContext);

  async function getTransportCountsFromAPI(filtername) {
    const {
      values: filterValues,
    } = filtersModel.getSavedFilterValuesAndPropsByName(filtername);

    const { response } = await getTransportCounts({ filters: filterValues || {} });

    const counters = {
      totalTransports: response.totalTransports,
      transportsWithTrackingError: response.transportsWithTrackingError,
    };

    return counters;
  }

  function updateCountsInQuickAccessBar({ filtername, counters }) {
    const {
      props: filterProps,
    } = filtersModel.getSavedFilterValuesAndPropsByName(filtername);

    quickAccessBarModel.updateCounter({
      filtername,
      counters,
      filterProps: filterProps || {},
      path: `/?filtername=${filtername}`,
    });
  }

  async function updateTransportCounts(filtername) {
    // Note:
    // that here we do two things: we get the total transport count for the Quick Action Filters
    // (filters set by the user to show up in the Quick Action Menu)
    // and also the erroneous transport count (for all the filters).
    try {
      const counters = await getTransportCountsFromAPI(filtername);

      updateCountsInQuickAccessBar({ filtername, counters });
    } catch (err) {
      toast.error('Fetching transports stats fail');
    }
  }

  async function updateAllTransportCounts() {
    try {
      const { savedFiltersNames } = filtersModel;
      const countersPromise = savedFiltersNames.map(
        (filtername) => getTransportCountsFromAPI(filtername),
      );
      const counters = await Promise.all(countersPromise);

      const filtersList = savedFiltersNames.map((filtername, i) => {
        const {
          props: filterProps,
        } = filtersModel.getSavedFilterValuesAndPropsByName(filtername);
        return {
          filtername,
          counters: counters[i],
          filterProps: filterProps || {},
          path: `/?filtername=${filtername}`,
        };
      });

      quickAccessBarModel.updateAllCounter(filtersList);
    } catch (err) {
      toast.error('Error while fetching all transports stats');
    }
  }

  return {
    updateTransportCounts,
    updateAllTransportCounts,
  };
}
