import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@storaensods/seeds-react';
import TrackingRulesForm from '../TrackingRules/TrackingRulesForm';
import TrackingExcludeForm from '../TrackingRules/TrackingExcludeForm';
import TrackingRulesTable from '../TrackingRules/TrackingRulesTable';
import TrackingExcludedTable from '../TrackingRules/TrackingExcludedTable';
import Div from '../shared/components/Div/Div';
import Page from './Page/Page';
import useDialog from '../shared/components/Dialog/useDialog';

function TrackingRules({ model }) {
  const {
    rulesList,
    excludedList,
    loadingRules,
    loadingExcluded,
  } = model;

  const { addDialog, removeLastDialog } = useDialog();
  const [selectedTab, setSelectedTab] = useState('table');

  useEffect(() => {
    model.loadTrackingRules();
    model.loadTrackingExcludes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRuleSubmit = (values) => {
    model.addTrackingRulesModel(values);
    removeLastDialog();
  };

  const onExcludeSubmit = (values) => {
    model.addExcludedLocationModel(values);
    removeLastDialog();
  };

  const onUpdate = (values) => {
    model.updateTrackingRulesModel(values);
    removeLastDialog();
  };

  const tabContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: '30px',
  };

  const tabStyle = {
    paddingRight: '20px',
    paddingLeft: '20px',
    fontSize: '20px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  const handleClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Page title="Tracking Rules">
      <Div style={tabContainerStyle}>
        <Div
          style={{ ...tabStyle, borderBottom: selectedTab === 'table' ? '5px solid' : '' }}
          onClick={() => handleClick('table')}
        >
          Tracking rules table
        </Div>
        <Div
          style={{ ...tabStyle, borderBottom: selectedTab === 'exclude' ? '5px solid' : '' }}
          onClick={() => handleClick('exclude')}
        >
          Excluded locations
        </Div>
      </Div>
      {selectedTab === 'table' && (
        <>
          <Button
            disabled={loadingRules}
            onClick={() => {
              addDialog(<TrackingRulesForm onSubmit={onRuleSubmit} />);
            }}
          >
            Add Tracking rule
          </Button>
          <Div margin={{ bottom: 'l' }}>
            <TrackingRulesTable
              loading={loadingRules}
              data={rulesList}
              onRemove={(id) => {
                addDialog('Are you sure you want to delete?', {
                  buttons: [
                    {
                      type: 'negative',
                      label: 'Yes',
                      onClick: async () => {
                        await model.removeRule(id);
                        return true;
                      },
                    },
                    {
                      label: 'No',
                      onClick: () => true,
                    },
                  ],
                });
              }}
              onEdit={(item) => {
                addDialog(
                  <TrackingRulesForm
                    data={item}
                    onSubmit={onUpdate}
                  />,
                );
              }}
            />
          </Div>
        </>
      )}
      {selectedTab === 'exclude' && (
        <>
          <Button
            disabled={loadingExcluded}
            onClick={() => {
              addDialog(<TrackingExcludeForm onSubmit={onExcludeSubmit} />);
            }}
          >
            Exclude location from tracking
          </Button>
          <Div margin={{ bottom: 'l' }}>
            <TrackingExcludedTable
              loading={loadingExcluded}
              data={excludedList}
              onRemove={(id) => {
                addDialog('Are you sure you want to delete?', {
                  buttons: [
                    {
                      type: 'negative',
                      label: 'Yes',
                      onClick: async () => {
                        await model.removeExcluded(id);
                        return true;
                      },
                    },
                    {
                      label: 'No',
                      onClick: () => true,
                    },
                  ],
                });
              }}
            />
          </Div>
        </>
      )}
    </Page>
  );
}

export default observer(TrackingRules);
