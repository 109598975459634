import React from 'react';
import Flex from '../Flex/Flex';
import styles from './PinIcon.module.scss';
import Div from '../Div/Div';

function PinIcon({ isPinned, makeLarger, ...props }) {
  return (
    <Flex
      className={[
        !isPinned && styles.pinIcon_notPinned,
        makeLarger && styles.pinIcon_larger,
      ]}
      centered
      fullHeight={false}
      padding={{ size: 's' }}
      pointer
      {...props}
    >
      <Div className={styles.pinIcon} title="Pin transport in list for easier reference">
        <i className="fas fa-thumbtack" />
      </Div>
    </Flex>
  );
}

export default PinIcon;
