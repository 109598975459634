import callBackend from '../shared/methods/callBackend/callBackend';

export type TTrackingRule = {
  originId: string,
  destinationId: string,
  carrierId: string,
  transportModeType: string,
  trackingPartyName: string,
};

const putTrackingRule = async (ruleId, body: TTrackingRule) => callBackend({
  url: `${process.env.REACT_APP_API_URL}/trackingrules/${ruleId}`,
  method: 'PUT',
  body,
});

export default putTrackingRule;
