import React from 'react';
import Flex from '../../../shared/components/Flex/Flex';
import Grid from '../../../shared/components/Grid/Grid';
import Text from '../../../shared/components/typography/Text';
import TransportCaption from '../../../TransportCaption/TransportCaption';
import DepartureCell from '../../../TransportsTable/DepartureCell/DepartureCell';
import ArrivalCell from '../../../TransportsTable/ArrivalCell/ArrivalCell';

function TransportDepartureAndArrival({ transportModel, ...props }) {
  const {
    loadingPlannedTo,
    departureActual,
    arrivalRequestedTo,
    arrivalEstimated,
    arrivalActual,
  } = transportModel;

  return (
    <Grid fullWidth columns="1fr 1fr" {...props}>
      <Text
        caption
        bold
        padding={{ bottom: 's' }}
        style={{ msGridRow: 1, msGridColumn: 1 }}
        color="neutral100"
      >
        Departure
      </Text>

      <Text
        caption
        bold
        padding={{ bottom: 's' }}
        style={{ msGridRow: 1, msGridColumn: 2 }}
        color="neutral100"
      >
        Arrival
      </Text>

      <Flex padding={{ bottom: 's' }} style={{ msGridRow: 2, msGridColumn: 1 }}>
        <TransportCaption color="neutral40" date={loadingPlannedTo} />
        <TransportCaption color="neutral40" time={loadingPlannedTo} />
        <TransportCaption color="neutral40" text="PTD" />
      </Flex>

      <Flex padding={{ bottom: 's' }} style={{ msGridRow: 2, msGridColumn: 2 }}>
        <TransportCaption color="neutral40" date={arrivalRequestedTo} />
        <TransportCaption color="neutral40" time={arrivalRequestedTo} />
        <TransportCaption color="neutral40" text="RTA" />
      </Flex>

      <Flex padding={{ bottom: 's' }} style={{ msGridRow: 3, msGridColumn: 1 }}>
        {departureActual && <DepartureCell value={transportModel} />}
      </Flex>

      <Flex padding={{ bottom: 's' }} style={{ msGridRow: 3, msGridColumn: 2 }}>
        {
          (!arrivalActual && !arrivalEstimated) ? <TransportCaption color="neutral40" text="No PTA" />
            : (arrivalActual || arrivalEstimated) && (<ArrivalCell value={transportModel} />)
        }
      </Flex>
    </Grid>
  );
}

export default TransportDepartureAndArrival;
