import { computed } from 'mobx';
import SelectModel from '../../shared/components/Select/SelectModel';
import DivisionLabel from '../../TransportPopup/Transport/DivisionLabel';

export default class DivisionFilterModel {
  divisionModel = new SelectModel();

  @computed
  get filters() {
    return {
      ...(this.divisionModel.value && {
        division: this.divisionModel.getValue(),
      }),
    };
  }

  @computed
  get tags() {
    return {
      division: {
        list: this.divisionModel.getLabelAndValue(),
        remove: (value) => { this.divisionModel.remove(value); },
      },
    };
  }

  getFilterOptions() {
    return {
      divisions: this.divisionModel.options,
    };
  }

  copyFilterOptions(options) {
    this.divisionModel.options = options.divisions;
  }

  setFilterOptions() {
    const options = Object.keys(DivisionLabel).map((key) => ({
      value: key,
      label: DivisionLabel[key],
    }));

    this.divisionModel.setOptions(options);
  }

  setFilterValues = (filterValues) => {
    if (filterValues.division) {
      this.divisionModel.setValue(filterValues.division);
    } else {
      this.divisionModel.setValue(null);
    }
  };
}
