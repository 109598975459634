import callBackend from '../../../shared/methods/callBackend/callBackend';

const getFromMapsService = async (address) => {
  const url = `${process.env.REACT_APP_API_URL}/mapservice/${address}`;

  return callBackend({
    url,
    method: 'GET',
  });
};

export default getFromMapsService;
