import callBackend from '../shared/methods/callBackend/callBackend';

export type OpeningHours = {
  id: string,
  locationId: string,
  close: string,
  dayOfWeek: number,
  date: Date,
  timeZone: string,
};

const postOpeningHours = async (body: OpeningHours) => callBackend({
  url: `${process.env.REACT_APP_API_URL}/openinghours`,
  method: 'POST',
  body,
});

export default postOpeningHours;
