import React, { useContext } from 'react';
import { Icon } from '@storaensods/seeds-react';
import { Link } from 'react-router-dom';
import Heading from '../../../shared/components/typography/Heading';
import Div from '../../../shared/components/Div/Div';
import Badge from '../../../shared/components/Badge/Badge';
import styles from '../QuickAccessItems.module.scss';
import AppContext from '../../../AppContext';

export default function AlertsQuickAccess({ alerts }) {
  const { filtersModel } = useContext(AppContext);

  const icon = (filtername) => {
    const { props } = filtersModel.getSavedFilterValuesAndPropsByName(filtername);
    const { alertIcon } = props || {};
    return alertIcon ? alertIcon.value : 'notifications';
  };

  const color = (filtername) => {
    const { props } = filtersModel.getSavedFilterValuesAndPropsByName(filtername);
    const { alertColor } = props || {};
    return alertColor ? alertColor.value : null;
  };

  return (
    <Div>
      <Heading tagName="h6" style={{ margin: '20px 0' }}>Alerts</Heading>
      <Div>
        {alerts.map(({ id, badge }) => (
          <Link
            to={`/?filtername=${id}`}
            key={id}
          >
            <Badge
              isOnRight
              value={badge}
              className={styles.itemContainer}
            >
              <Icon style={{ color: color(id) }}>
                {icon(id)}
              </Icon>
              {id}
            </Badge>
          </Link>
        ))}
        {!alerts.length && (
          <span>No Alerts found</span>
        )}
      </Div>
    </Div>
  );
}
