import {
  action, observable,
} from 'mobx';
import { toast } from 'react-toastify';
import setTransportTrackingStatus from './setTransportTrackingStatus';
import TrackingStatusEnum from './TrackingStatusEnum';

export const TrackingStatusRequestEnum = {
  REQUEST_TRACKING: 'REQUEST_TRACKING',
  CANCEL_TRACKING: 'CANCEL_TRACKING',
};

export const TrackingStatusRequestLabel = {
  REQUEST_TRACKING: 'Request Tracking',
  CANCEL_TRACKING: 'Cancel Tracking',
};

type TTrackingStatusActionItem = {
  label: string,
  onClick: any,
}

export default class TrackingStatysRequestModel {
  @observable loading = false;

  transportModel;

  setTransport(transportModel) {
    this.transportModel = transportModel;
  }

  @action
  async requestTrackingStatus(STATUS) {
    const { transportId } = this.transportModel;
    if (!transportId) {
      return null;
    }
    this.loading = true;
    try {
      const result = await setTransportTrackingStatus(transportId, STATUS);
      if (result.callWasSuccessful) {
        toast('Request received, please refresh later.');
      } else {
        toast.warn('Updating Tracking status failed, please try again later');
      }
    } catch (err) {
      toast.error('Connection failed, please contact IT support.');
    }
    this.loading = false;
    return null;
  }

  getTrackingStatusActionItems() {
    const { trackingStatus } = this.transportModel;
    const trackingStatusActionItems = [] as Array<TTrackingStatusActionItem>;

    const requestTrackingActionItem = {
      label: TrackingStatusRequestLabel.REQUEST_TRACKING,
      onClick: () => this.requestTrackingStatus(TrackingStatusRequestEnum.REQUEST_TRACKING),
    };
    const cancelTrackingActionItem = {
      label: TrackingStatusRequestLabel.CANCEL_TRACKING,
      onClick: () => this.requestTrackingStatus(TrackingStatusRequestEnum.CANCEL_TRACKING),
    };

    switch (trackingStatus) {
      case TrackingStatusEnum.NO_TRACKING:
        trackingStatusActionItems.push(requestTrackingActionItem);
        break;
      case TrackingStatusEnum.TRACKING_REQUESTED:
      case TrackingStatusEnum.TRACKING_ONGOING:
        trackingStatusActionItems.push(cancelTrackingActionItem);
        break;
      case TrackingStatusEnum.TRACKING_ERROR:
        trackingStatusActionItems.push(requestTrackingActionItem);
        trackingStatusActionItems.push(cancelTrackingActionItem);
        break;
      case TrackingStatusEnum.TRACKING_COMPLETED:
      default:
        break;
    }

    return trackingStatusActionItems;
  }
}
