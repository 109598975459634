import './shared/fonts/HelveticaNeue/HelveticaNeue.scss';
// import '@storaensods/se-design-system/dist/scss/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import '@storaensods/seeds-core/dist/css/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'mobx-react-lite/batchingForReactDom';
import { AzureAD } from 'react-aad-msal';
import App from './App';
import { createAuthProvider } from './authProvider';
import { UserProvider, UserModel } from './User';

const authProvider = createAuthProvider();

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin>
    {(userAgentApplication) => {
      const user = new UserModel(userAgentApplication);
      return (
        <UserProvider value={user}>
          <App />
        </UserProvider>
      );
    }}
  </AzureAD>,
  document.getElementById('root'),
);
