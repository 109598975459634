import React, { useState } from 'react';
import { Input } from '@storaensods/seeds-react';
import Text from '../../shared/components/typography/Text';
import Div from '../../shared/components/Div/Div';

function OrderNumberFilter({
  orderNumberFilterModel, filterOptionsLoading,
}) {
  const { orderNumberModel } = orderNumberFilterModel;
  const [timeOutInterval, setTimeoutInterval] = useState(null);
  const [currentValue, setCurrentValue] = useState(null);

  return (
    <Div>
      {/* Text renamed from Order Number to References */}
      <Text
        captionSmall
        bold
        padding={{ bottom: 's' }}
        color="neutral100"
        style={{ msGridRow: 1, msGridColumn: 1 }}
      >
        References
      </Text>

      <Div style={{ msGridRow: 2, msGridColumn: 1 }}>
        <Input
          size="sm"
          disabled={filterOptionsLoading}
          placeholder="Mill Order Line, Customer Order, Call Off, Loading Agreement..."
          value={currentValue || ''}
          onChange={(ev) => {
            const { value } = ev.target;
            setCurrentValue(value);
            clearTimeout(timeOutInterval);
            const interval = setTimeout(() => {
              orderNumberModel.setValue(value);
            }, 600);
            setTimeoutInterval(interval);
          }}
        />
      </Div>
    </Div>
  );
}

export default OrderNumberFilter;
