import React from 'react';
import moment from 'moment';
import { DataTable, Icon, Spinner } from '@storaensods/seeds-react';
import Div from '../shared/components/Div/Div';
import Flex from '../shared/components/Flex/Flex';
import { dayOfWeekOptions } from './OpeningHoursModel';

export default function OpeningHoursTable({
  data, onEdit, onRemove, loading,
}) {
  const columns = [
    {
      header: 'LocationId',
      key: 'locationId',
    },
    {
      header: 'Open',
      key: 'open',
    },
    {
      header: 'Close',
      key: 'close',
    },
    {
      header: 'Day of week',
      key: (k) => (k.dayOfWeek == null
        ? null
        : dayOfWeekOptions.filter(
          (item) => item.value === k.dayOfWeek,
        )[0].label),
    },
    {
      header: 'Date',
      key: (k) => (k.date == null ? null : moment(k.date).local().format('DD-MM-YYYY')),
    },
    {
      header: 'TimeZone',
      key: 'timeZoneId',
    },
    {
      content: (value, item) => (
        <Icon onClick={() => { onEdit(item); }}>
          edit
        </Icon>
      ),
      header: '',
      key: 'edit',
      sorting: false,
      width: '30px',
    },
    {
      content: (value, item) => (
        <Icon onClick={() => { onRemove(item.id); }}>
          delete
        </Icon>
      ),
      header: '',
      key: 'delete',
      sorting: false,
      width: '30px',
    },
  ];

  return (
    <Div relative style={{ maxWidth: 1024 }}>
      {loading && (
        <Flex
          absolute
          centered
          fullWidth
          fullHeight
          style={{ backgroundColor: '#ffffffaa', zIndex: 1 }}
        >
          <Spinner size="lg" />
        </Flex>
      )}
      <DataTable
        columns={columns}
        data={data}
      />
    </Div>
  );
}
