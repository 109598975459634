import callBackend from '../shared/methods/callBackend/callBackend';

const getTimezones = async () => {
  const url = `${process.env.REACT_APP_API_URL}/openinghours/timezones`;

  const result = await callBackend({ url, method: 'GET' });

  return result.response;
};

export default getTimezones;
