import { toast } from 'react-toastify';
import { createAuthProvider, accessTokenAuthParams } from '../authProvider';

const uploadLoadplanPM = async (fileInputEvent) => {
  const files = Array.from(fileInputEvent.target.files);
  let returnValue;

  if (files.length === 0) {
    return;
  }

  const formData = new FormData();

  files.forEach((file, i) => {
    formData.append(i, file);
  });

  const url = `${process.env.REACT_APP_API_URL}/loadplan`;

  try {
    const authProvider = createAuthProvider();
    const token = await authProvider.getAccessToken(accessTokenAuthParams);

    await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    });

    returnValue = { ok: true };
  } catch (error) {
    returnValue = {
      callWasSuccessful: false,
      error,
    };
  }

  if (returnValue.ok) {
    toast('PM loadplan message sent successfully');
  } else {
    toast.error('Error sending PM loadplan message');
  }

  // eslint-disable-next-line consistent-return
  return returnValue;
};

export default uploadLoadplanPM;
