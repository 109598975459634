import callBackend from '../shared/methods/callBackend/callBackend';

const saveFilterValues = async (filters) => {
  const url = `${process.env.REACT_APP_API_URL}/user/filters`;

  const result = await callBackend({
    url,
    method: 'POST',
    body: filters,
  });
  return result;
};

export default saveFilterValues;
