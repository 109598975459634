/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, ButtonGroup } from '@storaensods/seeds-react';
import classnames from 'classnames';
import styles from './Dialog.module.scss';
import useDialog from './useDialog';

type DialogProps = {
  children: any,
  onClose?: () => void,
  buttons?: Array<{
    onClick: () => boolean,
    label: string,
    icon?: string,
    type?: string,
  }>,
  showCloseButton?: boolean,
}

export default function Dialog({
  children, onClose, buttons, showCloseButton = true,
}: DialogProps) {
  const { removeLastDialog } = useDialog();

  const close = () => {
    removeLastDialog();
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={classnames([styles.dialogOverlay])}>
      <div className={styles.dialogConatiner}>
        <div className={styles.dialogHeader}>
          {showCloseButton && <Button flat icon="close" onClick={close} />}
        </div>
        <div className={styles.dialogBody}>
          {children}
        </div>
        <div className={styles.dialogFooter}>
          {buttons && (
            <ButtonGroup size="sm">
              {buttons.map((btn, i) => (
                <Button
                  type={btn.type}
                  key={`button-${i}`}
                  icon={btn.icon}
                  onClick={() => {
                    const result = btn.onClick();
                    if (result) {
                      close();
                    }
                  }}
                >
                  {btn.label}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </div>
      </div>
    </div>
  );
}
