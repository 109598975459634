import React, { useState } from 'react';
import classNames from 'classnames';
import Div from '../Div/Div';
import styles from './DropMenu.module.scss';

export default function DropMenu({
  disabled, items, children, ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Div
      {...props}
      styles={{ opacity: disabled ? 0.33 : 1 }}
      onClick={() => setIsOpen(!disabled && !isOpen)}
      className={styles.dropMenuContainer}
    >
      {children}
      {isOpen && <Div className={styles.overlay} onClick={() => setIsOpen(false)} />}
      <Div className={classNames([styles.dropMenu], { [styles.active]: isOpen })}>
        {items.length ? items.map(({ label, onClick }) => (
          <Div
            key={label}
            onClick={onClick}
            className={styles.dropMenuItem}
          >
            {label}
          </Div>
        )) : (
          <Div className={[styles.dropMenuItem, styles.disabled]}>No action required</Div>
        )}
      </Div>
    </Div>
  );
}
