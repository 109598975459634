import React from 'react';
import { observer } from 'mobx-react';
import Button from '../../../shared/components/Button/Button';
import Div from '../../../shared/components/Div/Div';
import Flex from '../../../shared/components/Flex/Flex';
import Text from '../../../shared/components/typography/Text';
import TextArea from '../../../shared/components/TextArea/TextArea';
import TransportCaption from '../../../TransportCaption/TransportCaption';
import A from '../../../shared/components/A/A';

function TransportComments({
  comments, model, onComment, ...props
}) {
  const { commentIsBeingPosted, inputModel } = model;

  const onNativeChange = ({ target: { value } }) => inputModel.setValue(value);

  return (
    <Flex column alignRight style={{ width: '80%' }} {...props}>
      {comments
        && comments.map((comment, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Div key={index} fullWidth margin={{ top: 's', bottom: 'm' }}>
            <A bold href={`mailto:${comment.author.email}`}>
              {comment.author.name}
            </A>

            <Text margin={{ top: 's', bottom: 'xs' }}>{comment.text}</Text>

            <Flex>
              <TransportCaption color="neutral40" date={comment.creationTime} />
              <TransportCaption color="neutral40" time={comment.creationTime} />
            </Flex>
          </Div>
        ))}

      <Div fullWidth margin={{ top: 'm', bottom: 's' }}>
        <TextArea
          value={inputModel.value}
          fullWidth
          placeholder="Enter comment..."
          onChange={onNativeChange}
        />
      </Div>

      <Button
        enabled={!commentIsBeingPosted}
        onClick={() => onComment(inputModel.value)}
      >
        {commentIsBeingPosted ? 'POSTING COMMENT...' : 'POST'}
      </Button>
    </Flex>
  );
}

export default observer(TransportComments);
