import { action, observable } from 'mobx';

export default class SelectModel {
  @observable options;

  @observable value;

  @action
    setOptions = (options) => {
      this.options = options;
      this.setValue(null);
    };

  // @computed
  // get compOptions() {
  //   return this.options.map(({value, label}) => ({value, label}));
  // }

  getValueFromOption(value) {
    const v = this.options ? this.options.toJS().filter((opt) => opt.value === value) : [];
    return v.length ? v[0] : null;
  }

  /**
   * set value if
   * • <string>
   * • <Obj{ value: string, label: string}>
   * • <Array(string)>
   * • <Array(Obj{ value: string, label: string})>
   */
  @action
    setValue = (value) => {
      if (Array.isArray(value)) {
        this.value = value.length
          ? value.map((v) => (v && v.value ? v : this.getValueFromOption(v)))
          : null;
      } else if (value && value.value === undefined) {
        this.value = this.getValueFromOption(value);
      } else {
        this.value = value;
      }
    };

  getValue() {
    return Array.isArray(this.value)
      ? this.value.map((v) => v && v.value)
      : this.value && this.value.value;
  }

  getLabelAndValue() {
    return this.value;
  }

  // setMultiValues = values => {
  //   this.value = this.options.toJS().filter(opt => values.includes(opt.value));
  // }

  remove(value) {
    const newValues = this.getValue().filter((v) => v !== value);
    this.setValue(newValues);
  }
}
