import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@storaensods/seeds-react';
import OpeningHoursForm from '../OpeningHours/OpeningHoursForm';
import OpeningHoursTable from '../OpeningHours/OpeningHoursTable';
import Div from '../shared/components/Div/Div';
import Page from './Page/Page';
import useDialog from '../shared/components/Dialog/useDialog';
import getTimezones from '../OpeningHours/getTimezones';

function OpeningHours({ model }) {
  const {
    list,
    loading,
  } = model;

  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    const asyncCall = async () => {
      const result = await getTimezones();

      setTimezones(result);
    };

    asyncCall();
  }, []);

  const { addDialog, removeLastDialog } = useDialog();

  useEffect(() => {
    model.loadOpeningHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values) => {
    model.addOpeningHoursModel(values);
    removeLastDialog();
  };

  const onUpdate = (values) => {
    model.updateOpeningHoursModel(values);
    removeLastDialog();
  };

  return (
    <Page title="OpeningHours">
      <Div margin={{ bottom: 'l' }}>
        <OpeningHoursTable
          loading={loading}
          data={list}
          onRemove={(id) => {
            addDialog('Are you sure you want to delete?', {
              buttons: [
                {
                  type: 'negative',
                  label: 'Yes',
                  onClick: async () => {
                    await model.remove(id);
                    return true;
                  },
                },
                {
                  label: 'No',
                  onClick: () => true,
                },
              ],
            });
          }}
          onEdit={(item) => {
            addDialog(
              <OpeningHoursForm
                data={item}
                timezones={timezones}
                onSubmit={onUpdate}
              />,
            );
          }}
        />
      </Div>
      <Button
        disabled={loading}
        onClick={() => {
          addDialog(<OpeningHoursForm timezones={timezones} onSubmit={onSubmit} />);
        }}
      >
        Add opening hours
      </Button>
    </Page>
  );
}

export default observer(OpeningHours);
