import { computed } from 'mobx';
import OrderNumberInputModel from './OrderNumberInputModel';

export default class OrderNumberFilterModel {
  orderNumberModel = new OrderNumberInputModel();

  apiProperty = 'orderNumber';

  @computed
  get filters() {
    return {
      ...(this.orderNumberModel.value && {
        [this.apiProperty]: this.orderNumberModel.value,
      }),
    };
  }

  @computed
  get tags() {
    return {
      [this.apiProperty]: {
        list: this.orderNumberModel.getLabelAndValue(),
        remove: () => { this.orderNumberModel.remove(); },
      },
    };
  }

  setFilterValues = (filterValues) => {
    if (filterValues[this.apiProperty]) {
      this.orderNumberModel.setValue(filterValues[this.apiProperty]);
    } else {
      this.orderNumberModel.setValue(null);
    }
  };
}
