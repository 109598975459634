import React from 'react';
import Element from '../Element/Element';
import styles from './Flex.module.scss';

function Flex({
  inline,
  column,
  centered,
  centeredVertically = centered,
  centeredHorizontally = centered,
  alignTop,
  alignBottom,
  alignRight,
  alignLeft,
  fullWidth = false,
  fullHeight = !column,
  spaceAround,
  spaceBetween,
  spaceEvenly,
  wrapContent,
  children,
  className,
  ...props
}) {
  const classNames = [
    styles.flex,
    {
      [styles.flex__inline]: inline,
      [styles.flex__column]: column,
      [styles.flex__justifyStart]: column ? alignTop : alignLeft,
      [styles.flex__justifyCenter]: column
        ? centeredVertically
        : centeredHorizontally,
      [styles.flex__justifyEnd]: column ? alignBottom : alignRight,
      [styles.flex__alignStart]: column ? alignLeft : alignTop,
      [styles.flex__alignCenter]: column
        ? centeredHorizontally
        : centeredVertically,
      [styles.flex__alignEnd]: column ? alignRight : alignBottom,
      [styles.flex__fullWidth]: fullWidth,
      [styles.flex__fullHeight]: fullHeight,
      [styles.flex__spaceAround]: spaceAround,
      [styles.flex__spaceBetween]: spaceBetween,
      [styles.flex__spaceEvenly]: spaceEvenly,
      [styles.flex__wrapContent]: wrapContent,
    },
    className,
  ];

  return (
    <Element tagName="div" className={classNames} {...props}>
      {children}
    </Element>
  );
}

export default Flex;
