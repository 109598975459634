import React, { useState } from 'react';
import { Icon } from '@storaensods/seeds-react';
import classnames from 'classnames';
import Heading from '../typography/Heading';
import Div from '../Div/Div';

import styles from './Accordion.module.scss';

export default function Accordion({ title, children }) {
  const [visible, setVisible] = useState(false);

  function toggle() {
    setVisible(!visible);
  }
  return (
    <Div
      className={classnames([styles.accordianContainer], { [styles.open]: visible })}
    >
      <Heading
        tagName="h6"
        caption
        bold
        color="neutral100"
        className={styles.accordianText}
        onClick={toggle}
      >
        <Icon
          className={styles.accordianButton}
          style={{ marginRight: 7 }}
        >
          keyboard_arrow_down
        </Icon>
        {title}
      </Heading>
      <Div
        className={styles.accordianContent}
      >
        {children}
      </Div>
    </Div>
  );
}
