import React from 'react';
import Flex from '../shared/components/Flex/Flex';
import Text from '../shared/components/typography/Text';
import Button from '../shared/components/Button/Button';
import Popup from '../shared/components/Popup/Popup';

function ErrorPopup({
  id, title, error, onDismiss,
}) {
  return (
    <Popup id={id}>
      <Flex centered fullHeight>
        <Flex column centered backgroundColor="neutral0">
          <Text
            caption
            color="red75"
            padding={{
              horizontal: 'xl',
              top: 'l',
              bottom: 's',
            }}
          >
            {title}
          </Text>

          <Text color="neutral40">{error}</Text>

          <Button padding={{ size: 'l' }} onClick={onDismiss}>
            OK
          </Button>
        </Flex>
      </Flex>
    </Popup>
  );
}

export default ErrorPopup;
