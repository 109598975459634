import { observable, action, computed } from 'mobx';

export default class SavingFilterFormModel {
  @observable filterName = '';

  @action
  onFilterNameChange(event) {
    this.filterName = event.target.value;
  }

  @computed
  get error() {
    const totalErrors = {} as any;

    if (!this.filterName || this.filterName.length === 0) {
      totalErrors.filterName = 'Required';
    }

    return Object.keys(totalErrors).length ? totalErrors : null;
  }
}
