import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import getLocations from './getLocations';
import putLocation from './putLocation';
import deleteLocation from './deleteLocation';

const ERROR_MESSAGE = 'Request failed, please try again later';

export type LocationListItem = {
  locationId: string,
  latitude: string,
  longitude: string,
  geofenceRadius: string,
  updatedBy: string,
};

export default class LocationModel {
  @observable list: Array<LocationListItem> = [];

  @observable loading = false;

  @action
  async remove(id) {
    this.loading = true;
    const { callWasSuccessful } = await deleteLocation(id);
    if (callWasSuccessful) {
      this.list = this.list.filter(({ locationId: itemId }) => itemId !== id);
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loading = false;
  }

  @action
  async updateLocationModel(values) {
    this.loading = true;
    const { callWasSuccessful, response } = await putLocation(values.locationId, values);
    if (callWasSuccessful) {
      const index = this.list.findIndex(({ locationId }) => response.locationId === locationId);
      if (index > -1) {
        this.list[index] = response;
      } else {
        const listCopy: Array<LocationListItem> = [...this.list, response];
        listCopy.sort((a, b) => a.locationId.localeCompare(b.locationId));
        const copyIndex = listCopy.findIndex(
          ({ locationId }) => response.locationId === locationId,
        );
        if (copyIndex > -1) {
          this.list.splice(copyIndex, 0, response);
        }
      }
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loading = false;
  }

  @action
  async loadLocations() {
    this.loading = true;
    const { callWasSuccessful, response } = await getLocations();
    if (callWasSuccessful) {
      this.list = response;
    } else {
      toast.error(ERROR_MESSAGE);
    }
    this.loading = false;
  }
}
