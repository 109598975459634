import callBackend from '../shared/methods/callBackend/callBackend';

const getTransportCounts = async ({ filters }) => {
  let url = `${process.env.REACT_APP_API_URL}/transportCounts`;

  if (filters) url += `?filters=${encodeURIComponent(JSON.stringify(filters))}`;

  const result = await callBackend({ url, method: 'GET' });
  return result;
};

export default getTransportCounts;
