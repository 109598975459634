import 'isomorphic-fetch';
import { AuthenticationParameters, AuthResponse, ClientAuthError } from 'msal';
import { createAuthProvider, accessTokenAuthParams, loginAuthParameters } from '../../../authProvider';

export interface BackendCallResponse {
  callWasSuccessful: boolean,
  error?: string,
  response?: any
}

interface BackendCallParameters {
  url: string,
  method: string,
  body?: any
}

const callBackend = async (params: BackendCallParameters): Promise<BackendCallResponse> => {
  let response;
  let returnValue;

  const authProvider = createAuthProvider();
  let token: AuthResponse = {} as AuthResponse;
  const authParamsTyped = accessTokenAuthParams as AuthenticationParameters;

  try {
    token = await authProvider.acquireTokenSilent(authParamsTyped);
  } catch (error) {
    if (error instanceof ClientAuthError && error.errorMessage.indexOf('interaction_required') !== -1) {
      console.log(error.errorMessage);
      console.log('require relogin');
      authProvider.acquireTokenPopup(loginAuthParameters);
    } else if (error instanceof ClientAuthError && error.errorCode === 'block_token_requests') {
      console.log(error.errorMessage);
    } else if (error instanceof ClientAuthError && error.errorCode === 'user_login_error') {
      console.log(error.errorMessage);
      authProvider.loginPopup(loginAuthParameters);
    } else if (error instanceof ClientAuthError && error.errorCode === 'user_cancelled') {
      console.log(error.errorMessage);
    } else if (error instanceof ClientAuthError && error.errorCode === 'login_progress_error') {
      console.log(error.errorMessage);
    } else {
      throw error;
    }
  }

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token.accessToken}`,
  };

  try {
    response = await fetch(params.url, {
      method: params.method,
      headers,
      body: JSON.stringify(params.body),
    });
  } catch (error) {
    return {
      callWasSuccessful: false,
      error,
    };
  }

  const callWasSuccessful = response.status === 200
  || response.status === 201 || response.status === 202;

  if (!callWasSuccessful) {
    return {
      callWasSuccessful,
      error: `${response.status} ${response.statusText}`,
    };
  }

  try {
    returnValue = await response.json();
  } catch (error) {
    returnValue = undefined;
  }

  return {
    callWasSuccessful,
    [callWasSuccessful ? 'response' : 'error']: returnValue,
  };
};

export default callBackend;
